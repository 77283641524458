import React, { useEffect, useRef, useState } from "react";
import { Connect } from "../pages/dash-bord/components/Page Of DashBord/Notifications/Connect.To.Socket.io";
import { useData } from "../data/management/Data.Management";
import { GetSchoolDataFun } from "../services/api/school/School";
import { toast } from "react-toastify";

const NotificationsForUser = () => {
  const {
    Data: {
      UserId,
      Roles,
      UserData: { SchoolId },
    },
  }: any = useData();
  let Socket = Connect();

  const AudioForNotifications = useRef<HTMLAudioElement | null>(null);
  const [isUserInteracted, setIsUserInteracted] = useState(false);

  useEffect(() => {
    const handleUserInteraction = () => {
      setIsUserInteracted(true);
      // Remove event listeners after the first interaction
      document.removeEventListener("click", handleUserInteraction);
      document.removeEventListener("keydown", handleUserInteraction);
    };

    // Add event listeners to capture user interaction
    document.addEventListener("click", handleUserInteraction);
    document.addEventListener("keydown", handleUserInteraction);

    return () => {
      // Clean up event listeners
      document.removeEventListener("click", handleUserInteraction);
      document.removeEventListener("keydown", handleUserInteraction);
    };
  }, []);

  useEffect(() => {
    GetSchoolDataFun({
      SchoolId: `${SchoolId?.replaceAll("_-_", "#")}`,
      UserId,
    }).then((result: any) => {
      Socket.emit("joinRoom", result.School);
      Socket.on("message", (message) => {
        if (message.Error === false && message.Change === false) {
          toast.success("There are new notifications", {
            theme: "dark",
            closeOnClick: true,
          });
          if (isUserInteracted && AudioForNotifications.current) {
            AudioForNotifications.current.play().catch((error) => {
              console.log(error);
            });
          }
        } else {
        }
      });
    });
  }, [Socket, SchoolId, UserId, isUserInteracted]);

  return (
    <div>
      <audio src="/MP3/OnMessageSended.mp3" ref={AudioForNotifications}></audio>
    </div>
  );
};

export default NotificationsForUser;
