import React, { useState } from "react";
import ModalComp from "../../../../../../components/Design/Modal";
import { Trash } from "lucide-react";
import { DeleteGroupFun } from "../../../../../../services/api/Group/Group";
import { Encrypt_Back } from "../../../../../../services/jwt with backend/JWT.With.Backend";
import { toast } from "react-toastify";
import { useData } from "../../../../../../data/management/Data.Management";

const DeleteBtnGroup = ({
  GroupData,
  UserId,
  OnDeleted,
}: {
  GroupData: any;
  UserId: string;
  OnDeleted: Function;
}) => {
  const {
    Data: {
      LanguageData: {
        DashBord: {
          Group: { Delete },
        },
      },
    },
  }: any = useData();
  const [disabledCloseBtn, SetdisabledCloseBtn] = useState(false);
  const [disabledConfirmBtn, SetdisabledConfirmBtn] = useState(false);
  const [Loading, SetLoading] = useState(false);
  const [Show, SetShow] = useState(false);

  const DeleteGroup = () => {
    SetLoading(true);
    DeleteGroupFun({
      Token: Encrypt_Back({
        Data: {
          GroupId: GroupData.GroupId,
          SchoolId: GroupData.SchoolId,
          UserId,
        },
        expiresIn: {
          minutes: 5,
        },
      }),
    }).then((result) => {
      SetShow(!result.Data.Deleted);
      toast.success(result.Data.Message, {
        closeOnClick: true,
        theme: "dark",
      });
      OnDeleted(true);
      SetLoading(false);
    });
  };
  return (
    <ModalComp
      ButtonTitle={
        <>
          <Trash />
        </>
      }
      ButtonTitlevariant="danger"
      ModalTitle={Delete.DeleteGroup}
      ConfirmBtn={
        <>
          <span className="me-2">{Delete.DeleteBtn}</span>
          <Trash />
        </>
      }
      variantBtn="danger"
      variantModal="light"
      disabledCloseBtn={disabledCloseBtn}
      disabledConfirmBtn={disabledConfirmBtn}
      onClickConFirmBtn={DeleteGroup}
      Show={Show}
      handleClose={() => {
        SetShow(false);
      }}
      handleShow={() => {
        SetShow(true);
      }}
      Loading={Loading}
    >
      <input
        type="text"
        disabled={true}
        value={GroupData.GroupName}
        className="form-control"
      />
    </ModalComp>
  );
};
export default DeleteBtnGroup;
