import { Plus } from "lucide-react";
import React, { useEffect, useState } from "react";
import ModalComp from "../../../../../../components/Design/Modal";
import { GetGroupsOfSchoolFun } from "../../../../../../services/api/school/School";
import { CreateStudentFun } from "../../../../../../services/api/Group/User";
import { Encrypt_Back } from "../../../../../../services/jwt with backend/JWT.With.Backend";
import { toast } from "react-toastify";
import CreatableSelect from "react-select/creatable";
import { useData } from "../../../../../../data/management/Data.Management";

const CreateStudent = ({
  SchoolId,
  UserId,
  OnCreated,
}: {
  SchoolId: string;
  UserId: string;
  OnCreated: Function;
}) => {
  let {
    Data: {
      LanguageData: {
        DashBord: {
          Student: { Create },
          Group,
        },
      },
    },
  }: any = useData();
  const [GroupsSelectOption, SetGroupsSelectOption]: any = useState([]);
  const [disabledCloseBtn, SetdisabledCloseBtn] = useState(false);
  const [disabledConfirmBtn, SetdisabledConfirmBtn] = useState(true);
  const [Loading, SetLoading] = useState(false);
  const [Show, SetShow] = useState(false);
  const [StudentEmail, SetStudentEmail] = useState("");
  const [StudentName, SetStudentName] = useState("");
  const [GroupId, SetGroupId] = useState(null);
  useEffect(() => {
    GetGroupsOfSchoolFun({ SchoolId, UserId }).then((result) => {
      for (let i = 0; i < result.School.Groups.length; i++) {
        const element = result.School.Groups[i];
        let SearchOfGroup = GroupsSelectOption.filter(
          (Group: any) => Group.value == element.GroupId
        );
        if (SearchOfGroup.length == 0) {
          GroupsSelectOption.push({
            value: element.GroupId,
            label: element.GroupName,
          });
          SetGroupsSelectOption(GroupsSelectOption);
        }
      }
    });
  }, []);
  useEffect(() => {
    if (
      StudentName.replace(/ /g, "").length > 0 &&
      StudentEmail.replace(/ /g, "").length > 0 &&
      GroupId != null
    ) {
      SetdisabledConfirmBtn(false);
    } else {
      SetdisabledConfirmBtn(true);
    }
  }, [StudentEmail, StudentName, GroupId]);
  const CreateStudentMedthod = () => {
    // Email, GroupId, SchoolId, UserName
    CreateStudentFun({
      Token: Encrypt_Back({
        Data: {
          Email: StudentEmail,
          GroupId,
          SchoolId,
          UserName: StudentName,
          UserId,
        },
        expiresIn: { minutes: 5 },
      }),
    }).then((result) => {
      if (result.Data.Created) {
        SetShow(!result.Data.Created);
        toast.success(result.Data.Message, {
          closeOnClick: true,
          theme: "dark",
        });
        OnCreated(true);
        SetStudentEmail("");
        SetStudentName("");
        SetGroupId(null);
      } else {
        toast.error(result.Data.Message, {
          closeOnClick: true,
          theme: "dark",
        });
      }
      SetLoading(false);
    });
  };
  return (
    <div className="w-100 d-flex justify-content-center align-items-center">
      <ModalComp
        ButtonTitle={
          <>
            <span className="mx-2">{Create.Create}</span>
            <Plus />
          </>
        }
        ModalTitle={Create.CreateNewStudent}
        ConfirmBtn={
          <>
            <span className="me-2">{Create.CreateBtn}</span>
            <Plus />
          </>
        }
        variantBtn="info"
        variantModal="light"
        disabledCloseBtn={disabledCloseBtn}
        disabledConfirmBtn={disabledConfirmBtn}
        onClickConFirmBtn={CreateStudentMedthod}
        Show={Show}
        handleClose={() => {
          SetShow(false);
          SetStudentEmail("");
          SetStudentName("");
          SetGroupId(null);
        }}
        handleShow={() => {
          SetShow(true);
          SetStudentEmail("");
          SetStudentName("");
          SetGroupId(null);
        }}
        Loading={Loading}
      >
        <input
          type="email"
          disabled={Loading}
          value={StudentEmail}
          onChange={(e: any) => {
            SetStudentEmail(e.target.value);
          }}
          placeholder={Create.StudentEmail}
          className="form-control mb-3"
        />
        <input
          type="text"
          disabled={Loading}
          value={StudentName}
          onChange={(e: any) => {
            SetStudentName(e.target.value);
          }}
          placeholder={Create.StudentName}
          className="form-control mb-3"
        />
        <CreatableSelect
          className="text-dark"
          isClearable
          options={GroupsSelectOption}
          onChange={(e: any) => {
            if (e != null) {
              SetGroupId(e.value);
            } else {
              SetGroupId(null);
            }
          }}
          placeholder={Group.Create.GroupName}
        />
      </ModalComp>
    </div>
  );
};

export default CreateStudent;
