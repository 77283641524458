import React from "react";
import { Edit, Info, Trash } from "lucide-react";
import EditBtnUser from "../../Leaders/components/Edit.Btn.Leader";
import DeleteBtnUser from "../../Leaders/components/Delete.Btn.Leader";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useNavigate } from "react-router-dom";
const StudentView = ({
  Title,
  SchoolId,
  GroupId,
  GroupsData,
  LeaderData,
  UserId,
  OnDeleted,
  OnEdit,
}: {
  Title: string;
  SchoolId: string;
  GroupId: string;
  GroupsData: any[];
  LeaderData: any;
  OnDeleted: Function;
  OnEdit: Function;
  UserId: string;
}) => {
  const Navigate = useNavigate();
  return (
    <div className="Student">
      <h3 className="mb-0">
        {/* {Title.length > 25 ? (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="button-tooltip">{Title}</Tooltip>}
          >
            <span>{Title.slice(0, 25) + ".."}</span>
          </OverlayTrigger>
        ) : (
          Title
        )} */}
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="button-tooltip">{Title}</Tooltip>}
        >
          <span>{Title}</span>
        </OverlayTrigger>
      </h3>
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ width: "140px" }}
      >
        <EditBtnUser
          UserData={LeaderData}
          OnEdit={OnEdit}
          UserId={UserId}
          type="Student"
          SchoolId={SchoolId}
          GroupsData={GroupsData}
        />
        <DeleteBtnUser
          type="Student"
          UserData={LeaderData}
          OnDeleted={OnDeleted}
          UserId={UserId}
        />
        <button
          className="ms-1 btn btn-outline-primary d-flex align-items-center justify-content-center"
          onClick={() => {
            Navigate(`/DashBord/User/@${LeaderData.Id}`);
          }}
        >
          <Info />
        </button>
      </div>
    </div>
  );
};

export default StudentView;
