import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../../../../../styles/DashBord/Groups.style.css";
import CreateGroup from "./components/Create.Group";
import GetAllGroups from "./components/Get.All.Groups";
import { useData } from "../../../../../data/management/Data.Management";
import { GetSchoolAllInfoFun } from "../../../../../services/api/school/School";
const Groups = () => {
  const {
    Data: {
      UserId,
      LanguageData: {
        Errors: { SchoolNotFound },
      },
    },
  }: any = useData();
  let { SchoolId } = useParams();
  const [StatusOnCreated, SetStatusOnCreated] = useState("");
  const [Loading, SetLoading] = useState(true);
  const [Show, SetShow] = useState(false);
  const UpDate = () => {
    SetStatusOnCreated(`${new Date()}`);
  };
  useEffect(() => {
    GetSchoolAllInfoFun({
      SchoolId: `${SchoolId?.replaceAll("_-_", "#")}`,
      UserId: UserId,
    }).then((result) => {
      SetLoading(false);
      if (result != null) {
        SetShow(true);
      }
    });
  }, []);

  return (
    <div id="Groups">
      {Loading ? (
        <div className="d-flex w-100 align-items-center justify-content-center mt-2">
          <div
            className="spinner-border text-primary"
            style={{ width: "50px", height: "50px" }}
            role="status"
          ></div>
        </div>
      ) : (
        <>
          {Show ? (
            <>
              <CreateGroup
                OnCreated={UpDate}
                UserId={UserId}
                SchoolId={`${SchoolId?.replaceAll("_-_", "#")}`}
              />
              <GetAllGroups
                GetGroups={StatusOnCreated}
                SchoolId={`${SchoolId?.replaceAll("_-_", "#")}`}
              />
            </>
          ) : (
            <div className="d-flex align-items-center w-100 justify-content-center">
              <div className="alert alert-info text-center">
                {SchoolNotFound}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Groups;
