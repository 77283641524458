import React, { useEffect, useState } from "react";
import ModalComp from "../../../../../../components/Design/Modal";
import { Edit, Pencil } from "lucide-react";
import { EditGroupFun } from "../../../../../../services/api/Group/Group";
import { Encrypt_Back } from "../../../../../../services/jwt with backend/JWT.With.Backend";
import { toast } from "react-toastify";
import { useData } from "../../../../../../data/management/Data.Management";

const EditBtnGroup = ({
  GroupData,
  UserId,
  OnEdit,
}: {
  GroupData: any;
  UserId: string;
  OnEdit: Function;
}) => {
  const {
    Data: {
      LanguageData: {
        DashBord: {
          Group: { Edit, Create },
        },
      },
    },
  }: any = useData();
  const [disabledCloseBtn, SetdisabledCloseBtn] = useState(false);
  const [disabledConfirmBtn, SetdisabledConfirmBtn] = useState(true);
  const [Loading, SetLoading] = useState(false);
  const [Show, SetShow] = useState(false);
  const [GroupName, SetGroupName] = useState(GroupData.GroupName);

  const EditGroup = () => {
    SetLoading(true);
    EditGroupFun({
      Token: Encrypt_Back({
        Data: {
          UserId,
          GroupId: GroupData.GroupId,
          SchoolId: GroupData.SchoolId,
          GroupName,
        },
        expiresIn: {
          minutes: 5,
        },
      }),
    }).then((result) => {
      SetShow(!result.Data.UpDated);
      toast.success(result.Data.Message, {
        closeOnClick: true,
        theme: "dark",
      });
      OnEdit(true);
      SetLoading(false);
    });
  };
  useEffect(() => {
    if (
      GroupName != GroupData.GroupName &&
      GroupName.replace(/ /g, "").length > 0
    ) {
      SetdisabledConfirmBtn(false);
    } else {
      SetdisabledConfirmBtn(true);
    }
  }, [GroupName, GroupData]);
  return (
    <ModalComp
      ButtonTitle={
        <>
          <Pencil />
        </>
      }
      ButtonTitlevariant="primary"
      ModalTitle={Edit.EditGroup}
      ConfirmBtn={
        <>
          <span className="me-2">{Edit.EditBtn}</span>
          <Pencil />
        </>
      }
      variantBtn="primary"
      variantModal="light"
      disabledCloseBtn={disabledCloseBtn}
      disabledConfirmBtn={disabledConfirmBtn}
      onClickConFirmBtn={EditGroup}
      Show={Show}
      handleClose={() => {
        SetShow(false);
      }}
      handleShow={() => {
        SetShow(true);
      }}
      Loading={Loading}
    >
      <input
        type="text"
        onChange={(e) => {
          SetGroupName(e.target.value);
        }}
        value={GroupName}
        className="form-control"
        placeholder={Create.GroupName}
      />
    </ModalComp>
  );
};
export default EditBtnGroup;
