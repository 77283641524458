import "../../../../../styles/DashBord/Forms/Forms.Page.style.css";
import { useData } from "../../../../../data/management/Data.Management";
import { useParams } from "react-router-dom";
import CreateForm from "./components/Create.Form";
import GetAllForms from "./components/Get.All.Forms";
import { useEffect, useState } from "react";
import { GetSchoolAllInfoFun } from "../../../../../services/api/school/School";
import GoogleSheet from "./GoogleSheet";
const FormsPage = () => {
  const {
    Data: {
      Roles,
      UserId,
      LanguageData: {
        Errors: { SchoolNotFound },
      },
    },
  }: any = useData();
  let { SchoolId } = useParams();
  const [StatusOnCreated, SetStatusOnCreated] = useState("");
  const [Loading, SetLoading] = useState(true);
  const [Show, SetShow] = useState(false);
  const UpDate = () => {
    SetStatusOnCreated(`${new Date()}`);
  };
  useEffect(() => {
    GetSchoolAllInfoFun({
      SchoolId: `${SchoolId?.replaceAll("_-_", "#")}`,
      UserId: UserId,
    }).then((result) => {
      SetLoading(false);
      if (result != null) {
        SetShow(true);
      }
    });
  }, []);
  return (
    <div id="FormsPage" className={`${Roles.includes("Admin") ? "Admin" : ""}`}>
      <div className="w-100 d-flex align-items-center justify-content-center flex-column mt-4">
        {Loading ? (
          <div className="d-flex w-100 align-items-center justify-content-center mt-2">
            <div
              className="spinner-border text-primary"
              style={{ width: "50px", height: "50px" }}
              role="status"
            ></div>
          </div>
        ) : (
          <>
            {Show ? (
              <>
                <CreateForm
                  OnCreated={UpDate}
                  UserId={UserId}
                  SchoolId={`${SchoolId?.replaceAll("_-_", "#")}`}
                />
                <GetAllForms
                  GetGroups={StatusOnCreated}
                  SchoolId={`${SchoolId?.replaceAll("_-_", "#")}`}
                />
              </>
            ) : (
              <div className="d-flex align-items-center w-100 justify-content-center">
                <div className="alert alert-info text-center">
                  {SchoolNotFound}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default FormsPage;
