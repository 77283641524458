import React from "react";
import ASideLinks from "./components/ASideLinks";
import { Outlet, Route, Routes } from "react-router-dom";
import "../../styles/DashBord/DashBord.Page.css";

const DashBordPage = () => {
  return (
    <div id="DashBord">
      <div className="Veiw">
        <Outlet />
      </div>
    </div>
  );
};

export default DashBordPage;
