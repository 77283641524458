import React, { useEffect, useState } from "react";
import ModalComp from "../../../../../../components/Design/Modal";
import { Edit, Pencil } from "lucide-react";
import { EditUserFun } from "../../../../../../services/api/Group/User";
import { Encrypt_Back } from "../../../../../../services/jwt with backend/JWT.With.Backend";
import { toast } from "react-toastify";
import CreatableSelect from "react-select/creatable";
import { GetGroupsOfSchoolFun } from "../../../../../../services/api/school/School";
import { useData } from "../../../../../../data/management/Data.Management";

const EditBtnUser = ({
  UserData,
  UserId,
  OnEdit,
  type,
  SchoolId,
  GroupsData,
}: {
  UserData: any;
  UserId: string;
  SchoolId: string;
  OnEdit: Function;
  type: "Leader" | "Student";
  GroupsData: any[];
}) => {
  let {
    Data: {
      LanguageData: {
        DashBord: { Leader, Student, Group },
      },
    },
  }: any = useData();

  const [disabledCloseBtn, SetdisabledCloseBtn] = useState(false);
  const [disabledConfirmBtn, SetdisabledConfirmBtn] = useState(true);
  const [Loading, SetLoading] = useState(false);
  const [Show, SetShow] = useState(false);
  const [NewEmail, SetNewEmail] = useState(UserData.Email);
  const [NewName, SetNewName] = useState(UserData.UserName);
  const [NewGroupId, SetNewGroupId]: any = useState({
    value: UserData.Group.GroupId,
    label: UserData.Group.GroupName,
  });
  const [GroupsSelectOption, SetGroupsSelectOption]: any = useState([]);
  useEffect(() => {
    for (let i = 0; i < GroupsData.length; i++) {
      const element = GroupsData[i];
      let SearchOfGroup = GroupsSelectOption.filter(
        (Group: any) => Group.value == element.GroupId
      );
      if (SearchOfGroup.length == 0) {
        GroupsSelectOption.push({
          value: element.GroupId,
          label: element.GroupName,
        });
        SetGroupsSelectOption(GroupsSelectOption);
      }
    }
  }, []);
  const EditUser = () => {
    SetLoading(true);

    console.log({
      value: UserData.Group.GroupId,
      label: UserData.Group.GroupName,
    });

    EditUserFun({
      Token: Encrypt_Back({
        Data: {
          UserId,
          Email: NewEmail,
          _UserId: UserData.UserId,
          UserName: NewName,
          GroupId: NewGroupId.value,
          UserType: type,
        },
        expiresIn: { minutes: 5 },
      }),
    }).then((result) => {
      if (result.Data.UpDated != undefined) {
        SetShow(!result.Data.UpDated);
        toast.success(result.Data.Message, {
          closeOnClick: true,
          theme: "dark",
        });
        OnEdit(true);
      }
      SetLoading(false);
    });
  };
  useEffect(() => {
    if (
      NewEmail.replace(/ /g, "").length > 0 &&
      NewName.replace(/ /g, "").length > 0 &&
      NewGroupId != null
    ) {
      if (
        NewEmail != UserData.Email ||
        NewName != UserData.UserName ||
        NewGroupId.value != UserData.Group.GroupId
      ) {
        SetdisabledConfirmBtn(false);
      } else {
        SetdisabledConfirmBtn(true);
      }
    }
  }, [NewEmail, NewName, NewGroupId]);
  return (
    <ModalComp
      ButtonTitle={
        <>
          <Pencil />
        </>
      }
      ButtonTitlevariant="primary"
      ModalTitle={
        type == "Leader" ? Leader.Edit.EditLeader : Student.Edit.EditStudent
      }
      ConfirmBtn={
        <>
          <span className="me-2">
            {type == "Leader" ? Leader.Edit.EditBtn : Student.Edit.EditBtn}
          </span>
          <Pencil />
        </>
      }
      variantBtn="primary"
      variantModal="light"
      disabledCloseBtn={disabledCloseBtn}
      disabledConfirmBtn={disabledConfirmBtn}
      onClickConFirmBtn={EditUser}
      Show={Show}
      handleClose={() => {
        SetShow(false);
        SetNewEmail(UserData.Email);
        SetNewName(UserData.UserName);
        SetNewGroupId({
          value: UserData.Group.GroupId,
          label: UserData.Group.GroupName,
        });
      }}
      handleShow={() => {
        SetShow(true);
        SetNewEmail(UserData.Email);
        SetNewName(UserData.UserName);
        SetNewGroupId({
          value: UserData.Group.GroupId,
          label: UserData.Group.GroupName,
        });
      }}
      Loading={Loading}
    >
      <input
        type="email"
        disabled={Loading}
        value={NewEmail}
        onChange={(e: any) => {
          SetNewEmail(e.target.value);
        }}
        placeholder={
          type == "Leader"
            ? Leader.Create.LeaderEmail
            : Student.Create.StudentEmail
        }
        className="form-control mb-3"
      />
      <input
        type="text"
        disabled={Loading}
        value={NewName}
        onChange={(e: any) => {
          SetNewName(e.target.value);
        }}
        placeholder={
          type == "Leader"
            ? Leader.Create.LeaderName
            : Student.Create.StudentName
        }
        className="form-control mb-3"
      />
      <CreatableSelect
        className="text-dark"
        isClearable
        options={GroupsSelectOption}
        value={NewGroupId}
        onChange={(e: any) => {
          if (e != null) {
            SetNewGroupId(e);
          } else {
            SetNewGroupId(null);
          }
        }}
        placeholder={Group.Create.GroupName}
      />
    </ModalComp>
  );
};

export default EditBtnUser;
