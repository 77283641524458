import { Link, NavLink, useNavigate } from "react-router-dom";
import "../../styles/header/header.component.style.css";
import { useData } from "../../data/management/Data.Management";
import { IconsForNav } from "../../data/static/Links";
import { LogOut, Menu, User } from "lucide-react";
import { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
const Header = () => {
  const {
    Data: {
      LanguageData: { AppName, Header },
      Language,
      Roles,
      UserData: { UserName, UserImage },
    },
    SetRoles,
    SetMode,
    SetLanguage,
    SetUserLogin,
    SetLogin,
  }: any = useData();

  const [links, Setlinks]: any = useState([]);
  const [ShowMenu, SetShowMenu] = useState(false);
  const [DropDownList, SetDropDownList] = useState([]);
  const [, SetTime] = useState("");
  useEffect(() => {
    if (Roles.includes("Admin")) {
      let _Links = Header.Links.filter(
        (Link: any) => Link.Name != "Notifications"
      );
      Setlinks([..._Links, ...Header.AdminLinks]);
      SetTime(`${new Date()}`);
    } else {
      Setlinks(Header.Links);
      SetTime(`${new Date()}`);
    }

    if (!Roles.includes("Admin") && !Roles.includes("Leader")) {
      SetDropDownList(Header.DropDownList);
    }
  }, [Language, Roles]);
  const Navigate = useNavigate();
  const LogOutFun = () => {
    SetRoles([]);
    SetMode("Light");
    SetLanguage("En");
    SetUserLogin({});
    SetLogin(false);
    Navigate("/");
  };
  const _Icon = (Name: string) => {
    return IconsForNav.filter((Icon: any) => Icon.Name == Name)[0].Icon;
  };
  useEffect(() => {
    if (window.location.hash.split("#").length > 1) {
      SetShowMenu(
        window.location.hash.split("#")[1].split("/").includes("DashBord")
      );
    }
  }, [window.location.hash]);
  return (
    <header id="Header" className={`${Language == "Ar" ? "Lalezar" : "Rubik"}`}>
      {/* <div className="container"> */}
      <div className="Desktop">
        <div className="Logo">
          <Link to="/" className={`${Language == "Ar" ? "Lalezar" : "Rubik"}`}>
            <img src="/Images/Logo.png" alt="" />
            <span>{AppName}</span>
          </Link>
        </div>
        <div className="Links">
          <button className="Btn Menu">
            <Menu />
          </button>
          <ul>
            {links.map((_Link: any, i: number) => {
              return (
                <li key={i}>
                  <NavLink to={_Link.path} className="GoodBtn">
                    <span>{_Link.Title}</span>
                    {_Icon(_Link.Name)}
                  </NavLink>
                </li>
              );
            })}

            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                {UserImage.length > 0 ? (
                  <img src={UserImage} alt="" />
                ) : (
                  <div className="Cirle">
                    <User />
                  </div>
                )}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.ItemText>{UserName}</Dropdown.ItemText>
                <hr className="my-0" />
                {DropDownList.map((_Link: any, i: number) => {
                  return (
                    <Dropdown.ItemText key={i}>
                      <NavLink to={_Link.path} className="GoodBtn">
                        <span>{_Link.Title}</span>
                        {_Icon(_Link.Name)}
                      </NavLink>
                    </Dropdown.ItemText>
                  );
                })}
                <Dropdown.ItemText>
                  <button className="GoodBtn" onClick={LogOutFun}>
                    <span className="me-2">{Header.LogOutBtn}</span>
                    <LogOut />
                  </button>
                </Dropdown.ItemText>
              </Dropdown.Menu>
            </Dropdown>
          </ul>
        </div>
        {/* </div> */}
      </div>
      <div className="Mobile">
        <ul>
          {links.map((_Link: any, i: number) => {
            return (
              <li key={i}>
                <NavLink to={_Link.path} className="GoodBtn">
                  {_Icon(_Link.Name)}
                </NavLink>
              </li>
            );
          })}
          {/* {ShowMenu && (
            <li>
              <button
                className="GoodBtn rounded-1"
                onClick={() => {
                  if (!window.location.href.split("#").includes("ShowPopup")) {
                    Navigate(
                      window.location.hash.slice(
                        1,
                        window.location.hash.length
                      ) + "#ShowPopup"
                    );
                  } else {
                    Navigate(
                      window.location.hash
                        .split("#")
                        .slice(0, window.location.hash.split("#").length - 1)
                        .join("#")
                        .slice(
                          1,
                          window.location.hash
                            .split("#")
                            .slice(
                              0,
                              window.location.hash.split("#").length - 1
                            )
                            .join("#").length
                        )
                    );
                  }
                }}
              >
                <Menu />
              </button>
            </li>
          )} */}
          {/* <li>
            <button className="Btn rounded-1" onClick={LogOutFun}>
              <LogOut />
            </button>
          </li> */}
        </ul>
      </div>
    </header>
  );
};

export default Header;
