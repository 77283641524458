import React, { useEffect, useState } from "react";
import { useData } from "../../data/management/Data.Management";
import { LogOut, User } from "lucide-react";
import "../../styles/header/HeaderUserImg.style.css";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { IconsForNav } from "../../data/static/Links";
const HeaderUserImg = () => {
  // const {
  //   Data: {
  //     Roles,
  //     UserData: { UserName, UserImage },
  //   },
  // }: any = useData();
  const {
    Data: {
      LanguageData: { AppName, Header },
      Language,
      Roles,
      UserData: { UserName, UserImage },
    },
    SetRoles,
    SetMode,
    SetLanguage,
    SetUserLogin,
    SetLogin,
  }: any = useData();
  const [DropDownList, SetDropDownList] = useState([]);
  useEffect(() => {
    if (!Roles.includes("Admin") && !Roles.includes("Leader")) {
      SetDropDownList(Header.DropDownList);
    }
  }, [Language, Roles]);
  const _Icon = (Name: string) => {
    return IconsForNav.filter((Icon: any) => Icon.Name == Name)[0].Icon;
  };
  const Navigate = useNavigate();
  const LogOutFun = () => {
    SetRoles([]);
    SetMode("Light");
    SetLanguage("En");
    SetUserLogin({});
    SetLogin(false);
    Navigate("/");
  };
  return (
    <div className="HeaderUserImg">
      <Link to="/">
        <img src="/Images/Logo.png" alt="" style={{ width: "50px" }} />
      </Link>
      <Dropdown>
        <Dropdown.Toggle variant="success" id="dropdown-basic">
          {UserImage.length > 0 ? (
            <img src={UserImage} alt="" />
          ) : (
            <div className="Cirle">
              <User />
            </div>
          )}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.ItemText>{UserName}</Dropdown.ItemText>
          <hr className="my-0" />
          {DropDownList.map((_Link: any, i: number) => {
            return (
              <Dropdown.ItemText key={i}>
                <NavLink to={_Link.path} className="GoodBtn">
                  <strong>{_Link.Title}</strong>
                  {_Icon(_Link.Name)}
                </NavLink>
              </Dropdown.ItemText>
            );
          })}

          <Dropdown.ItemText>
            <button className="GoodBtn" onClick={LogOutFun}>
              <strong className="me-2">{Header.LogOutBtn}</strong>
              <LogOut />
            </button>
          </Dropdown.ItemText>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default HeaderUserImg;
