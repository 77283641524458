import React, { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { useData } from "../../../data/management/Data.Management";
import { ChangeLanguageun } from "../../../services/api/User/User";
import {
  Decrypt_Back,
  Encrypt_Back,
} from "../../../services/jwt with backend/JWT.With.Backend";
const ChangeLanguage = () => {
  const {
    Data: {
      IsLoggin,
      Roles,
      UserData: { UserName },
      LanguageData: { HomePage },
      UserId,
      Theme,
      Language,
    },
    SetTheme,
    SetPermissions,
    SetRoles,
    SetMode,
    SetLanguage,
    SetUserLogin,
  }: any = useData();
  let Languages = [
    { value: "Ar", label: "عربي" },
    { value: "En", label: "English" },
  ];
  const [_Language, Set_Language]: any = useState(
    Languages.filter((_Language: any) => _Language.value == Language)[0]
  );
  let [Start, SetStart] = useState(false);
  useEffect(() => {
    if (Start) {
      if (_Language != null) {
        SetLanguage(_Language.value);
        ChangeLanguageun({
          Token: Encrypt_Back({
            Data: { UserId, Language: _Language.value },
            expiresIn: { minutes: 5 },
          }),
        }).then((result) => {
          let UserData = Decrypt_Back({
            Data: result.Data.UserData,
          }).Data;

          SetPermissions(UserData.Permissions);
          SetRoles(UserData.Roles);
          SetTheme(UserData.Theme);
          SetLanguage(UserData.Language);
          SetUserLogin(UserData);
        });
      }
    }
    SetStart(true);
  }, [_Language]);
  return (
    <div style={{ width: "200px" }} className="mb-2">
      <CreatableSelect
        className="text-dark"
        isClearable
        options={Languages}
        value={_Language}
        onChange={(e: any) => {
          if (e != null) {
            Set_Language(e);
          } else {
            Set_Language(null);
          }
        }}
        // placeholder={Group.Create.GroupName}
      />
    </div>
  );
};

export default ChangeLanguage;
