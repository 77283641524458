import React, { useEffect, useState } from "react";
import "../../styles/sign-in/sign-in.page.style.css";
import { useData } from "../../data/management/Data.Management";
import { GoogleLogin } from "@react-oauth/google";
import { SignInFun } from "../../services/api/SignIn/SignIn";
import {
  Decrypt_Back,
  Encrypt_Back,
} from "../../services/jwt with backend/JWT.With.Backend";
import { useNavigate } from "react-router-dom";
import GoogleLoginBtn from "./components/GoogleLoginBtn";

const SignInPage = () => {
  const {
    SetLanguage,
    Data: { IsLoggin },
    SetPermissions,
    SetRoles,
    SetMode,
    SetUserLogin,
    SetLogin,
  }: any = useData();
  const Navigate = useNavigate();
  const SignIn = (credentialResponse: any) => {
    SignInFun({
      Token: Encrypt_Back({
        Data: {
          EmailToken: credentialResponse.credential,
        },
        expiresIn: {
          minutes: 5,
        },
      }),
    }).then((result: any) => {
      if (result.Data.Loggin) {
        let AdminData = Decrypt_Back({
          Data: result.Data.UserData,
        }).Data;

        SetPermissions(AdminData.Permissions);
        SetRoles(AdminData.Roles);
        SetMode(AdminData.Theme);
        SetLanguage(AdminData.Language);
        SetUserLogin(AdminData);
        SetLogin(true);
        Navigate("/");
      } else {
        //  Message To User Because Not Found
      }
    });
  };

  return (
    <div id="SignInPage">
      <div className="Form">
        <h3>Login To</h3>
        <h2 className="Rubik">Siham Beid Jabaar</h2>
        <GoogleLogin
          onSuccess={SignIn}
          onError={() => {
            // console.log("Login Failed");
          }}
          useOneTap
        />
        {/*<GoogleLoginBtn />*/}
      </div>
    </div>
  );
};

export default SignInPage;
