import axios from "axios";
import { GraphQlURL } from "../../../data/main/URLS";
import { Decrypt_BackQraphQl } from "../../jwt with backend/JWT.With.Backend";
import { BackendURL } from "../URLs";

export const GetPlayLists = ({
  UserId,
  SchoolId,
}: {
  UserId: string;
  SchoolId: string;
}) => {
  return axios
    .post(`${GraphQlURL}`, {
      query: `
          query Query($UserId:String,$SchoolId:String) {
            PlayLists(UserId:$UserId,SchoolId:$SchoolId) {
                PlayListId
                Title
                CreateAt
                PlayListImage
                SchoolId
                IsVisible
                Libraries {
                    LibraryId
                    Link
                }
                Level {
                  LevelId
                  LevelTitle
                }
            }
          }
        `,

      variables: {
        UserId,
        SchoolId,
      },
    })
    .then((result) => {
      return Decrypt_BackQraphQl({ Data: result.data.data.data }).Data;
    });
};
export const GetPlayList = ({
  UserId,
  PlayListId,
}: {
  UserId: string;
  PlayListId: string;
}) => {
  console.log("HELLO");

  return axios
    .post(`${GraphQlURL}`, {
      query: `
          query Query($UserId:String,$PlayListId:String) {
            PlayList(UserId:$UserId,PlayListId:$PlayListId) {
                PlayListId
                Title
                CreateAt
                PlayListImage
                SchoolId
                IsVisible
                Libraries {
                    CreateAt
                    Description
                    Link
                    Type
                    LibraryId    
                    PDFURL  
                    PlayList {
                      Title
                      PlayListId
                    }                                  
                }
            }
          }
        `,

      variables: {
        UserId,
        PlayListId,
      },
    })
    .then((result) => {
      return Decrypt_BackQraphQl({ Data: result.data.data.data }).Data;
    });
};

export const CreatePlayListFun = ({ Token }: { Token: string }) => {
  return axios
    .post(`${BackendURL}PlayList/Create`, { Token, date: `${new Date()}` })
    .then((result) => {
      return result.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
export const DeletePlayListFun = ({ Token }: { Token: string }) => {
  console.log(Token);

  return axios
    .post(`${BackendURL}PlayList/Delete`, { Token, date: `${new Date()}` })
    .then((result) => {
      return result.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
export const EditPlayListFun = ({ Token }: { Token: string }) => {
  return axios
    .post(`${BackendURL}PlayList/Edit`, { Token, date: `${new Date()}` })
    .then((result) => {
      return result.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
// export const GetAllLibrariesForSchool = ({
//   UserId,
//   SchoolId,
// }: {
//   UserId: string;
//   SchoolId: string;
// }) => {
//   return axios
//     .post(`${GraphQlURL}`, {
//       query: `
//         query Query($UserId:String,$SchoolId:String) {
//           Libraries(UserId:$UserId,SchoolId:$SchoolId) {
//             CreateAt
//             Description
//             Link
//             Type
//             LibraryId
//             UserId
//             Level {
//               LevelId
//               LevelTitle
//             }
//           }
//         }
//       `,
//       variables: {
//         UserId,
//         SchoolId,
//       },
//     })
//     .then((result) => {
//       return Decrypt_BackQraphQl({ Data: result.data.data.data }).Data;
//     });
// };
