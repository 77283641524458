import React from "react";
import { BadgeInfo, Edit, Info, Trash } from "lucide-react";
import DeleteBtnGroup from "./Delete.Btn.Group";
import EditBtnGroup from "./Edit.Btn.Group";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useData } from "../../../../../../data/management/Data.Management";
import { useNavigate } from "react-router-dom";

const GroupView = ({
  Title,
  GroupData,
  UserId,
  OnDeleted,
  OnEdit,
  Index,
}: {
  Title: string;
  GroupData: any;
  UserId: string;
  OnDeleted: Function;
  OnEdit: Function;
  Index: number;
}) => {
  const {
    Data: {
      LanguageData: {
        DashBord: {
          Group: {
            GroupInfo: { GroupInfo },
          },
        },
      },
    },
  }: any = useData();
  return (
    <div className="Group">
      <h3 className="mb-0">
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="button-tooltip">{Title}</Tooltip>}
        >
          <span>{Title.length > 15 ? Title.slice(0, 15) + "..." : Title}</span>
        </OverlayTrigger>
      </h3>
      <div>
        <EditBtnGroup OnEdit={OnEdit} GroupData={GroupData} UserId={UserId} />
        <DeleteBtnGroup
          OnDeleted={OnDeleted}
          GroupData={GroupData}
          UserId={UserId}
        />
      </div>
    </div>
  );
};

export default GroupView;
