import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import DeleteBtnForm from "./Delete.Form";
import { useData } from "../../../../../../data/management/Data.Management";
import EditBtnForm from "./Edit.Btn.Form";
import { Info } from "lucide-react";
import { useNavigate } from "react-router-dom";

const ViewForm = ({
  FormData,
  OnChanges,
}: {
  FormData: any;
  OnChanges: Function;
}) => {
  const {
    Data: {
      UserId,
      LanguageData: {
        DashBord: {
          Form: {
            Messages: { ShowFormAnswers },
          },
        },
      },
    },
  }: any = useData();
  let Navigate = useNavigate();
  return (
    <div className="Form">
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="button-tooltip">{FormData.FormTitle}</Tooltip>}
      >
        <h3 className="mb-0">
          {FormData.FormTitle.length > 15
            ? FormData.FormTitle.slice(0, 15) + "..."
            : FormData.FormTitle}
        </h3>
      </OverlayTrigger>

      <div className="Actions">
        <DeleteBtnForm
          FormData={FormData}
          OnDeleted={OnChanges}
          UserId={UserId}
        />
        <EditBtnForm OnEdit={OnChanges} FormData={FormData} UserId={UserId} />
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="button-tooltip">{ShowFormAnswers}</Tooltip>}
        >
          <button
            onClick={() => {
              Navigate(
                `/DashBord/School/${FormData.SchoolId.replaceAll(
                  "#",
                  "_-_"
                )}/Forms/${FormData.FormId.replaceAll("#", "_-_")}`
              );
            }}
            className="btn btn-outline-info mx-1 d-inline-flex align-items-center justify-content-center"
          >
            <Info />
          </button>
        </OverlayTrigger>
      </div>
    </div>
  );
};

export default ViewForm;
