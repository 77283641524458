import React, { useEffect, useState } from "react";
import { useData } from "../../data/management/Data.Management";
import "../../styles/FormsStudentPage/Forms-Student.Page.style.css";
import { GetAllFormsForNormalUserFun } from "../../services/api/Form/Form";
import GetAllForms from "./components/Get.All.Forms";
const FormsStudentPage = () => {
  const {
    Data: {
      Roles,
      Language,
      UserId,
      UserData: { Email, SchoolId },
      LanguageData: {
        Profile,
        DashBord: {
          Form: {
            Messages: { FormsNotCreated },
          },
        },
      },
    },
  }: any = useData();
  const [Loading, SetLoading] = useState(true);
  const [Show, SetShow] = useState(false);
  const [Forms, SetForms] = useState([]);
  useEffect(() => {
    if (SchoolId != "" || SchoolId != null) {
      GetAllFormsForNormalUserFun({
        SchoolId,
        UserId,
      }).then((result) => {
        if (result != null) {
          if (result.FormsNormalUser.length > 0) {
            SetForms(result.FormsNormalUser);
            SetShow(true);
          }
        }
        SetLoading(false);
      });
    }
  }, []);

  return (
    <div
      id="FormsStudentPage"
      className={`d-flex align-items-center justify-content-center flex-column ${
        Roles.length == 1 && Roles.includes("Student") ? "" : "Leader"
      } ${Language == "Ar" ? "Left" : "Right"}`}
    >
      <div className="w-100 d-flex align-items-center justify-content-center flex-column">
        {Loading ? (
          <div className="d-flex w-100 align-items-center justify-content-center">
            <div
              className="spinner-border text-primary"
              style={{ width: "50px", height: "50px" }}
              role="status"
            ></div>
          </div>
        ) : (
          <>
            {Show ? (
              <>
                <GetAllForms Forms={Forms} />
              </>
            ) : (
              <div className="d-flex align-items-center w-100 justify-content-center">
                <div className="alert alert-info text-center">
                  {FormsNotCreated}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default FormsStudentPage;
