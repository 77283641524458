import React, { useRef } from "react";
import { Encrypt_Back } from "../../../../../../services/jwt with backend/JWT.With.Backend";
import QRCode from "react-qr-code";
import html2canvas from "html2canvas";
import { toPng } from "html-to-image";
const DivQrCode = ({ User }: { User: any }) => {
  const divRef: any = useRef<HTMLDivElement>(null);
  const handleDownload = async () => {
    if (divRef.current) {
      const canvas = await html2canvas(divRef.current);
      const imgData = canvas.toDataURL("image/png");
      console.log(imgData);

      // const link = document.createElement("a");
      // link.href = imgData;
      // link.download = `${User.UserName}.Card.png`;
      // link.click();
    }
  };
  const UserData = (Data: any) => {
    let _Data = {
      UserId: Data.UserId,
    };
    return Encrypt_Back({
      Data: {
        _Data,
      },
      expiresIn: {
        hours: 10000,
      },
    });
  };
  const htmlToImageConvert = () => {
    toPng(divRef.current, { cacheBust: false }).then((dataUrl: any) => {
      console.log(dataUrl);
      const link = document.createElement("a");
      link.download = `${User.UserName}.png`;
      link.href = dataUrl;
      link.click();
    });
  };
  return (
    <div>
      <button
        className="btn btn-primary mb-2 mt-5"
        onClick={htmlToImageConvert}
      >
        Download
      </button>
      <div className="QrCode" ref={divRef}>
        <QRCode value={UserData(User)} />
        <h5 className="text-center usename">User Name: {User.UserName}</h5>
        <h5 className="text-center id">
          Id: {User.UserName.split(" ")[0] + "-" + User.Id}
        </h5>
      </div>
    </div>
  );
};

export default DivQrCode;
