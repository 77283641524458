import { Eye, EyeOff } from "lucide-react";
import React, { useState } from "react";

const Password = ({ OnChange }: { OnChange: Function }) => {
  const [IsPassword, SetIsPassword] = useState(true);
  return (
    <div className="form-group w-100 mt-3 Password">
      <label htmlFor="Password" className="ms-1 form-check-label">
        Admin Password
      </label>
      <div className="d-flex align-items-center justify-content-between">
        <input
          type={IsPassword ? "password" : "text"}
          placeholder="Enter Your Password"
          className="form-control p-2 rounded-end-0"
          name="Password"
          id="Password"
          onChange={(e) => {
            OnChange(e.target.value);
          }}
        />
        <button
          className="Btn rounded-1 p-2 px-3 m-0 rounded-start-0"
          onClick={() => {
            SetIsPassword(!IsPassword);
          }}
        >
          {IsPassword ? <Eye /> : <EyeOff />}
        </button>
      </div>
    </div>
  );
};

export default Password;
