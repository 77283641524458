import React, { useEffect, useState } from "react";
import { GetAllFormsFun } from "../../../../../../services/api/Form/Form";
import { useData } from "../../../../../../data/management/Data.Management";
import ViewForm from "./View.Form";

const GetAllForms = ({
  GetGroups,
  SchoolId,
}: {
  GetGroups: string;
  SchoolId: string;
}) => {
  const {
    Data: {
      UserId,
      LanguageData: {
        DashBord: {
          Form: {
            Messages: { NoFormsCreated },
          },
        },
      },
    },
  }: any = useData();
  let [Forms, SetForms] = useState([]);
  const GetAllForms = () => {
    GetAllFormsFun({
      UserId,
      SchoolId,
    }).then((result) => {
      if (result.Forms != undefined) {
        SetForms(result.Forms);
      }
    });
  };
  useEffect(() => {
    GetAllForms();
  }, [GetGroups]);

  return (
    <div className="Forms">
      {Forms == null && (
        <section className="w-100 d-flex align-items-center justify-content-center flex-column">
          <div
            className="spinner-border text-primary"
            style={{ width: "50px", height: "50px" }}
            role="status"
          ></div>
        </section>
      )}
      {Forms != null && Forms.length > 0 ? (
        Forms.map((Form: any, i: number) => {
          return <ViewForm OnChanges={GetAllForms} key={i} FormData={Form} />;
        })
      ) : (
        <div
          className="alert alert-primary m-auto mt-3"
          style={{ width: "fit-content" }}
        >
          {NoFormsCreated}
        </div>
      )}
    </div>
  );
};

export default GetAllForms;
