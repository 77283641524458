import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useData } from "../../../data/management/Data.Management";

const FormsType = () => {
  const {
    Data: {
      UserId,
      Roles,
      Language,
      LanguageData: {
        DashBord: {
          Form: { QuizForm, FollowUpForm, HomeWorkForm },
        },
      },
    },
  }: any = useData();
  let Navigate = useNavigate();
  return (
    <div className="FormsType">
      <div
        className="FollowUp"
        onClick={() => {
          Navigate("/Profile/FollowUp");
        }}
      >
        {FollowUpForm}
      </div>
      <div
        className="Quiz"
        onClick={() => {
          Navigate("/Profile/Quiz");
        }}
      >
        {QuizForm}
      </div>
      <div
        className="HomeWork"
        onClick={() => {
          Navigate("/Profile/HomeWork");
        }}
      >
        {HomeWorkForm}
      </div>
    </div>
  );
};

export default FormsType;
