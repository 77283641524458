import React, { useEffect, useState } from "react";
import ModalComp from "../../../../../../components/Design/Modal";
import { Edit } from "lucide-react";
import { useParams } from "react-router-dom";
import { useData } from "../../../../../../data/management/Data.Management";
import { Connect } from "../Connect.To.Socket.io";

const EditNotification = ({
  Notification,
  NotificationId,
  OnEdit,
}: {
  Notification: string;
  NotificationId: string;
  OnEdit: Function;
}) => {
  let { SchoolId } = useParams();
  const {
    Data: {
      UserId,
      LanguageData: {
        DashBord: { Notifications },
      },
    },
  }: any = useData();
  const [disabledCloseBtn, SetdisabledCloseBtn] = useState(false);
  const [disabledConfirmBtn, SetdisabledConfirmBtn] = useState(true);
  const [Loading, SetLoading] = useState(false);
  const [Show, SetShow] = useState(false);
  const [NewNotificationTitle, SetNewNotificationTitle] =
    useState(Notification);
  let Socket = Connect();
  const EditNotificationMedthod = async () => {
    SetLoading(true);
    SetdisabledCloseBtn(true);
    SetdisabledConfirmBtn(true);
    await Socket.emit("joinRoom", {
      SchoolId: SchoolId?.replaceAll("_-_", "#"),
    });
    await Socket.emit("message", {
      room: { SchoolId: SchoolId?.replaceAll("_-_", "#") },
      message: NewNotificationTitle,
      Medthod: "Edit",
      AllData: {
        NotificationId,
        UserId,
        SchoolId: SchoolId?.replaceAll("_-_", "#"),
      },
    });
    SetdisabledCloseBtn(false);
    SetdisabledConfirmBtn(false);
    SetShow(false);
    SetLoading(false);
    OnEdit(true);
  };
  useEffect(() => {
    if (
      NewNotificationTitle.length > 0 &&
      NewNotificationTitle != Notification
    ) {
      SetdisabledConfirmBtn(false);
    } else {
      SetdisabledConfirmBtn(true);
    }
  }, [NewNotificationTitle]);
  return (
    <ModalComp
      ButtonTitle={
        <>
          <span>{Notifications.Edit.EditBtn}</span> <Edit />
        </>
      }
      ModalTitle={Notifications.Edit.EditTitle}
      ConfirmBtn={
        <>
          <span>{Notifications.Edit.EditBtn}</span> <Edit />
        </>
      }
      variantBtn="primary"
      disabledCloseBtn={disabledCloseBtn}
      disabledConfirmBtn={disabledConfirmBtn}
      onClickConFirmBtn={EditNotificationMedthod}
      Show={Show}
      handleClose={() => {
        SetShow(false);
      }}
      handleShow={() => {
        SetShow(true);
      }}
      Loading={Loading}
      ButtonTitlevariant="primary"
    >
      <textarea
        className="form-control"
        onChange={(e) => {
          SetNewNotificationTitle(e.target.value);
        }}
        style={{ height: "300px", width: "100%", maxHeight: "350px" }}
        value={NewNotificationTitle}
        placeholder={Notifications.EnterYourNotification}
      ></textarea>
    </ModalComp>
  );
};

export default EditNotification;
