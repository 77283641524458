import React, { useState } from "react";
import { calculateTimePeriod } from "../../../../../../services/Format Date/Format";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { Edit, EllipsisVertical, Trash } from "lucide-react";
import { useData } from "../../../../../../data/management/Data.Management";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import DeleteNotification from "./Delete.Notification";
import EditNotification from "./Edit.Notification";
const Notification = ({
  Notification,
  OnDeleted,
  Index,
}: {
  Notification: any;
  OnDeleted?: Function;
  Index: number;
}) => {
  const {
    Data: {
      Roles,

      LanguageData: {
        DashBord: { Notifications },
        ShowMore,
        ShowLess,
      },
    },
  }: any = useData();
  let [_Notification, SetNotification] = useState(
    Notification.NotificationTitle.length > 40
      ? Notification.NotificationTitle.slice(0, 40) + "..."
      : Notification.NotificationTitle
  );
  const [BtnText, SetBtnText] = useState(ShowMore);
  const [IsShow, SetIsShow] = useState(false);
  const ToggleView = () => {
    if (IsShow) {
      SetIsShow(false);
      SetBtnText(ShowMore);
      SetNotification(
        Notification.NotificationTitle.length > 40
          ? Notification.NotificationTitle.slice(0, 40) + "..."
          : Notification.NotificationTitle
      );
    } else {
      SetIsShow(true);
      SetBtnText(ShowLess);
      SetNotification(Notification.NotificationTitle);
    }
  };
  return (
    <div
      className="Message"
      dir={
        /[\u0600-\u06FF]/.test(Notification.NotificationTitle) ? "rtl" : "ltr"
      }
    >
      {Roles.includes("Admin") && OnDeleted != undefined && (
        <DropdownButton
          id="dropdown-basic-button"
          title={<EllipsisVertical size={18} />}
          variant="outline-primary"
          style={{
            width: "40px",
            position: "absolute",
            top: "10px",
          }}
        >
          <ul>
            <li>
              <DeleteNotification
                Notification={Notification.NotificationTitle}
                NotificationId={Notification.NotificationId}
                OnDeleted={OnDeleted}
              />
            </li>
            <li>
              <EditNotification
                Notification={Notification.NotificationTitle}
                NotificationId={Notification.NotificationId}
                OnEdit={OnDeleted}
              />
            </li>
          </ul>
        </DropdownButton>
      )}
      <p>
        {_Notification}
        {Notification.NotificationTitle.length > 40 && (
          <button onClick={ToggleView} className="ToggleView">
            {" "}
            {BtnText}{" "}
          </button>
        )}
      </p>
      <span className="Time" dir="ltr">
        {calculateTimePeriod(Notification.CreateAt)}
      </span>
    </div>
  );
};

export default Notification;
