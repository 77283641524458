import React, { useEffect, useState } from "react";

import {
  Decrypt_Back,
  Encrypt_Back,
} from "../../../../../services/jwt with backend/JWT.With.Backend";
import { useData } from "../../../../../data/management/Data.Management";
import { GetAllUsers } from "../../../../../services/api/User/User";
import { calculateTimePeriod } from "../../../../../services/Format Date/Format";

const FormViewData = ({
  Answer,
  Header,
  OnChangeParent,
}: {
  Answer: any;
  Header: any;
  OnChangeParent: number;
}) => {
  const {
    Data: { UserId },
  }: any = useData();
  const [Users, SetUsers] = useState([]);
  const [IsShow, SetIsShow] = useState(false);
  useEffect(() => {
    GetAllUsers({
      Token: Encrypt_Back({
        Data: {
          UserId,
        },
        expiresIn: {
          minutes: 5,
        },
      }),
    }).then((result) => {
      SetUsers(Decrypt_Back({ Data: result.Data.Users }).Data);
    });
  });
  const GetUserName = (Email: string) => {
    let _User: any = Users.filter((User: any) => User.Email == Email);

    if (_User.length == 1) return _User[0].UserName;
    else return "No User Name";
  };
  useEffect(() => {
    SetIsShow(false);
  }, [OnChangeParent]);
  return (
    <div className="FirstCard">
      <div className="UserName">
        <span>
          {calculateTimePeriod(
            new Date(Answer[0])
              .toLocaleString("en-US", {
                timeZone: "Africa/Cairo",
              })
              .toString()
          )}
          <span>. </span>
          {new Date(Answer[0])
            .toLocaleString("en-US", {
              timeZone: "Africa/Cairo",
            })
            .toString()}
        </span>

        <span>{GetUserName(Answer[1])}</span>
        <hr />
      </div>
      <button
        className="btn btn-primary mx-auto"
        style={{ width: "fit-content" }}
        onClick={() => {
          SetIsShow(!IsShow);
        }}
      >
        {!IsShow ? "Show" : "Hide"}
      </button>
      <hr />
      <div
        className={`Answers`}
        style={{ display: `${IsShow ? "block" : "none"}` }}
      >
        {Answer.map((ChildOfAnswer: any, i: number) => {
          return (
            <div key={i} className={`${i == 1 ? "UserName" : ""}`}>
              {i == 1 ? (
                ""
              ) : (
                <>
                  <span>{Header[i]}</span>
                  <br />
                  <span className="Answer">
                    {isLink(ChildOfAnswer) ? (
                      <a href={`${ChildOfAnswer}`} target="_blank">
                        {ChildOfAnswer}
                      </a>
                    ) : (
                      ChildOfAnswer
                    )}
                  </span>
                </>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
function isLink(text: string) {
  const urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.?)+[a-zA-Z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-zA-Z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-zA-Z\\d_]*)?$", // fragment locator
    "i" // case insensitive
  );

  return urlPattern.test(text);
}

export default FormViewData;
