import React, { useEffect, useState } from "react";
import "../../../../../styles/DashBord/Library/Library.Page.style.css";
import { useData } from "../../../../../data/management/Data.Management";
import {
  CreateLibraryFun,
  GetAllLibrariesForSchool,
} from "../../../../../services/api/Library/Library";
import "../../../../../styles/DashBord/Library/Libraries.style.css";
import CreateLibrary from "./components/Create.Library";
import { useParams } from "react-router-dom";
import LibraryComponent from "./Library.Component";
import { GetSchoolAllInfoFun } from "../../../../../services/api/school/School";
import { GetPlayList } from "../../../../../services/api/PlayList/PlayList";

const LibraryPage = () => {
  const {
    Data: {
      Roles,
      UserData,
      UserId,
      LanguageData: {
        DashBord: {
          Library: { Message },
        },
        Errors: { SchoolNotFound },
      },
    },
  }: any = useData();
  let { SchoolId, PlayListId } = useParams();
  const [Libraries, SetLibraries]: any = useState(null);
  let GetLibraries = () => {
    GetPlayList({
      PlayListId: `${PlayListId?.replaceAll("_-_", "#")}`,
      UserId: UserId,
    })
      .then((result) => {
        SetLibraries([]);
        SetLibraries(result.PlayList.Libraries);
      })
      .catch((err) => {});
    // if (UserData.SchoolId == null) {
    //   GetAllLibrariesForSchool({
    //     SchoolId: `${SchoolId?.replaceAll("_-_", "#")}`,
    //     UserId: UserData.UserId,
    //     PlayListId: `${PlayListId?.replaceAll("_-_", "#")}`,
    //   }).then((result) => {
    //     if (result != null) {
    //       SetLibraries(result.Libraries);
    //       SetLoading(false);
    //     } else {
    //       SetLibraries([]);
    //       SetLoading(false);
    //     }
    //   });
    // } else {
    //   GetAllLibrariesForSchool({
    //     SchoolId: `${UserData.SchoolId}`,
    //     UserId: UserData.UserId,
    //     PlayListId: `${PlayListId?.replaceAll("_-_", "#")}`,
    //   }).then((result) => {
    //     console.log(result);
    //     if (result != null) {
    //       SetLibraries(null);
    //       SetLibraries(result.Libraries);
    //       SetLoading(false);
    //     } else {
    //       SetLibraries([]);
    //       SetLoading(false);
    //     }
    //   });
    // }
  };
  const [Loading, SetLoading] = useState(true);
  const [Show, SetShow] = useState(false);
  useEffect(() => {
    GetSchoolAllInfoFun({
      SchoolId: `${SchoolId?.replaceAll("_-_", "#")}`,
      UserId: UserId,
    }).then((result) => {
      SetLoading(false);
      if (result != null) {
        SetShow(true);
        GetLibraries();
      } else {
        if (!Roles.includes("Admin")) {
          SetShow(true);
          GetLibraries();
        }
      }
    });
  }, []);
  return (
    <div
      id="LibraryPage"
      className={`${Roles.includes("Admin") ? "Admin" : ""} ${
        Roles.includes("Leader") && !Roles.includes("Admin") ? "Leader" : ""
      }`}
    >
      {Loading ? (
        <div className="d-flex w-100 align-items-center justify-content-center mt-2">
          <div
            className="spinner-border text-primary"
            style={{ width: "50px", height: "50px" }}
            role="status"
          ></div>
        </div>
      ) : (
        <>
          {Show ? (
            <>
              {Roles.includes("Admin") && (
                <CreateLibrary
                  SchoolId={`${SchoolId?.replaceAll("_-_", "#")}`}
                  OnCreated={GetLibraries}
                  UserId={UserData.UserId}
                />
              )}
              <div className="Libraries">
                {Libraries != null && Libraries.length > 0 ? (
                  Libraries.map((_Library: any, i: number) => {
                    return (
                      <LibraryComponent
                        OnDataChanged={GetLibraries}
                        key={i}
                        SchoolId={`${SchoolId?.replaceAll("_-_", "#")}`}
                        UserId={UserData.UserId}
                        LibraryData={_Library}
                      />
                    );
                  })
                ) : (
                  <div className="alert alert-success">{Message}</div>
                )}
              </div>
            </>
          ) : (
            <div className="d-flex align-items-center w-100 justify-content-center">
              <div className="alert alert-info text-center">
                {SchoolNotFound}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default LibraryPage;
