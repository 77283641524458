import { calculateTimePeriod } from "../../../../../../services/Format Date/Format";
import { EllipsisVertical } from "lucide-react";
import { useNavigate } from "react-router-dom";

const School = ({ SchoolData }: { SchoolData: any }) => {
  const Navigate = useNavigate();
  return (
    <div className="School position-relative">
      <button
        className="more btn btn-outline-primary d-flex align-items-center justify-content-center p-2 position-absolute rounded-circle"
        style={{ right: "10px", top: "10px", width: "40px", height: "40px" }}
        onClick={() => {
          Navigate(
            `/DashBord/School/${SchoolData.SchoolId.replaceAll(
              "#",
              "_-_"
            )}/home`
          );
        }}
      >
        <EllipsisVertical />
      </button>
      <div
        className="Image"
        style={{ backgroundImage: `url("${SchoolData.SchoolSrcImage}")` }}
      ></div>
      <h3>{SchoolData.SchoolName}</h3>
      <h5>{calculateTimePeriod(SchoolData.CreateAt)}</h5>
    </div>
  );
};

export default School;
