import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import Router from "./router/Router";
import { DataManagement } from "./data/management/Data.Management";
import "bootstrap/dist/css/bootstrap.min.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleId } from "./services/Variables";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AOS from "aos";
import "aos/dist/aos.css";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
AOS.init();
root.render(
  // <React.StrictMode>
  <GoogleOAuthProvider clientId={GoogleId}>
    <DataManagement>
      <Router />
      <ToastContainer stacked position="bottom-right" />
    </DataManagement>
  </GoogleOAuthProvider>
  // {/* </React.StrictMode> */}
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
