import axios from "axios";
import { BackendURL } from "../URLs";
import { GraphQlURL } from "../../../data/main/URLS";
import { Decrypt_BackQraphQl } from "../../jwt with backend/JWT.With.Backend";
export const CreateGroupFun = ({ Token }: { Token: string }) => {
  return axios
    .post(`${BackendURL}Group/Create`, { Token, date: `${new Date()}` })
    .then((result) => {
      return result.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
export const DeleteGroupFun = ({ Token }: { Token: string }) => {
  return axios
    .post(`${BackendURL}Group/Delete`, { Token, date: `${new Date()}` })
    .then((result) => {
      return result.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
export const EditGroupFun = ({ Token }: { Token: string }) => {
  return axios
    .post(`${BackendURL}Group/Edit`, { Token, date: `${new Date()}` })
    .then((result) => {
      return result.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
export const GetLeaderGroupFun = ({ UserId }: { UserId: string }) => {
  return axios
    .post(`${GraphQlURL}`, {
      query: `
      query Query($UserId:String) {
        LeaderGroup(UserId:$UserId) {
        UserName
        Id
        }
      }
`,
      variables: {
        UserId,
      },
    })
    .then((result) => {
      return Decrypt_BackQraphQl({ Data: result.data.data.data }).Data;
    });
};
