import React, { useEffect, useState } from "react";
import ModalComp from "../../../../../../components/Design/Modal";
import { Edit, Pencil } from "lucide-react";
import { EditLevelFun } from "../../../../../../services/api/Level/Level";
import { Encrypt_Back } from "../../../../../../services/jwt with backend/JWT.With.Backend";
import { toast } from "react-toastify";
import { useData } from "../../../../../../data/management/Data.Management";
import CreatableSelect from "react-select/creatable";

const EditBtnLevel = ({
  LevelData,
  UserId,
  OnEdit,
}: {
  LevelData: any;
  UserId: string;
  OnEdit: Function;
}) => {
  const {
    Data: {
      LanguageData: {
        DashBord: {
          Level: { Edit, Create },
        },
      },
    },
  }: any = useData();
  const [disabledCloseBtn, SetdisabledCloseBtn] = useState(false);
  const [disabledConfirmBtn, SetdisabledConfirmBtn] = useState(true);
  const [Loading, SetLoading] = useState(false);
  const [Show, SetShow] = useState(false);
  const [LevelName, SetLevelName] = useState(LevelData.LevelTitle);
  const [Status, SetStatus]: any = useState({
    value: LevelData.Status,
    label: LevelData.Status,
  });
  let LevelStatus = [
    {
      value: "Active",
      label: "Active",
    },
    {
      value: "Finish",
      label: "Finish",
    },
    {
      value: "Will-Start",
      label: "Will-Start",
    },
  ];
  const EditLevel = () => {
    SetLoading(true);
    EditLevelFun({
      Token: Encrypt_Back({
        Data: {
          UserId,
          LevelId: LevelData.LevelId,
          SchoolId: LevelData.SchoolId,
          LevelTitle: LevelName,
          Status: Status.value,
        },
        expiresIn: {
          minutes: 5,
        },
      }),
    }).then((result) => {
      SetShow(!result.Data.Updated);
      toast.success(result.Data.Message, {
        closeOnClick: true,
        theme: "dark",
      });
      OnEdit(true);
      SetLoading(false);
    });
  };
  useEffect(() => {
    if (
      (LevelName != LevelData.LevelTitle &&
        LevelName.replace(/ /g, "").length > 0) ||
      (Status.value != LevelData.Status && Status != null)
    ) {
      SetdisabledConfirmBtn(false);
    } else {
      SetdisabledConfirmBtn(true);
    }
  }, [LevelName, LevelData, Status]);
  return (
    <ModalComp
      ButtonTitle={
        <>
          <Pencil />
        </>
      }
      ButtonTitlevariant="primary"
      ModalTitle={Edit.EditLevel}
      ConfirmBtn={
        <>
          <span className="me-2">{Edit.EditBtn}</span>
          <Pencil />
        </>
      }
      variantBtn="primary"
      variantModal="light"
      disabledCloseBtn={disabledCloseBtn}
      disabledConfirmBtn={disabledConfirmBtn}
      onClickConFirmBtn={EditLevel}
      Show={Show}
      handleClose={() => {
        SetShow(false);
      }}
      handleShow={() => {
        SetShow(true);
      }}
      Loading={Loading}
    >
      <input
        type="text"
        onChange={(e) => {
          SetLevelName(e.target.value);
        }}
        value={LevelName}
        className="form-control mb-3"
        placeholder={Create.LevelName}
      />
      <CreatableSelect
        className="text-dark"
        isClearable
        options={LevelStatus}
        onChange={(e: any) => {
          if (e != null) {
            SetStatus(e);
          } else {
            SetStatus(null);
          }
        }}
        value={Status}
        placeholder={Edit.ChangeStatus}
      />
    </ModalComp>
  );
};
export default EditBtnLevel;
