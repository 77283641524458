import React, { useEffect, useState } from "react";
import { useData } from "../../../../../../data/management/Data.Management";
import ModalComp from "../../../../../../components/Design/Modal";
import { Pencil, Trash } from "lucide-react";
import { EditSchoolsFun } from "../../../../../../services/api/school/School";
import { Encrypt_Back } from "../../../../../../services/jwt with backend/JWT.With.Backend";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const EditSchool = ({
  SchoolData,
  UserId,
  OnEditd,
}: {
  SchoolData: any;
  UserId: string;
  OnEditd: Function;
}) => {
  const {
    Data: {
      LanguageData: {
        DashBord: {
          School: { Edit },
        },
      },
    },
  }: any = useData();
  const [disabledCloseBtn, SetdisabledCloseBtn] = useState(false);
  const [disabledConfirmBtn, SetdisabledConfirmBtn] = useState(false);
  const [Loading, SetLoading] = useState(false);
  const [Show, SetShow] = useState(false);
  const [SchoolName, SetSchoolName] = useState(SchoolData.SchoolName);
  let Navigate = useNavigate();
  const EditSchool = async () => {
    SetLoading(true);

    EditSchoolsFun({
      Token: Encrypt_Back({
        Data: {
          SchoolId: SchoolData.SchoolId,
          UserId,
          SchoolName,
        },
        expiresIn: {
          minutes: 5,
        },
      }),
    }).then((result) => {
      SetShow(!result.Data.Updated);
      toast.success(result.Data.Message, {
        closeOnClick: true,
        theme: "dark",
      });
      OnEditd(true);
      SetLoading(false);
      Navigate("/DashBord/");
    });
  };
  useEffect(() => {
    if (SchoolName.length > 0 && SchoolName != SchoolData.SchoolName) {
      SetdisabledConfirmBtn(false);
    } else {
      SetdisabledConfirmBtn(true);
    }
  }, [SchoolName]);
  return (
    <ModalComp
      ButtonTitle={
        <>
          <Pencil />
        </>
      }
      ButtonTitlevariant="primary"
      ModalTitle={Edit.EditSchool}
      ConfirmBtn={
        <>
          <span className="me-2">{Edit.EditBtn}</span>
          <Pencil />
        </>
      }
      variantBtn="primary"
      variantModal="light"
      disabledCloseBtn={disabledCloseBtn}
      disabledConfirmBtn={disabledConfirmBtn}
      onClickConFirmBtn={EditSchool}
      Show={Show}
      handleClose={() => {
        SetShow(false);
      }}
      handleShow={() => {
        SetShow(true);
      }}
      Loading={Loading}
    >
      <input
        type="text"
        value={SchoolName}
        className="form-control"
        onChange={(e) => {
          SetSchoolName(e.target.value);
        }}
      />
    </ModalComp>
  );
};

export default EditSchool;
