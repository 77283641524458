import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GetAnswersFormFun } from "../../../../../services/api/Form/Form";
import {
  Decrypt_Back,
  Encrypt_Back,
} from "../../../../../services/jwt with backend/JWT.With.Backend";
import { useData } from "../../../../../data/management/Data.Management";
import Pagination from "@mui/material/Pagination";
import { GetAllUsers } from "../../../../../services/api/User/User";
import FormViewData from "./Form.View.Data";

const FormPage = () => {
  const {
    Data: { UserId },
  }: any = useData();
  const { FormId, SchoolId } = useParams();
  const [Answers, SetAnswers]: any = useState([]);
  const [CurrentPageIndex, SetCurrentPageIndex] = useState(0);
  const [Header, SetHeader] = useState([]);
  const [Loading, SetLoading] = useState(true);
  const [Show, SetShow] = useState(false);
  const [IsShow, SetIsShow] = useState(false);
  const [Users, SetUsers] = useState([]);
  useEffect(() => {
    GetAllUsers({
      Token: Encrypt_Back({
        Data: {
          UserId,
        },
        expiresIn: {
          minutes: 5,
        },
      }),
    }).then((result) => {
      SetUsers(Decrypt_Back({ Data: result.Data.Users }).Data);
    });
    GetAnswersFormFun({
      Token: Encrypt_Back({
        Data: {
          FormId: `${FormId?.replaceAll("_-_", "#")}`,
          UserId,
          SchoolId: `${SchoolId?.replaceAll("_-_", "#")}`,
        },
        expiresIn: {
          minutes: 5,
        },
      }),
    }).then((result) => {
      let Response = Decrypt_Back({ Data: result.Token });
      if (Response) {
        SetAnswers(
          paginateArray(
            Response.Data.Answers.splice(1, Response.Data.Answers.length),
            4
          )
        );
        SetHeader(Response.Data.Answers[0]);
        SetLoading(false);
        SetShow(true);
      }
    });
  }, []);

  return (
    <>
      <div className="w-100 d-flex align-items-center justify-content-center flex-column mt-4">
        {Loading ? (
          <div className="d-flex w-100 align-items-center justify-content-center mt-2">
            <div
              className="spinner-border text-primary"
              style={{ width: "50px", height: "50px" }}
              role="status"
            ></div>
          </div>
        ) : (
          <>
            {Show && (
              <div className="FormData">
                <div className="ViewData">
                  {Answers.length > 0 &&
                    Answers[CurrentPageIndex].map((Answer: any, i: number) => {
                      return (
                        <FormViewData
                          Answer={Answer}
                          Header={Header}
                          OnChangeParent={CurrentPageIndex}
                        />
                      );
                    })}
                </div>
                <div className="Pagination">
                  {Answers.length > 0 && (
                    <Pagination
                      count={Answers.length}
                      variant="outlined"
                      color="secondary"
                      onChange={(e, value) => {
                        SetCurrentPageIndex(value - 1);
                      }}
                    />
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default FormPage;
export function paginateArray(arr: any[], pageSize: number) {
  const result = [];
  for (let i = 0; i < arr.length; i += pageSize) {
    const page = arr.slice(i, i + pageSize);
    result.push(page);
  }
  return result;
}
