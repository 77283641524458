import React, { useEffect, useState } from "react";
import { useData } from "../../data/management/Data.Management";
import "../../styles/YourGroupPage/Your-Group.Page.style.css";
import { GetLeaderGroupFun } from "../../services/api/Group/Group";
import GetAllUsers from "./components/Get.All.Users";

const YourGroupPage = () => {
  const {
    Data: {
      Roles,
      Language,
      UserData: { Email },
      LanguageData: {
        Profile,
        Errors: { UsersNotCreated },
      },
      UserId,
    },
  }: any = useData();
  const [Loading, SetLoading] = useState(true);
  const [Show, SetShow] = useState(false);
  const [Users, SetUsers] = useState([]);
  useEffect(() => {
    GetLeaderGroupFun({
      UserId,
    }).then((result) => {
      if (result.LeaderGroup.length > 0) {
        SetUsers(result.LeaderGroup);
        SetShow(true);
      }
      SetLoading(false);
    });
  }, []);
  return (
    <div
      id="YourGroupPage"
      className={`d-flex align-items-center justify-content-center flex-column ${
        Roles.length == 1 && Roles.includes("Student") ? "" : "Leader"
      } ${Language == "Ar" ? "Left" : "Right"}`}
    >
      <div className="w-100">
        {Loading ? (
          <div className="w-100">
            <div
              className="spinner-border text-primary"
              style={{ width: "50px", height: "50px" }}
              role="status"
            ></div>
          </div>
        ) : (
          <>
            {Show ? (
              <>
                <GetAllUsers Users={Users} />
              </>
            ) : (
              <div className="d-flex align-items-center w-100 justify-content-center">
                <div className="alert alert-info text-center">
                  {UsersNotCreated}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default YourGroupPage;
