export const KeyJWT =
  "f5a28170-1a26-4df8-8693-1a13c635d3e06b55cbcd-26c1-4e89-844f-8debf77fcf47";
// export const GoogleId =
//   "575714369748-s11rv9vfqc2e1669icrbn4prams9kipc.apps.googleusercontent.com";
export const GoogleId =
  "575714369748-c3ohqcjt3426s1frmfu355tkaad1jql6.apps.googleusercontent.com";
// export const KeyJWT =
//   "f5a28170-1a26-4df8-8693-1a13c635d3e06b55cbcd-26c1-4e89-844f-8debf77fcf47";
// export const GoogleId =
//   "575714369748-c3ohqcjt3426s1frmfu355tkaad1jql6.apps.googleusercontent.com";
