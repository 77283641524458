import En from "./Languages/En.json";
export interface InitialValue {
  IsLoggin: boolean;
  UserData:
    | {
        Email: string;
        UserName: string;
        UserId: string;
        Role: "User" | "Admin" | "Manger" | "";
        Permissions: any[];
      }
    | {};
  Roles: ["User" | "Admin" | "Manger" | ""];
  Permissions: any[];
  Theme: "Dark" | "Light";
  Language: "En" | "Ar";
  LanguageData: any;
  UserId: string | "";
}
export const InitialValue: InitialValue = {
  IsLoggin: false,
  UserData: {},
  Roles: [""],
  Theme: "Light",
  Language: "En",
  LanguageData: En,
  UserId: "",
  Permissions: [],
};
