// PreparingEnvironment
import axios from "axios";
import { BackendURL } from "../URLs";
import { GraphQlURL } from "../../../data/main/URLS";
import {
  Decrypt_Back,
  Decrypt_BackQraphQl,
} from "../../jwt with backend/JWT.With.Backend";
export const DeleteFormFun = ({ Token }: { Token: string }) => {
  return axios
    .post(`${BackendURL}Form/Delete`, {
      Token,
      date: `${new Date()}`,
    })
    .then((result) => {
      return result.data.Data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
export const EditFormFun = ({ Token }: { Token: string }) => {
  return axios
    .patch(`${BackendURL}Form/Edit`, {
      Token,
      date: `${new Date()}`,
    })
    .then((result) => {
      return result.data.Data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
export const CreateFormFun = ({ Token }: { Token: string }) => {
  return axios
    .post(`${BackendURL}Form/Create`, {
      Token,
      date: `${new Date()}`,
    })
    .then((result) => {
      return result.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
export const GetAnswersFormFun = ({ Token }: { Token: string }) => {
  return axios
    .post(`${BackendURL}Form/GetAnswers`, {
      Token,
      date: `${new Date()}`,
    })
    .then((result) => {
      return result.data.Data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const GetAllFormsFun = ({
  UserId,
  SchoolId,
}: {
  UserId: string;
  SchoolId: string;
}) => {
  return axios
    .post(`${GraphQlURL}`, {
      query: `
        query Query($UserId:String,$SchoolId:String) {
          Forms(UserId:$UserId,SchoolId:$SchoolId) {
              FormId
              FormTitle
              FormDescription
              SchoolId
              CreateAt
              GoogleSheetId
              FormUrl
              Status
              Type
              Score
          }
        }
      `,
      variables: {
        UserId,
        SchoolId,
      },
    })
    .then((result) => {
      return Decrypt_BackQraphQl({ Data: result.data.data.data }).Data;
    });
};
export const GetAllFormsForNormalUserFun = ({
  UserId,
  SchoolId,
}: {
  UserId: string;
  SchoolId: string;
}) => {
  return axios
    .post(`${GraphQlURL}`, {
      query: `
        query Query($UserId:String,$SchoolId:String,$date:String) {
          FormsNormalUser(UserId:$UserId,SchoolId:$SchoolId,date:$date) {
              FormId
              FormTitle
              FormDescription
              CreateAt
              FormUrl              
          }
        }
      `,
      variables: {
        UserId,
        SchoolId,
        date: `${new Date()}`,
      },
    })
    .then((result) => {
      return Decrypt_BackQraphQl({ Data: result.data.data.data }).Data;
    });
};
