import React, { useEffect, useState } from "react";
import ModalComp from "../../../../../../components/Design/Modal";
import { Pencil } from "lucide-react";
import {
  Decrypt_Back,
  Encrypt_Back,
} from "../../../../../../services/jwt with backend/JWT.With.Backend";
import { toast } from "react-toastify";
import { useData } from "../../../../../../data/management/Data.Management";
import { EditPlayListFun } from "../../../../../../services/api/PlayList/PlayList";
import { GetAllLevelsOfSchool } from "../../../../../../services/api/Level/Level";
import CreatableSelect from "react-select/creatable";
import { _Date } from "../../../../../../services/Format Date/Format";

const EditPlayList = ({
  PlayListData,
  UserId,
  SchoolId,
  OnEdit,
}: {
  PlayListData: any;
  UserId: string;
  SchoolId: string;
  OnEdit: Function;
}) => {
  let {
    Data: {
      LanguageData: {
        DashBord: {
          PlayList: { Create, Edit },
          PlayList,
        },
      },
    },
  }: any = useData();
  const [TypeOfPlayListSelectOption]: any = useState([
    {
      value: true,
      label: "Visible",
    },
    {
      value: false,
      label: "Hidden",
    },
  ]);
  const [disabledCloseBtn, SetdisabledCloseBtn] = useState(false);
  const [disabledConfirmBtn, SetdisabledConfirmBtn] = useState(true);
  const [DisabledSelectBox, SetDisabledSelectBox] = useState(false);
  const [Loading, SetLoading] = useState(false);
  const [Show, SetShow] = useState(false);
  const [Title, SetTitle] = useState(PlayListData.Title);

  const [Type, SetType]: any = useState({
    label: PlayListData.IsVisible == "true" ? "Visible" : "Hidden",
    value: PlayListData.IsVisible == "true" ? true : false,
  });

  const [Level, SetLevel]: any = useState({
    label: PlayListData.Level.LevelTitle,
    value: PlayListData.Level.LevelId,
  });
  const [Levels, SetLevels]: any = useState(null);

  useEffect(() => {
    GetAllLevelsOfSchool({
      UserId,
      SchoolId,
    }).then((result) => {
      if (result.Levels.length > 0) {
        let _Levels = result.Levels;
        let Selection_Levels = [];
        for (let i = 0; i < _Levels.length; i++) {
          const element = _Levels[i];
          Selection_Levels.push({
            label: element.LevelTitle,
            value: element.LevelId,
          });
        }
        SetLevels(Selection_Levels);
      }
    });
  }, []);
  const EditPlayListMedthod = () => {
    SetLoading(true);

    EditPlayListFun({
      Token: Encrypt_Back({
        Data: {
          UserId,
          Title,
          IsVisible: Type.value,
          ImageUrl: "",
          LevelId: Level.value,
          SchoolId,
          DateOfPlayList: _Date(),
          PlayListId: PlayListData.PlayListId,
        },
        expiresIn: { minutes: 5 },
      }),
    }).then((result: any) => {
      if (result.Data.UpDated) {
        SetShow(!result.Data.UpDated);
        toast.success(result.Data.Message, {
          closeOnClick: true,
          theme: "dark",
        });
        OnEdit(true);
        SetShow(false);
        SetTitle("");
        SetDisabledSelectBox(false);
      } else {
        toast.error(result.Data.Message, {
          closeOnClick: true,
          theme: "dark",
        });
      }
      SetLoading(false);
    });
  };
  useEffect(() => {
    if (
      (Type.value != null &&
        Level.value != null &&
        Title.length != 0 &&
        Level.value != PlayListData.Level.LevelId) ||
      Type.value != (PlayListData.IsVisible == "true" ? true : false) ||
      Title != PlayListData.Title
    ) {
      SetdisabledConfirmBtn(false);
      SetdisabledCloseBtn(true);
    } else {
      SetdisabledConfirmBtn(true);
      SetdisabledCloseBtn(false);
    }
  }, [Title, Type, Level]);
  return (
    <ModalComp
      ButtonTitle={
        <>
          <Pencil />
        </>
      }
      ButtonTitlevariant="primary"
      ModalTitle={Edit.EditPlayList}
      ConfirmBtn={
        <>
          <span className="me-2">{Edit.EditBtn}</span>
          <Pencil />
        </>
      }
      variantBtn="primary"
      variantModal="light"
      disabledCloseBtn={disabledCloseBtn}
      disabledConfirmBtn={disabledConfirmBtn}
      onClickConFirmBtn={EditPlayListMedthod}
      Show={Show}
      handleClose={() => {
        SetShow(false);
      }}
      handleShow={() => {
        SetShow(true);
      }}
      Loading={Loading}
    >
      <div>
        <CreatableSelect
          className="text-dark mb-2"
          isClearable
          options={Levels}
          value={Level}
          onChange={(e: any) => {
            if (e != null) {
              SetLevel(e);
            } else {
              SetLevel(null);
            }
          }}
          placeholder={Create.Message2}
          isDisabled={DisabledSelectBox}
        />
        <CreatableSelect
          className="text-dark"
          isClearable
          options={TypeOfPlayListSelectOption}
          onChange={(e: any) => {
            if (e != null) {
              SetType(e);
            } else {
              SetType(null);
            }
          }}
          placeholder={Create.Message}
          isDisabled={DisabledSelectBox}
          value={Type}
        />
        <input
          type="text"
          placeholder={Create.CreateNewPlayList}
          className="w-100 form-control my-3"
          onChange={(e) => {
            SetTitle(e.target.value);
          }}
          value={Title}
        />
      </div>
    </ModalComp>
  );
};

export default EditPlayList;
