import React, { useState } from "react";
import ModalComp from "../../../../../../components/Design/Modal";
import { Trash } from "lucide-react";
import { Connect } from "../Connect.To.Socket.io";
import { useParams } from "react-router-dom";
import { useData } from "../../../../../../data/management/Data.Management";

const DeleteNotification = ({
  Notification,
  NotificationId,
  OnDeleted,
}: {
  Notification: string;
  NotificationId: string;
  OnDeleted: Function;
}) => {
  let { SchoolId } = useParams();
  const {
    Data: {
      UserId,
      LanguageData: {
        DashBord: {
          Notifications: { Delete },
        },
      },
    },
  }: any = useData();

  const [disabledCloseBtn, SetdisabledCloseBtn] = useState(false);
  const [disabledConfirmBtn, SetdisabledConfirmBtn] = useState(false);
  const [Loading, SetLoading] = useState(false);
  const [Show, SetShow] = useState(false);
  let Socket = Connect();

  const DeleteNotificationMedthod = async () => {
    SetLoading(true);
    SetdisabledCloseBtn(true);
    SetdisabledConfirmBtn(true);
    await Socket.emit("joinRoom", {
      SchoolId: SchoolId?.replaceAll("_-_", "#"),
    });
    await Socket.emit("message", {
      room: { SchoolId: SchoolId?.replaceAll("_-_", "#") },
      message: Notification,
      Medthod: "Delete",
      AllData: {
        NotificationId,
        UserId,
        SchoolId: SchoolId?.replaceAll("_-_", "#"),
      },
    });
    SetdisabledCloseBtn(false);
    SetdisabledConfirmBtn(false);
    SetShow(false);
    SetLoading(false);
    OnDeleted(true);
  };
  return (
    <ModalComp
      ButtonTitle={
        <>
          <span>{Delete.DeleteBtn}</span> <Trash />
        </>
      }
      ModalTitle={Delete.DeleteTitle}
      ConfirmBtn={
        <>
          <span>{Delete.DeleteBtn}</span> <Trash />
        </>
      }
      variantBtn="danger"
      disabledCloseBtn={disabledCloseBtn}
      disabledConfirmBtn={disabledConfirmBtn}
      onClickConFirmBtn={DeleteNotificationMedthod}
      Show={Show}
      handleClose={() => {
        SetShow(false);
      }}
      handleShow={() => {
        SetShow(true);
      }}
      Loading={Loading}
      ButtonTitlevariant="danger"
    >
      <input
        type="text"
        value={Notification}
        disabled={true}
        className="form-control"
      />
    </ModalComp>
  );
};

export default DeleteNotification;
