import React, { ReactNode, useContext, useState } from "react";
import { InitialValue } from "./initial.value";
import En from "./Languages/En.json";
import Ar from "./Languages/Ar.json";
import { SaveData } from "./SaveData/SaveData&Get";
const MainContext = React.createContext(InitialValue);
const DataManagement = ({ children }: { children: ReactNode }) => {
  const [AppData, SetAppData] = useState(InitialValue);
  const [, SetTime] = useState("");
  const SetMode = (Mode: "Dark" | "Light") => {
    AppData.Theme = Mode;
    SetAppData(AppData);
    SetTime(`${new Date()}`);
    SaveData({
      Key: "AppData",
      Value: AppData,
    });
  };
  const SetLanguage = (Language: "En" | "Ar") => {
    AppData.Language = Language;
    switch (Language) {
      case "Ar":
        AppData.LanguageData = Ar;
        break;
      case "En":
        AppData.LanguageData = En;
        break;
      default:
        AppData.LanguageData = En;
        break;
    }
    SetAppData(AppData);
    SaveData({
      Key: "AppData",
      Value: AppData,
    });
    SetTime(`${new Date()}`);
  };
  const SetUserLogin = (UserData: {
    Email: string;
    UserNam: string;
    Role: "User" | "Admin" | "Manger";
    UserId: string;
    Theme: "Dark" | "Light";
  }) => {
    AppData.UserId = UserData.UserId;
    AppData.Theme = UserData.Theme;
    AppData.UserData = UserData;
    SetAppData(AppData);
    SaveData({
      Key: "AppData",
      Value: AppData,
    });
    SetTime(`${new Date()}`);
  };
  const SetRoles = (Roles: ["User" | "Admin" | "Manger" | ""]) => {
    AppData.Roles = Roles;
    SetAppData(AppData);
    SaveData({
      Key: "AppData",
      Value: AppData,
    });
    SetTime(`${new Date()}`);
  };
  const SetPermissions = (Permissions: any[]) => {
    AppData.Permissions = Permissions;
    SetAppData(AppData);
    SaveData({
      Key: "AppData",
      Value: AppData,
    });
    SetTime(`${new Date()}`);
  };
  const SetTheme = (Theme: "Light" | "Dark") => {
    AppData.Theme = Theme;
    SetAppData(AppData);
    SaveData({
      Key: "AppData",
      Value: AppData,
    });
    SetTime(`${new Date()}`);
  };
  const SetLogin = (Loggin: boolean) => {
    AppData.IsLoggin = Loggin;
    SetAppData(AppData);
    SaveData({
      Key: "AppData",
      Value: AppData,
    });
    SetTime(`${new Date()}`);
  };
  const Props: any = {
    Data: AppData,
    SetMode,
    SetLanguage,
    SetTheme,
    SetUserLogin,
    SetRoles,
    SetLogin,
    SetPermissions,
  };
  return <MainContext.Provider value={Props}>{children}</MainContext.Provider>;
};

const useData = () => {
  return useContext(MainContext);
};
export { DataManagement, useData };
