import axios from "axios";
import {
  Decrypt_Back,
  Decrypt_BackQraphQl,
} from "../../jwt with backend/JWT.With.Backend";
import { GraphQlURL } from "../../../data/main/URLS";

export const GetNotificationsForSchoolFun = ({
  UserId,
}: {
  UserId: string;
}) => {
  return axios
    .post(`${GraphQlURL}`, {
      query: `
      query Query($UserId:String) {
        Notifications(UserId:$UserId) {
          NotificationTitle
          NotificationId
          CreateAt
        }
      }
  `,
      variables: {
        UserId,
      },
    })
    .then((result) => {
      return Decrypt_BackQraphQl({ Data: result.data.data.data }).Data;
    });
};
export const GetNotificationsForSchoolFunAdmin = ({
  UserId,
  SchoolId,
}: {
  UserId: string;
  SchoolId: string;
}) => {
  return axios
    .post(`${GraphQlURL}`, {
      query: `
      query Query($UserId:String,$SchoolId:String) {
        NotificationsAdmin(UserId:$UserId,SchoolId:$SchoolId) {
          NotificationTitle
          NotificationId
          CreateAt
        }
      }
  `,
      variables: {
        UserId,
        SchoolId,
      },
    })
    .then((result) => {
      return Decrypt_BackQraphQl({ Data: result.data.data.data }).Data;
    });
};
