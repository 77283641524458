import React, { useEffect, useState } from "react";
import ModalComp from "../../../../../../components/Design/Modal";
import { Edit, Pencil } from "lucide-react";
import { EditFormFun } from "../../../../../../services/api/Form/Form";
import { Encrypt_Back } from "../../../../../../services/jwt with backend/JWT.With.Backend";
import { toast } from "react-toastify";
import { useData } from "../../../../../../data/management/Data.Management";
import { getSheetId } from "./Create.Form";
import CreatableSelect from "react-select/creatable";

const EditBtnForm = ({
  FormData,
  UserId,
  OnEdit,
}: {
  FormData: any;
  UserId: string;
  OnEdit: Function;
}) => {
  const {
    Data: {
      LanguageData: {
        DashBord: {
          Form: { Edit, Create },
        },
      },
    },
  }: any = useData();
  const [disabledCloseBtn, SetdisabledCloseBtn] = useState(false);
  const [disabledConfirmBtn, SetdisabledConfirmBtn] = useState(true);
  const [Loading, SetLoading] = useState(false);
  const [Show, SetShow] = useState(false);
  const [FormTitle, SetFormTitle] = useState(FormData.FormTitle);
  const [FormDescription, SetFormDescription] = useState(
    FormData.FormDescription
  );
  const [Score, SetScore]: any = useState(
    FormData.Type == "Quiz" ? Number(FormData.Score) : null
  );
  const [GoogleSheetId, SetGoogleSheetId] = useState(FormData.GoogleSheetId);
  const [FormUrl, SetFormUrl]: any = useState(FormData.FormUrl);
  const [Status, SetStatus]: any = useState({
    value: FormData.Status,
    label: FormData.Status,
  });
  const [SelectOptions, SetSelectOptions]: any = useState([
    { value: "Active", label: "Active" },
    { value: "InActive", label: "InActive" },
    { value: "Finish", label: "Finish" },
  ]);
  const [TypeOptions, SetTypeOptions]: any = useState([
    { value: "Quiz", label: "Quiz" },
    { value: "HomeWork", label: "HomeWork" },
    { value: "FollowUp", label: "FollowUp" },
  ]);

  const [Type, SetType]: any = useState({
    value: FormData.Type,
    label: FormData.Type,
  });
  const EditForm = () => {
    SetLoading(true);
    EditFormFun({
      Token: Encrypt_Back({
        Data: {
          UserId,
          FormId: FormData.FormId,
          SchoolId: FormData.SchoolId,
          FormTitle,
          FormDescription,
          GoogleSheetId,
          FormUrl,
          Status: Status.value,
          Type: Type.value,
          Score:
            Score != 0 && Score != null ? Score : Score != 0 ? Score : null,
        },
        expiresIn: {
          minutes: 5,
        },
      }),
    }).then((result) => {
      SetShow(!result.UpDated);
      toast.success(result.Message, {
        closeOnClick: true,
        theme: "dark",
      });
      OnEdit(true);
      SetLoading(false);
    });
  };
  useEffect(() => {
    if (Status != null && Type != null) {
      if (
        (FormTitle != FormData.FormTitle &&
          FormTitle.replace(/ /g, "").length > 0) ||
        FormDescription != FormData.FormDescription ||
        GoogleSheetId != FormData.GoogleSheetId ||
        (FormUrl != FormData.FormUrl && FormUrl.replace(/ /g, "").length > 0) ||
        Status.value != FormData.Status ||
        Type.value != FormData.Type ||
        (Score != FormData.Score && Score != 0)
      ) {
        SetdisabledConfirmBtn(false);
      } else {
        SetdisabledConfirmBtn(true);
      }
    }
  }, [
    FormTitle,
    FormData,
    FormDescription,
    GoogleSheetId,
    FormUrl,
    Status,
    Type,
    Score,
  ]);

  return (
    <ModalComp
      ButtonTitle={
        <>
          <Pencil />
        </>
      }
      ButtonTitlevariant="primary"
      ModalTitle={Edit.EditForm}
      ConfirmBtn={
        <>
          <span className="me-2">{Edit.EditBtn}</span>
          <Pencil />
        </>
      }
      variantBtn="primary"
      variantModal="light"
      disabledCloseBtn={disabledCloseBtn}
      disabledConfirmBtn={disabledConfirmBtn}
      onClickConFirmBtn={EditForm}
      Show={Show}
      handleClose={() => {
        SetShow(false);
      }}
      handleShow={() => {
        SetShow(true);
      }}
      Loading={Loading}
    >
      <input
        type="text"
        disabled={Loading}
        value={FormUrl}
        onChange={(e: any) => {
          SetFormUrl(e.target.value);
        }}
        placeholder={Create.FormURL}
        className="form-control mb-3"
      />
      <CreatableSelect
        className="text-dark mb-3"
        isClearable
        options={SelectOptions}
        onChange={(e: any) => {
          try {
            if (e != null) {
              SetStatus(e);
            } else {
              SetStatus(null);
            }
          } catch {}
        }}
        value={Status}
        placeholder={Create.SelectTitle}
      />
      <CreatableSelect
        className="text-dark mb-3"
        isClearable
        options={TypeOptions}
        onChange={(e: any) => {
          try {
            if (e != null) {
              SetType(e);
            } else {
              SetType(null);
            }
          } catch {}
        }}
        value={Type}
        placeholder={Create.SelectTitle}
      />

      <input
        type="text"
        onChange={(e) => {
          SetFormTitle(e.target.value);
        }}
        value={FormTitle}
        className="form-control mb-3"
        placeholder={Create.Title}
      />
      {Type != null && Type.value == "Quiz" && (
        <input
          type="number"
          disabled={Loading}
          value={Score}
          onChange={(e: any) => {
            SetScore(e.target.value);
          }}
          placeholder={Create.Score}
          className="form-control mb-3"
        />
      )}
      <input
        type="text"
        onChange={(e) => {
          try {
            SetGoogleSheetId(getSheetId(e.target.value));
          } catch {
            SetGoogleSheetId(null);
          }
        }}
        value={GoogleSheetId}
        className="form-control mb-3"
        placeholder={Create.SheetURL}
      />
      {GoogleSheetId != null && GoogleSheetId != "" && (
        <p dir="ltr">SheetId: {GoogleSheetId}</p>
      )}

      <textarea
        name="Description"
        id="Description"
        className="form-control"
        disabled={Loading}
        dir={/[\u0600-\u06FF]/.test(FormDescription) ? "rtl" : "ltr"}
        style={{ maxHeight: "300px" }}
        placeholder={Create.Description}
        onChange={(e) => {
          SetFormDescription(e.target.value);
        }}
        value={FormDescription}
      ></textarea>
    </ModalComp>
  );
};
export default EditBtnForm;
