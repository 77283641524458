// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { deleteObject, getStorage, ref } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBWp-T7_7a8Nq60zu8MlTlkx4HpPXiZI8Q",
  authDomain: "sihambeidjabaar.firebaseapp.com",
  projectId: "sihambeidjabaar",
  storageBucket: "sihambeidjabaar.appspot.com",
  messagingSenderId: "575714369748",
  appId: "1:575714369748:web:cd7d782a1c7dbe6e011b41",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const Storage = getStorage(app);
export async function DeleteFileByUrl(fileUrl: string) {
  try {
    // Create a reference to the file to delete
    const storageRef = ref(Storage, fileUrl);

    // Delete the file
    await deleteObject(storageRef);
    return true;
  } catch (error) {
    return false;
  }
}
