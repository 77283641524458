import { KeyJWT } from "../../../services/Variables";
import CryptoJS from "crypto-js";

export const SaveData = ({ Key, Value }: { Key: string; Value: any }) => {
  localStorage.setItem(
    Key,
    CryptoJS.AES.encrypt(JSON.stringify(Value), KeyJWT).toString()
  );
  return true;
};
export const GetData = (Key: string) => {
  try {
    let Find = localStorage.getItem(Key);
    if (Find != null && Find != undefined) {
      let _Data: any = CryptoJS.AES.decrypt(Find, KeyJWT);
      _Data = JSON.parse(_Data.toString(CryptoJS.enc.Utf8));
      return _Data;
    } else {
      return null;
    }
  } catch {
    localStorage.clear();
  }
};
// sa;
