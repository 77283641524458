import React from "react";
import FormView from "./Form.View";

const GetAllForms = ({ Forms }: { Forms: any[] }) => {
  return (
    <div className="Forms">
      {Forms.map((Form: any, i: number) => {
        return <FormView key={i} FormData={Form} />;
      })}
    </div>
  );
};

export default GetAllForms;
