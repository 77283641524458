import React, { useState } from "react";
import ModalComp from "../../../../../../components/Design/Modal";
import { Trash } from "lucide-react";
import { DeleteLevelFun } from "../../../../../../services/api/Level/Level";
import { Encrypt_Back } from "../../../../../../services/jwt with backend/JWT.With.Backend";
import { toast } from "react-toastify";
import { useData } from "../../../../../../data/management/Data.Management";

const DeleteBtnLevel = ({
  LevelData,
  UserId,
  OnDeleted,
}: {
  LevelData: any;
  UserId: string;
  OnDeleted: Function;
}) => {
  const {
    Data: {
      LanguageData: {
        DashBord: {
          Level: { Delete },
        },
      },
    },
  }: any = useData();
  const [disabledCloseBtn, SetdisabledCloseBtn] = useState(false);
  const [disabledConfirmBtn, SetdisabledConfirmBtn] = useState(false);
  const [Loading, SetLoading] = useState(false);
  const [Show, SetShow] = useState(false);

  const DeleteLevel = () => {
    SetLoading(true);
    DeleteLevelFun({
      Token: Encrypt_Back({
        Data: {
          LevelId: LevelData.LevelId,
          SchoolId: LevelData.SchoolId,
          UserId,
        },
        expiresIn: {
          minutes: 5,
        },
      }),
    }).then((result) => {
      SetShow(!result.Data.Deleted);
      toast.success(result.Data.Message, {
        closeOnClick: true,
        theme: "dark",
      });
      OnDeleted(true);
      SetLoading(false);
    });
  };
  return (
    <ModalComp
      ButtonTitle={
        <>
          <Trash />
        </>
      }
      ButtonTitlevariant="danger"
      ModalTitle={Delete.DeleteLevel}
      ConfirmBtn={
        <>
          <span className="me-2">{Delete.DeleteBtn}</span>
          <Trash />
        </>
      }
      variantBtn="danger"
      variantModal="light"
      disabledCloseBtn={disabledCloseBtn}
      disabledConfirmBtn={disabledConfirmBtn}
      onClickConFirmBtn={DeleteLevel}
      Show={Show}
      handleClose={() => {
        SetShow(false);
      }}
      handleShow={() => {
        SetShow(true);
      }}
      Loading={Loading}
    >
      <input
        type="text"
        disabled={true}
        value={LevelData.LevelTitle}
        className="form-control"
      />
    </ModalComp>
  );
};
export default DeleteBtnLevel;
