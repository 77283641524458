import { Moon, Sun } from "lucide-react";
import React, { useState } from "react";
import { useData } from "../../../data/management/Data.Management";
import { ChangeThemeFun } from "../../../services/api/User/User";
import {
  Decrypt_Back,
  Encrypt_Back,
} from "../../../services/jwt with backend/JWT.With.Backend";

const ChangeTheme = () => {
  const {
    Data: {
      IsLoggin,
      Roles,
      UserData: { UserName },
      LanguageData: { HomePage },
      UserId,
      Theme,
    },
    SetTheme,
    SetPermissions,
    SetRoles,
    SetMode,
    SetLanguage,
    SetUserLogin,
  }: any = useData();
  let [IsDark, SetIsDark] = useState(Theme == "Dark" ? true : false);
  const ChangeThemeMedthod = () => {
    SetIsDark(!IsDark);
    ChangeThemeFun({
      Token: Encrypt_Back({
        Data: { UserId, Theme: !IsDark ? "Dark" : "Light" },
        expiresIn: { minutes: 5 },
      }),
    }).then((result) => {
      let UserData = Decrypt_Back({
        Data: result.Data.UserData,
      }).Data;
      SetPermissions(UserData.Permissions);
      SetRoles(UserData.Roles);
      SetTheme(UserData.Theme);
      SetLanguage(UserData.Language);
      SetUserLogin(UserData);
    });
  };
  return (
    <div className="py-2">
      {/* <button
        className="btn btn-primary"
        style={{ width: "200px" }}
        onClick={ChangeThemeMedthod}
      >
        {IsDark ? <Sun /> : <Moon />}
      </button>       */}
    </div>
  );
};

export default ChangeTheme;
