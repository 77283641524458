import { useEffect, useState } from "react";
import { HashRouter, Routes, Route } from "react-router-dom";
import HomePage from "../pages/home/home.page";
import Header from "../components/header/header";
import NotFoundPage from "../pages/not-found/not-found.page";
import ProfilePage from "../pages/profile/profile.page";
import { useData } from "../data/management/Data.Management";
import SignInPage from "../pages/sign-in/sign-in.page";
import { GetData } from "../data/management/SaveData/SaveData&Get";
import { AdminCheckFun } from "../services/api/SignIn/SignIn";
import {
  Decrypt_Back,
  Encrypt_Back,
} from "../services/jwt with backend/JWT.With.Backend";
import "../styles/AppBody/AppBody.css";
import DashBordPage from "../pages/dash-bord/dash-bord.page";
import LoginAsAdmin from "../pages/sign-in/components/LoginAsAdmin";
import NotificationsForUser from "../components/Notifications";
import NotificationsForAllSchool from "../pages/NotificationsForAllSchool/Notifications.For.All.School";
import Redirect from "../components/Redirect";
import ActionForSchool from "../pages/dash-bord/components/Page Of DashBord/school/components/Action.For.School";
import Groups from "../pages/dash-bord/components/Page Of DashBord/Groups/Groups";
import Students from "../pages/dash-bord/components/Page Of DashBord/Students/Students";
import Leaders from "../pages/dash-bord/components/Page Of DashBord/Leaders/Leaders";
import Notifications from "../pages/dash-bord/components/Page Of DashBord/Notifications/Notifications";
import Header3 from "../components/header/Header3";
import LibraryPage from "../pages/dash-bord/components/Page Of DashBord/Library/Library.page";
import GetSchoolsPage from "../pages/dash-bord/components/Page Of DashBord/school/Get.Schools";
import CreateSchoolPage from "../pages/dash-bord/components/Page Of DashBord/school/components/Create.School";
import LevelsPage from "../pages/dash-bord/components/Page Of DashBord/levels/Levels.Page";
import FormsPage from "../pages/dash-bord/components/Page Of DashBord/Forms/Forms.page";
import CreateForm from "../pages/dash-bord/components/Page Of DashBord/Forms/components/Create.Form";
import FormPage from "../pages/dash-bord/components/Page Of DashBord/Forms/Form.Page";
import QrCodePage from "../pages/dash-bord/components/Page Of DashBord/QrCode/QrCode.Page";
import YourGroupPage from "../pages/Your-Group/Your-Group.Page";
import FormsStudentPage from "../pages/Forms-Student/Forms-Student.Page";
import ShowUserData from "../pages/Your-Group/components/Show.User.Data";
import HeaderUserImg from "../components/header/Header.User.Img";
import PlayListsPage from "../pages/dash-bord/components/Page Of DashBord/PlayLists/PlayLists.page";
import FollowUpPage from "../pages/profile/components/FollowUpPage";
import QuizPage from "../pages/profile/components/QuizPage";
import HomeWorkPage from "../pages/profile/components/HomeWorkPage";
import MobileSignIn from "../pages/sign-in/Mobile.SignIn";

const Router = () => {
  const {
    Data: { IsLoggin, UserData, Theme, Language, Roles, Permissions },
    SetRoles,
    SetMode,
    SetLanguage,
    SetUserLogin,
    SetLogin,
    SetPermissions,
  }: any = useData();
  const [Show, SetShow] = useState(false);
  const [Loading, SetLoading] = useState(true);
  const [_Theme, Set_Theme] = useState(Theme);
  const [OffLine, SetOffLine] = useState(false);
  const [Messages, SetMessages]: any = useState("");
  useEffect(() => {
    if (navigator.onLine) {
      if (GetData("AppData") != null) {
        let UserDataSend = {
          ...GetData("AppData").UserData,
          UserRoles: Roles,
          UserPermissions: Permissions,
        };
        AdminCheckFun({
          Token: Encrypt_Back({
            Data: UserDataSend,
            expiresIn: {
              minutes: 5,
            },
          }),
        }).then((result) => {
          if (result.Data.Loggin) {
            let AdminData = Decrypt_Back({
              Data: result.Data.UserData,
            }).Data;
            SetRoles(AdminData.Roles);
            SetPermissions(AdminData.Permissions);
            SetMode(AdminData.Theme);
            SetLanguage(AdminData.Language);
            SetUserLogin(AdminData);
            SetLogin(true);
            SetShow(true);
            setTimeout(() => {
              SetLoading(false);
            }, 1000);
          } else {
            SetRoles([]);
            SetMode("Light");
            SetLanguage("En");
            SetUserLogin({});
            SetLogin(false);
            setTimeout(() => {
              SetShow(true);
              setTimeout(() => {
                SetLoading(false);
              }, 1000);
            }, 100);
          }
        });
      } else {
        SetShow(true);
        setTimeout(() => {
          SetLoading(false);
        }, 1000);
      }
    } else {
      if (GetData("AppData") != null) {
        let UserDataSend = {
          ...GetData("AppData").UserData,
          UserRoles: Roles,
          UserPermissions: Permissions,
        };
        SetRoles(UserDataSend.Roles);
        SetPermissions(UserDataSend.Permissions);
        SetMode(UserDataSend.Theme);
        SetLanguage(UserDataSend.Language);
        SetUserLogin(UserDataSend);
        SetLogin(true);
        SetOffLine(true);
        SetMessages(GetData("AppData").LanguageData.IfHeIsOffLine);
      } else {
      }
      SetLoading(false);
    }
  }, []);
  useEffect(() => {
    Set_Theme(Theme);
  }, [Theme]);

  return (
    <section id="App">
      <HashRouter>
        <div
          className={`d-flex align-items-center justify-content-center Layer ${
            Loading ? "" : "active"
          }`}
          style={{ height: "100vh" }}
        >
          <div
            className="spinner-border spinner-dark SpecialSpinner"
            style={{
              width: "100px",
              height: "100px",
            }}
          ></div>
        </div>
        {OffLine && (
          <div
            className={`w-100 d-flex text-center text-capitalize align-items-center justify-content-center flex-column `}
            style={{ height: "100vh" }}
          >
            <div className="alert alert-primary m-2">{Messages.Message}</div>
            <div
              className="alert alert-primary m-2"
              dir={Language == "Ar" ? "rtl" : "ltr"}
            >
              {Messages.Message2[0] +
                " " +
                UserData.UserName +
                ", " +
                Messages.Message2[1]}
            </div>
          </div>
        )}

        {Show && (
          <>
            {IsLoggin && (
              <>
                {Roles.length == 1 && Roles.includes("Student") && IsLoggin && (
                  <Header />
                )}
                {Roles.length == 1 && Roles.includes("Student") && IsLoggin && (
                  <HeaderUserImg />
                )}
                {Roles.includes("Admin") && IsLoggin && <Header3 />}
                {Roles.includes("Leader") && IsLoggin && <Header3 />}
                {/* ${_Theme} */}
                <div
                  className={`AppBody ${
                    Roles.length == 1 && Roles.includes("Student")
                      ? "User"
                      : "Admin"
                  } ${Language == "Ar" ? "Lalezar" : "Rubik"}`}
                  dir={`${Language == "Ar" ? "rtl" : "ltr"}`}
                >
                  <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/Profile" element={<ProfilePage />} />
                    {Roles.includes("Leader") && (
                      <>
                        <Route path="/Your-Group" element={<YourGroupPage />} />
                        <Route
                          path="/Your-Group/User/:Id"
                          element={<ShowUserData />}
                        />
                      </>
                    )}
                    {Roles.includes("Student") && (
                      <>
                        <Route path="/Forms" element={<FormsStudentPage />} />
                        <Route
                          path="/Profile/FollowUp"
                          element={<FollowUpPage />}
                        />
                        <Route path="/Profile/Quiz" element={<QuizPage />} />
                        <Route
                          path="/Profile/HomeWork"
                          element={<HomeWorkPage />}
                        />
                      </>
                    )}
                    {!Roles.includes("Admin") && (
                      <>
                        <Route
                          path="/Notifications"
                          element={<NotificationsForAllSchool />}
                        />
                        <Route path="/PlayLists" element={<PlayListsPage />} />
                        <Route
                          path="/PlayLists/:PlayListId"
                          element={<LibraryPage />}
                        />
                      </>
                    )}
                    <Route
                      path="/DashBord/User/:Id"
                      element={<ShowUserData />}
                    />
                    {Roles.includes("Admin") && (
                      <Route path="/DashBord" element={<DashBordPage />}>
                        <Route
                          path=""
                          element={<Redirect To="/DashBord/home" />}
                        />
                        <Route path="home" element={<GetSchoolsPage />} />
                        <Route
                          path="Create-School"
                          element={<CreateSchoolPage />}
                        />
                        <Route
                          path="School/:SchoolId/home"
                          element={<ActionForSchool />}
                        />
                        <Route
                          path="School/:SchoolId/Groups"
                          element={<Groups />}
                        />
                        <Route
                          path="School/:SchoolId/Students"
                          element={<Students />}
                        />
                        <Route
                          path="School/:SchoolId/Leaders"
                          element={<Leaders />}
                        />
                        <Route
                          path="School/:SchoolId/Notifications"
                          element={<Notifications />}
                        />
                        <Route
                          path="School/:SchoolId/Levels"
                          element={<LevelsPage />}
                        />
                        <Route
                          path="School/:SchoolId/PlayLists"
                          element={<PlayListsPage />}
                        />
                        <Route
                          path="School/:SchoolId/PlayLists/:PlayListId/libraries"
                          element={<LibraryPage />}
                        />
                        <Route
                          path="School/:SchoolId/Forms"
                          element={<FormsPage />}
                        />
                        <Route
                          path="School/:SchoolId/Forms/:FormId"
                          element={<FormPage />}
                        />
                        <Route
                          path="School/:SchoolId/QrCode"
                          element={<QrCodePage />}
                        />
                        <Route path="*" element={<NotFoundPage />} />
                      </Route>
                    )}
                    {/* {Roles.includes("Leader") && (
                      <Route path="/DashBord" element={<DashBordPage />}>
                        <Route
                          path=""
                          element={<Redirect To="/DashBord/home" />}
                        />
                        <Route path="home" element={<GetSchoolsPage />} />
                        <Route
                          path="Create-School"
                          element={<CreateSchoolPage />}
                        />
                        <Route
                          path="School/:SchoolId/home"
                          element={<ActionForSchool />}
                        />
                        <Route
                          path="School/:SchoolId/Groups"
                          element={<Groups />}
                        />
                        <Route
                          path="School/:SchoolId/Students"
                          element={<Students />}
                        />
                        <Route
                          path="School/:SchoolId/Leaders"
                          element={<Leaders />}
                        />
                        <Route
                          path="School/:SchoolId/Notifications"
                          element={<Notifications />}
                        />
                        <Route
                          path="School/:SchoolId/Levels"
                          element={<LevelsPage />}
                        />
                        <Route
                          path="School/:SchoolId/Library"
                          element={<LibraryPage />}
                        />
                        <Route
                          path="School/:SchoolId/Forms"
                          element={<FormsPage />}
                        />

                        <Route path="*" element={<NotFoundPage />} />
                      </Route>
                    )} */}
                    <Route path="*" element={<NotFoundPage />} />
                  </Routes>
                </div>
              </>
            )}
            {!Roles.includes("Admin") && IsLoggin && <NotificationsForUser />}
            {!IsLoggin && (
              <Routes>
                {/* <Route path="/" element={<MobileSignIn />} /> */}
                {/* <Route path="*" element={<MobileSignIn />} /> */}
                <Route path="*" element={<SignInPage />} />
                <Route path="/" element={<SignInPage />} />
                <Route path="/AdminLogin" element={<LoginAsAdmin />} />
              </Routes>
            )}
          </>
        )}
      </HashRouter>
    </section>
  );
};

export default Router;
// SihamBediJabaar!@#$%12345)9
