import React from "react";

const Img = ({
  URL,
  Default,
  Width,
  Height,
}: {
  URL: string;
  Default: string;
  Width: number;
  Height: number;
}) => {
  return (
    <div
      className="Img"
      style={{
        width: Width,
        height: Height,
      }}
    >
      {URL.length > 0 ? (
        <img src={URL} alt="IMG" />
      ) : (
        <img src={Default} alt="IMG" />
      )}
    </div>
  );
};

export default Img;
