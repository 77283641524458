import axios from "axios";
import { BackendURL } from "../URLs";
export const SignInAdminFun = ({ Token }: { Token: string }) => {
  return axios
    .post(`${BackendURL}SignIn/Admin`, { Token, date: `${new Date()}` })
    .then((result) => {
      return result.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const SignInFunMobile = ({ Token }: { Token: string }) => {
  return axios
    .post(`${BackendURL}SignIn/Mobile/User`, { Token, date: `${new Date()}` })
    .then((result) => {
      return result.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
export const AdminCheckFun = ({ Token }: { Token: string }) => {
  return axios
    .post(`${BackendURL}Check`, { Token, date: `${new Date()}` })
    .then((result) => {
      return result.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const SignInFun = ({ Token }: { Token: string }) => {
  return axios
    .post(`${BackendURL}SignIn/User`, { Token, date: `${new Date()}` })
    .then((result) => {
      return result.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
