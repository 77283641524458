import { useEffect, useState } from "react";
import { CreateFormFun } from "../../../../../../services/api/Form/Form";
import { Encrypt_Back } from "../../../../../../services/jwt with backend/JWT.With.Backend";
import { useData } from "../../../../../../data/management/Data.Management";
import { toast } from "react-toastify";
import ModalComp from "../../../../../../components/Design/Modal";
import { Plus } from "lucide-react";
import { _Date } from "../../../../../../services/Format Date/Format";
import CreatableSelect from "react-select/creatable";

const CreateForm = ({
  OnCreated,
  UserId,
  SchoolId,
}: {
  OnCreated: Function;
  UserId: string;
  SchoolId: string;
}) => {
  const {
    Data: {
      Roles,

      LanguageData: {
        DashBord: {
          Form: { Create },
        },
      },
    },
  }: any = useData();
  const [disabledCloseBtn, SetdisabledCloseBtn] = useState(false);
  const [disabledConfirmBtn, SetdisabledConfirmBtn] = useState(true);
  const [Loading, SetLoading] = useState(false);
  const [Show, SetShow] = useState(false);
  const [FormTitle, SetFormTitle] = useState("");
  const [FormDescription, SetFormDescription] = useState("");
  const [GoogleSheetId, SetGoogleSheetId]: any = useState(null);
  const [FormUrl, SetFormUrl]: any = useState(null);
  const [Status, SetStatus]: any = useState(null);
  const [Type, SetType]: any = useState(null);
  const [Score, SetScore]: any = useState(0);
  const [SelectOptions, SetSelectOptions]: any = useState([
    { value: "Active", label: "Active" },
    { value: "InActive", label: "InActive" },
    { value: "Finish", label: "Finish" },
  ]);
  const [TypeOptions, SetTypeOptions]: any = useState([
    { value: "Quiz", label: "Quiz" },
    { value: "HomeWork", label: "HomeWork" },
    { value: "FollowUp", label: "FollowUp" },
  ]);

  useEffect(() => {
    if (
      FormTitle != null &&
      FormUrl != null &&
      Status != null &&
      Type != null &&
      GoogleSheetId != null
    ) {
      if (
        FormTitle.replace(/ /g, "").length > 0 &&
        FormUrl.replace(/ /g, "").length > 0 &&
        GoogleSheetId.length > 0 &&
        Status.value.replace(/ /g, "").length > 0 &&
        Type.value.replace(/ /g, "").length > 0 &&
        isValidUrl(FormUrl)
      ) {
        if (Type.value == "Quiz") {
          if (Type.value == "Quiz" && Score != null && Score != 0) {
            SetdisabledConfirmBtn(false);
          } else {
            SetdisabledConfirmBtn(true);
          }
        } else {
          SetdisabledConfirmBtn(false);
        }
      } else {
        SetdisabledConfirmBtn(true);
      }
    } else {
      SetdisabledConfirmBtn(true);
    }
  }, [FormTitle, FormUrl, GoogleSheetId, Status, Type, Score]);
  const CreateGroup = () => {
    SetLoading(true);
    CreateFormFun({
      Token: Encrypt_Back({
        Data: {
          FormTitle,
          UserId,
          FormDescription,
          CreateAt: _Date(),
          SchoolId,
          GoogleSheetId,
          FormUrl,
          Status: Status.value,
          Type: Type.value,
          Score:
            Score != 0 && Score != null ? Score : Score != 0 ? Score : null,
        },
        expiresIn: {
          minutes: 5,
        },
      }),
    }).then((result) => {
      SetShow(!result.Data.Created);
      toast.success(result.Data.Message, {
        closeOnClick: true,
        theme: "dark",
      });
      OnCreated(true);
      SetFormTitle("");
      SetFormDescription("");
      SetLoading(false);
    });
  };
  return (
    <div className="w-100 d-flex justify-content-center align-items-center mb-2">
      <ModalComp
        ButtonTitle={
          <>
            <span className="mx-2">{Create.CreateBtn}</span>
            <Plus />
          </>
        }
        ModalTitle={Create.CreateBtn}
        ConfirmBtn={
          <>
            <span className="me-2">{Create.CreateBtn}</span>
            <Plus />
          </>
        }
        variantBtn="info"
        variantModal="light"
        disabledCloseBtn={disabledCloseBtn}
        disabledConfirmBtn={disabledConfirmBtn}
        onClickConFirmBtn={CreateGroup}
        Show={Show}
        handleClose={() => {
          SetShow(false);
          SetFormTitle("");
          SetFormDescription("");
          SetGoogleSheetId("");
          SetFormUrl("");
          SetStatus(null);
          SetScore(null);
          SetType(null);
        }}
        handleShow={() => {
          SetShow(true);
          SetFormTitle("");
          SetFormDescription("");
          SetGoogleSheetId("");
          SetFormUrl("");
          SetStatus(null);
          SetScore(null);
          SetType(null);
        }}
        Loading={Loading}
      >
        <input
          type="text"
          disabled={Loading}
          value={FormUrl}
          onChange={(e: any) => {
            SetFormUrl(e.target.value);
          }}
          placeholder={Create.FormURL}
          className="form-control mb-3"
        />
        <CreatableSelect
          className="text-dark mb-3"
          isClearable
          options={SelectOptions}
          onChange={(e: any) => {
            if (e != null) {
              SetStatus(e);
            } else {
              SetStatus(null);
            }
          }}
          value={Status}
          placeholder={Create.SelectTitle}
        />
        <CreatableSelect
          className="text-dark mb-3"
          isClearable
          options={TypeOptions}
          onChange={(e: any) => {
            if (e != null) {
              SetType(e);
            } else {
              SetType(null);
            }
          }}
          value={Type}
          placeholder={Create.SelectTitleType}
        />

        <input
          type="text"
          disabled={Loading}
          value={FormTitle}
          onChange={(e: any) => {
            SetFormTitle(e.target.value);
          }}
          placeholder={Create.Title}
          className="form-control mb-3"
        />
        {Type != null && Type.value == "Quiz" && (
          <input
            type="number"
            disabled={Loading}
            value={Score}
            onChange={(e: any) => {
              SetScore(e.target.value);
            }}
            placeholder={Create.Score}
            className="form-control mb-3"
          />
        )}
        <input
          type="text"
          disabled={Loading}
          value={GoogleSheetId}
          onChange={(e: any) => {
            try {
              SetGoogleSheetId(getSheetId(e.target.value));
            } catch {
              SetGoogleSheetId(null);
            }
          }}
          placeholder={Create.SheetURL}
          className="form-control mb-3"
        />
        {GoogleSheetId != null && GoogleSheetId != "" && (
          <p dir="ltr">SheetId: {GoogleSheetId}</p>
        )}
        <textarea
          name="Description"
          id="Description"
          className="form-control"
          disabled={Loading}
          onChange={(e) => {
            SetFormDescription(e.target.value);
          }}
          style={{ maxHeight: "300px" }}
          value={FormDescription}
          placeholder={Create.Description}
        ></textarea>
      </ModalComp>
    </div>
  );
};

export function getSheetId(sheetUrl: string) {
  // Use a regular expression to match the sheet ID
  const regex = /\/d\/([a-zA-Z0-9-_]+)\//;
  const match = sheetUrl.match(regex);

  // If a match is found, return the sheet ID
  if (match) {
    return match[1];
  } else {
    throw new Error("Invalid Google Sheets URL");
  }
}
function isValidUrl(url: string): boolean {
  const regex = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i" // fragment locator
  );
  return !!regex.test(url);
}
export default CreateForm;
