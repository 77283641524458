import React, { useEffect, useState } from "react";
import ModalComp from "../../../../../../components/Design/Modal";
import { Pencil } from "lucide-react";
import {
  Decrypt_Back,
  Encrypt_Back,
} from "../../../../../../services/jwt with backend/JWT.With.Backend";
import { toast } from "react-toastify";
import { useData } from "../../../../../../data/management/Data.Management";
import { EditLibraryFun } from "../../../../../../services/api/Library/Library";
import {
  GetAllLevelsOfSchool,
  GetAllPlayListsOfSchool,
} from "../../../../../../services/api/Level/Level";
import CreatableSelect from "react-select/creatable";

const EditLibrary = ({
  LibraryData,
  UserId,
  SchoolId,
  OnEdit,
}: {
  LibraryData: any;
  UserId: string;
  SchoolId: string;
  OnEdit: Function;
}) => {
  const {
    Data: {
      LanguageData: {
        DashBord: {
          Library: { Edit, Create },
        },
      },
    },
  }: any = useData();
  const [disabledCloseBtn, SetdisabledCloseBtn] = useState(false);
  const [disabledConfirmBtn, SetdisabledConfirmBtn] = useState(false);
  const [Loading, SetLoading] = useState(false);
  const [Show, SetShow] = useState(false);
  const [NewDescription, SetNewDescription] = useState(LibraryData.Description);
  console.log(LibraryData.PlayList);
  const [PlayListId, SetPlayListId]: any = useState({
    label: LibraryData.PlayList.Title,
    value: LibraryData.PlayList.PlayListId,
  });

  const [PlayListIds, SetPlayListIds]: any = useState(null);
  useEffect(() => {
    GetAllPlayListsOfSchool({
      UserId,
      SchoolId,
    }).then((result) => {
      if (result.PlayLists.length > 0) {
        let _PlayLists = result.PlayLists;
        let Selection_PlayLists = [];
        for (let i = 0; i < _PlayLists.length; i++) {
          const element = _PlayLists[i];
          Selection_PlayLists.push({
            label: element.Title,
            value: element.PlayListId,
          });
        }
        SetPlayListIds(Selection_PlayLists);
      }
      console.log(result);
    });
  }, []);

  const EditLibrary = () => {
    SetLoading(true);
    EditLibraryFun({
      Token: Encrypt_Back({
        Data: {
          UserId,
          SchoolId,
          LibraryId: LibraryData.LibraryId,
          Description: NewDescription,
          PlayListId: PlayListId.value,
        },
        expiresIn: { minutes: 5 },
      }),
    }).then((result) => {
      SetLoading(false);
      if (result.Data.UpDated) {
        SetShow(!result.Data.UpDated);
        OnEdit(Decrypt_Back({ Data: result.Data.LibraryData }).Data.Library);
        toast.success(result.Data.Message, {
          closeOnClick: true,
          theme: "dark",
        });
      } else {
        toast.error(result.Data.Message, {
          closeOnClick: true,
          theme: "dark",
        });
      }
    });
  };
  useEffect(() => {
    if (
      NewDescription != LibraryData.Description ||
      (PlayListId != null &&
        PlayListId.value != LibraryData.PlayList.PlayListId)
    ) {
      SetdisabledConfirmBtn(false);
    } else {
      SetdisabledConfirmBtn(true);
    }
  }, [NewDescription, PlayListId]);
  let [Height, SetHeight] = useState({ Height: 0, ClassName: "" });
  useEffect(() => {
    switch (LibraryData.Type) {
      case "SoundCloud":
        SetHeight({
          ClassName: "SoundCloud",
          Height: 160,
        });
        break;
      case "PDF":
        SetHeight({
          ClassName: "PDF",
          Height: 500,
        });
        break;
      default:
        SetHeight({
          ClassName: "Media",
          Height: 270,
        });
    }
  }, [LibraryData.Type]);
  return (
    <ModalComp
      ButtonTitle={
        <>
          <Pencil />
        </>
      }
      ButtonTitlevariant="primary"
      ModalTitle={Edit.EditLibrary}
      ConfirmBtn={
        <>
          <span className="me-2">{Edit.EditBtn}</span>
          <Pencil />
        </>
      }
      variantBtn="primary"
      variantModal="light"
      disabledCloseBtn={disabledCloseBtn}
      disabledConfirmBtn={disabledConfirmBtn}
      onClickConFirmBtn={EditLibrary}
      Show={Show}
      handleClose={() => {
        SetShow(false);
      }}
      handleShow={() => {
        SetShow(true);
      }}
      Loading={Loading}
    >
      <div>
        <CreatableSelect
          className="text-dark mb-2"
          isClearable
          options={PlayListIds}
          onChange={(e: any) => {
            if (e != null) {
              SetPlayListId(e);
            } else {
              SetPlayListId(null);
            }
          }}
          value={PlayListId}
          placeholder={Create.Message2}
        />
        {LibraryData.Type == "PDF" ? (
          <div className="Image w-100">
            <img src={LibraryData.PDFURL} className=" w-100" alt="" />
            <a
              href={LibraryData.Link}
              target="_blanck"
              className="btn btn-primary mx-auto mt-3 d-block"
              style={{ width: "fit-content" }}
            >
              PDF Link
            </a>
          </div>
        ) : (
          <iframe
            className={`w-100 ${Height.ClassName}`}
            src={LibraryData.Link}
            allow="fullscreen"
            height={Height.Height}
          ></iframe>
        )}

        <div className="body">
          <textarea
            name="Description"
            id="Description"
            dir={/[\u0600-\u06FF]/.test(NewDescription) ? "rtl" : "ltr"}
            style={{
              height: "150px",
              maxHeight: "300px",
              minHeight: "100px",
            }}
            placeholder={Create.Description}
            className="form-control mt-2"
            value={NewDescription}
            onChange={(e) => {
              SetNewDescription(e.target.value);
            }}
          ></textarea>
        </div>
      </div>
    </ModalComp>
  );
};

export default EditLibrary;
