import axios from "axios";
import { BackendURL } from "../URLs";
import {
  Decrypt_Back,
  Decrypt_BackQraphQl,
} from "../../jwt with backend/JWT.With.Backend";
import { GraphQlURL } from "../../../data/main/URLS";
export const CreateLevelFun = ({ Token }: { Token: string }) => {
  return axios
    .post(`${BackendURL}Level/Create`, { Token, date: `${new Date()}` })
    .then((result) => {
      return result.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
export const DeleteLevelFun = ({ Token }: { Token: string }) => {
  return axios
    .post(`${BackendURL}Level/Delete`, { Token, date: `${new Date()}` })
    .then((result) => {
      return result.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
export const EditLevelFun = ({ Token }: { Token: string }) => {
  return axios
    .post(`${BackendURL}Level/Edit`, { Token, date: `${new Date()}` })
    .then((result) => {
      return result.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const GetAllLevelsOfSchool = ({
  UserId,
  SchoolId,
}: {
  UserId: string;
  SchoolId: string;
}) => {
  return axios
    .post(`${GraphQlURL}`, {
      query: `
        query Query($UserId:String,$SchoolId:String) {
          Levels(UserId:$UserId,SchoolId:$SchoolId) {
            LevelId       
            LevelTitle
            CreateAt    
            SchoolId 
            Status
          }
        }
      `,
      variables: {
        UserId,
        SchoolId,
      },
    })
    .then((result) => {
      return Decrypt_BackQraphQl({ Data: result.data.data.data }).Data;
    });
};
export const GetAllPlayListsOfSchool = ({
  UserId,
  SchoolId,
}: {
  UserId: string;
  SchoolId: string;
}) => {
  return axios
    .post(`${GraphQlURL}`, {
      query: `
        query Query($UserId:String,$SchoolId:String) {
          PlayLists(UserId:$UserId,SchoolId:$SchoolId) {
            PlayListId
            Title
          }
        }
      `,
      variables: {
        UserId,
        SchoolId,
      },
    })
    .then((result) => {
      return Decrypt_BackQraphQl({ Data: result.data.data.data }).Data;
    });
};
