import CryptoJS from "crypto-js";
import { KeyJWT } from "../Variables";

export const Encrypt_Back = ({
  Data,
  expiresIn: { hours, milliseconds, minutes, seconds },
}: {
  Data: any;
  expiresIn: {
    hours?: number;
    milliseconds?: number;
    minutes?: number;
    seconds?: number;
  };
}) => {
  let date = addPeriodToTime(new Date(), {
    hours,
    milliseconds,
    minutes,
    seconds,
  });
  let AfterConvert = JSON.stringify({
    Data,
    expiresIn: date,
  });
  return CryptoJS.AES.encrypt(AfterConvert, KeyJWT).toString();
};
export const Decrypt_Back = ({ Data }: { Data: string }) => {
  try {
    let _Data: any = CryptoJS.AES.decrypt(Data, KeyJWT);
    _Data = JSON.parse(_Data.toString(CryptoJS.enc.Utf8));

    let date = new Date(_Data.expiresIn);
    // return _Data;
    if (date.getTime() > new Date().getTime()) {
      return _Data;
    }
  } catch {
    return false;
  }
};
export const Decrypt_BackQraphQl = ({ Data }: { Data: string }) => {
  try {
    let _Data: any = CryptoJS.AES.decrypt(Data, KeyJWT);
    _Data = JSON.parse(_Data.toString(CryptoJS.enc.Utf8));

    let date = new Date(_Data.expiresIn);

    return _Data;
  } catch {
    return false;
  }
};

export const Encrypt = ({ Data }: { Data: any }) => {
  let AfterConvert = JSON.stringify({
    Data: Data,
  });
  return CryptoJS.AES.encrypt(AfterConvert, KeyJWT).toString();
};
export const Decrypt = ({ Data }: { Data: string }) => {
  let _Data: any = CryptoJS.AES.decrypt(Data, KeyJWT);
  _Data = JSON.parse(_Data.toString(CryptoJS.enc.Utf8));
  return _Data;
};

// Function to add a period to a specific time
function addPeriodToTime(
  date: Date,
  period: {
    hours?: number;
    minutes?: number;
    seconds?: number;
    milliseconds?: number;
  }
): Date {
  // Clone the date to avoid mutating the original
  const newDate = new Date(date.getTime());

  // Add hours if provided
  if (period.hours) {
    newDate.setHours(newDate.getHours() + period.hours);
  }

  // Add minutes if provided
  if (period.minutes) {
    newDate.setMinutes(newDate.getMinutes() + period.minutes);
  }

  // Add seconds if provided
  if (period.seconds) {
    newDate.setSeconds(newDate.getSeconds() + period.seconds);
  }

  // Add milliseconds if provided
  if (period.milliseconds) {
    newDate.setMilliseconds(newDate.getMilliseconds() + period.milliseconds);
  }

  return newDate;
}
