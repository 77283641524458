import React, { useEffect, useState } from "react";
import { useData } from "../../../../../data/management/Data.Management";
import { useParams } from "react-router-dom";
import { GetAllLibrariesForSchool } from "../../../../../services/api/Library/Library";
import { GetPlayLists } from "../../../../../services/api/PlayList/PlayList";
import { GetSchoolAllInfoFun } from "../../../../../services/api/school/School";
import ViewPlayList from "./components/View.Play.List";
import CreatePlayList from "./components/Create.PlayList";
import "../../../../../styles/PlayLists/PlayLists.style.css";

const PlayListsPage = () => {
  const {
    Data: {
      Roles,
      UserData,
      UserId,
      LanguageData: {
        DashBord: {
          PlayList: {
            Messages: { NoSPlayListsCreated },
          },
        },
        Errors: { SchoolNotFound },
      },
    },
  }: any = useData();
  let { SchoolId } = useParams();
  const [PlayLists, SetPlayLists]: any = useState(null);
  let _GetPlayLists = () => {
    SetLoading(true);
    if (UserData.SchoolId == null || UserData.SchoolId == undefined) {
      GetPlayLists({
        SchoolId: `${SchoolId?.replaceAll("_-_", "#")}`,
        UserId: UserData.UserId,
      }).then((result) => {
        if (result != null) {
          SetPlayLists(result.PlayLists);
          SetLoading(false);
        } else {
          SetPlayLists([]);
          SetLoading(false);
        }
      });
    } else {
      GetPlayLists({
        SchoolId: `${UserData.SchoolId}`,
        UserId: UserData.UserId,
      }).then((result) => {
        if (result != null) {
          SetPlayLists(result.PlayLists);
          SetLoading(false);
        } else {
          SetPlayLists([]);
          SetLoading(false);
        }
      });
    }
  };
  const [Loading, SetLoading] = useState(true);
  const [Show, SetShow] = useState(false);
  useEffect(() => {
    GetSchoolAllInfoFun({
      SchoolId: `${SchoolId?.replaceAll("_-_", "#")}`,
      UserId: UserId,
    }).then((result) => {
      SetLoading(false);
      if (result != null) {
        SetShow(true);
        _GetPlayLists();
      } else {
        if (!Roles.includes("Admin")) {
          SetShow(true);
          _GetPlayLists();
        }
      }
    });
  }, []);
  return (
    <div
      id="PlayLists"
      className={`${Roles.includes("Admin") ? "Admin" : ""} ${
        Roles.includes("Leader") && !Roles.includes("Admin") ? "Leader" : ""
      }`}
    >
      {Loading ? (
        <div className="d-flex w-100 align-items-center justify-content-center mt-2">
          <div
            className="spinner-border text-primary"
            style={{ width: "50px", height: "50px" }}
            role="status"
          ></div>
        </div>
      ) : (
        <>
          {Show ? (
            <>
              {Roles.includes("Admin") && (
                <CreatePlayList
                  SchoolId={`${SchoolId?.replaceAll("_-_", "#")}`}
                  OnCreated={_GetPlayLists}
                  UserId={UserData.UserId}
                />
              )}

              <div className="PlayLists">
                {PlayLists != null &&
                  PlayLists.map((PlayList: any, i: number) => {
                    return (
                      <ViewPlayList
                        OnChanges={_GetPlayLists}
                        PlayListData={PlayList}
                        key={i}
                      />
                    );
                  })}
                {PlayLists.length == 0 && (
                  <div
                    className="alert alert-primary mx-auto mt-3"
                    style={{ width: "fin-content" }}
                  >
                    {NoSPlayListsCreated}
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className="d-flex align-items-center w-100 justify-content-center">
              <div className="alert alert-info text-center">
                {SchoolNotFound}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PlayListsPage;
