import React from "react";
import { BadgeInfo, Edit, Info, Trash } from "lucide-react";
// import DeleteBtnLevel from "./Delete.Btn.Level";
// import EditBtnLevel from "./Edit.Btn.Level";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useData } from "../../../../../../data/management/Data.Management";
import { useNavigate } from "react-router-dom";
import DeleteBtnLevel from "./Delete.Btn.Level";
import EditBtnLevel from "./Edit.Btn.Group";

const LevelView = ({
  Title,
  LevelData,
  UserId,
  OnDeleted,
  OnEdit,
  Index,
}: {
  Title: string;
  LevelData: any;
  UserId: string;
  OnDeleted: Function;
  OnEdit: Function;
  Index: number;
}) => {
  let Navigate = useNavigate();
  const {
    Data: {
      LanguageData: {
        DashBord: {
          Level: {},
        },
      },
    },
  }: any = useData();

  return (
    <div className="Level">
      <h3 className="mb-0">
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="button-tooltip">{Title}</Tooltip>}
        >
          <span>{Title.length > 15 ? Title.slice(0, 15) + "..." : Title}</span>
        </OverlayTrigger>
      </h3>
      <div>
        <EditBtnLevel OnEdit={OnEdit} LevelData={LevelData} UserId={UserId} />
        <DeleteBtnLevel
          OnDeleted={OnDeleted}
          LevelData={LevelData}
          UserId={UserId}
        />
      </div>
    </div>
  );
};

export default LevelView;
