import React, { useEffect, useState } from "react";
import LeaderView from "./Leader.View";
import { useData } from "../../../../../../data/management/Data.Management";
import { GetLeadersOfSchoolFun } from "../../../../../../services/api/school/School";
import { motion } from "framer-motion";

const GetAllLeaders = ({
  SchoolId,
  GetLeaders,
}: {
  SchoolId: string;
  GetLeaders: string;
}) => {
  const {
    Data: { UserId },
  }: any = useData();
  const [Groups, SetGroups]: any = useState(null);
  const GetLeadersMedthod = () => {
    GetLeadersOfSchoolFun({
      SchoolId,
      UserId,
    }).then((result) => {
      SetGroups(result.School.Groups);
    });
  };
  useEffect(() => {
    GetLeadersMedthod();
  }, [GetLeaders]);
  return (
    <div className="Leaders">
      <section className="w-100 d-flex align-items-center justify-content-center">
        {Groups == null && (
          <div
            className="spinner-border text-primary"
            style={{ width: "50px", height: "50px" }}
            role="status"
          ></div>
        )}
      </section>
      {Groups != null &&
        Groups.map((Group: any, i: number) => {
          return (
            Group.Leaders.length != 0 && (
              <motion.div
                initial={{ opacity: 0, x: 200 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{
                  ease: "easeInOut",
                  duration: 0.5,
                  delay: (i + 1) * 4 * 0.05,
                }}
                className="mb-2"
                key={i}
              >
                <div className="mb-2">
                  <h2>{Group.GroupName}</h2>
                  {Group.Leaders.map((Leader: any, i: number) => {
                    return (
                      <LeaderView
                        SchoolId={SchoolId}
                        GroupsData={Groups}
                        LeaderData={Leader}
                        Title={Leader.UserName}
                        key={i}
                        OnDeleted={() => {
                          GetLeadersMedthod();
                        }}
                        UserId={UserId}
                      />
                    );
                  })}
                </div>
              </motion.div>
            )
          );
        })}
    </div>
  );
};

export default GetAllLeaders;
