import React, { useEffect, useState } from "react";
import { GetUserDataFun } from "../../../services/api/User/User";
import {
  Decrypt_Back,
  Encrypt_Back,
} from "../../../services/jwt with backend/JWT.With.Backend";
import { useParams } from "react-router-dom";
import { useData } from "../../../data/management/Data.Management";
import { Check, Copy, X } from "lucide-react";
import { calculateTimePeriod } from "../../../services/Format Date/Format";
import FormViewDataOfUser from "./Form.View.Data.Of.User";
import UserScore from "./Score.User.To.Admin";
import { toast } from "react-toastify";

const ShowUserData = () => {
  const {
    Data: {
      UserId,
      Roles,
      Language,
      LanguageData: {
        DashBord: {
          Form: { QuizForm, FollowUpForm, HomeWorkForm },
        },
      },
    },
  }: any = useData();
  let { Id } = useParams();
  let [UserData, SetUserData]: any = useState(null);
  const [Loading, SetLoading] = useState(true);
  let [QuizForms, SetQuizForms] = useState([]);
  let [HomeWorkForms, SetHomeWorkForms] = useState([]);
  let [FollowUpForms, SetFollowUpForms] = useState([]);
  useEffect(() => {
    GetUserDataFun({
      Token: Encrypt_Back({
        Data: {
          UserId,
          Id: Id?.replace("@", ""),
        },
        expiresIn: { minutes: 5 },
      }),
    }).then((result) => {
      try {
        if (result.Data.UserData != undefined) {
          if (Decrypt_Back({ Data: result.Data.UserData })) {
            let Data = Decrypt_Back({ Data: result.Data.UserData }).Data;

            let Forms1 = Data.Forms;
            console.log(Forms1);
            let Forms2 = Data.Forms;
            let Forms3 = Data.Forms;
            SetQuizForms(Forms1.filter((Form: any) => Form.Type == "Quiz"));
            SetFollowUpForms(
              Forms2.filter((Form: any) => Form.Type == "FollowUp")
            );
            SetHomeWorkForms(
              Forms3.filter((Form: any) => Form.Type == "HomeWork")
            );

            SetUserData(Data.UserData);
            setTimeout(() => {
              SetLoading(false);
            }, 1000);
          }
        }
      } catch (error) {}
    });
  }, []);
  return (
    <div
      id="YourGroupPage"
      className={`d-flex align-items-center justify-content-center flex-column ${
        Roles.length == 1 && Roles.includes("Student") ? "" : "Leader"
      } ${Language == "Ar" ? "Left" : "Right"}`}
    >
      <div className="w-100">
        {Loading ? (
          <div className="w-100 d-flex align-items-center justify-content-center">
            <div
              className="spinner-border text-primary"
              style={{ width: "50px", height: "50px" }}
              role="status"
            ></div>
          </div>
        ) : (
          <div className="w-100 d-flex flex-column">
            {UserData != null && (
              <div className="Info">
                <p className="mb-1">{UserData.UserName}</p>
                <div
                  className="alert alert-primary d-flex align-items-center justify-content-center p-2 mt-3"
                  style={{ width: "fit-content  " }}
                >
                  <p className="mb-1">{Id?.replace("@", "")}</p>
                  <button
                    className="bg-transparent border-0 text-white mx-2"
                    onClick={() => {
                      navigator.clipboard.writeText(`${Id?.replace("@", "")}`);
                      toast(`Copied ${Id?.replace("@", "")}`, {
                        closeOnClick: true,
                      });
                    }}
                  >
                    <Copy />
                  </button>
                </div>
              </div>
            )}
            <hr />
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <UserScore Id={`${Id?.replace("@", "")}`} />
                  <hr />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="ParentOfForms d-flex  align-items-start justify-content-center flex-wrap">
                    <div className="Form m-2">
                      <div className="Title">{QuizForm}</div>
                      <div className="Forms">
                        {QuizForms.map((Form: any, i: number) => {
                          return <FormViewDataOfUser Data={Form} key={i} />;
                        })}
                      </div>
                    </div>
                    <div className="Form m-2">
                      <div className="Title">{FollowUpForm}</div>
                      <div className="Forms">
                        {FollowUpForms.map((Form: any, i: number) => {
                          return <FormViewDataOfUser Data={Form} key={i} />;
                        })}
                      </div>
                    </div>
                    <div className="Form m-2">
                      <div className="Title">{HomeWorkForm}</div>
                      <div className="Forms">
                        {HomeWorkForms.map((Form: any, i: number) => {
                          return <FormViewDataOfUser Data={Form} key={i} />;
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-xl-4 col-lg-6 col-md-12 col-ms-12"></div>
                <div className="col-xl-4 col-lg-6 col-md-12 col-ms-12">
                  <div className="Title">{FollowUpForm}</div>
                  <div className="Forms">
                    {FollowUpForms.map((Form: any, i: number) => {
                      return <FormViewDataOfUser Data={Form} key={i} />;
                    })}
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-12 col-ms-12">
                  <div className="Title">{HomeWorkForm}</div>
                  <div className="Forms">
                    {HomeWorkForms.map((Form: any, i: number) => {
                      return <FormViewDataOfUser Data={Form} key={i} />;
                    })}
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ShowUserData;
