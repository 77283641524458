import axios from "axios";
import { BackendURL } from "../URLs";
export const ChangeThemeFun = ({ Token }: { Token: string }) => {
  return axios
    .post(`${BackendURL}ChangeUserData/Theme`, { Token, date: `${new Date()}` })
    .then((result) => {
      return result.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
export const ChangeLanguageun = ({ Token }: { Token: string }) => {
  return axios
    .post(`${BackendURL}ChangeUserData/Language`, {
      Token,
      date: `${new Date()}`,
    })
    .then((result) => {
      return result.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
export const GetAllUsers = ({ Token }: { Token: string }) => {
  return axios
    .post(`${BackendURL}Users/Get-All`, {
      Token,
      date: `${new Date()}`,
    })
    .then((result) => {
      return result.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
export const ChangeImageFun = ({ Token }: { Token: string }) => {
  return axios
    .post(`${BackendURL}ChangeUserData/Image`, { Token, date: `${new Date()}` })
    .then((result) => {
      return result.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
export const ChangeUserNameFun = ({ Token }: { Token: string }) => {
  return axios
    .post(`${BackendURL}ChangeUserData/UserName`, {
      Token,
      date: `${new Date()}`,
    })
    .then((result) => {
      return result.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
export const GetUserDataFun = ({ Token }: { Token: string }) => {
  return axios
    .post(`${BackendURL}Auth/GetUserData`, {
      Token,
      date: `${new Date()}`,
    })
    .then((result) => {
      return result.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
