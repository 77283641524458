import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useData } from "../../../../../data/management/Data.Management";
import { GetSchoolAllInfoByUserIdFun } from "../../../../../services/api/school/School";
import DivQrCode from "./components/DivQrCode";
import "../../../../../styles/DashBord/QrCode.Page.style.css";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const QrCodePage = () => {
  const {
    SetLanguage,
    Data: { Roles, UserId },
  }: any = useData();
  let { SchoolId } = useParams();
  let [AllUsers, SetAllUsers]: any[] = useState([]);
  let [SchoolName, SetSchoolName] = useState("");
  useEffect(() => {
    GetSchoolAllInfoByUserIdFun({
      UserId,
      SchoolId: `${SchoolId?.replaceAll("_-_", "#")}`,
    }).then((result) => {
      console.log(result);
      // SetSchoolName(result.School.SchoolName);
      // let Groups = result.School.Groups;

      // let Users = [];
      // for (let i = 0; i < Groups.length; i++) {
      //   const element = Groups[i];
      //   for (let l = 0; l < element.Students.length; l++) {
      //     const Student = element.Students[l];
      //     Student.GroupName = element.GroupName;

      //     Users.push(Student);
      //   }
      // }

      // SetAllUsers(Users);
    });
  }, []);
  // const downloadPdfDocument = (rootElementId: string) => {
  //   const input: any = document.getElementById(rootElementId);
  //   console.log(input);

  //   html2canvas(input).then((canvas) => {
  //     const imgData = canvas.toDataURL("image/png");
  //     console.log(imgData);

  //     const pdf: any = new jsPDF();
  //     pdf.addImage(imgData, "JPEG", 0, 0);
  //     // pdf.save("download.pdf");
  //   });
  // };
  return (
    <div
      id="QrCodePage"
      className={`${Roles.includes("Admin") ? "Admin" : ""}`}
    >
      {/* <button
        className="btn btn-primary"
        onClick={() => {
          downloadPdfDocument("QrCodes");
        }}
      >
        Print
      </button> */}
      <div id="QrCodes">
        {AllUsers.map((User: any, i: number) => {
          return <DivQrCode key={i} User={User} />;
        })}
      </div>
    </div>
  );
};

export default QrCodePage;
