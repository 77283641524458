import React from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import "../../styles/header/header.component.style.css";
import { useData } from "../../data/management/Data.Management";
import { IconsForNav } from "../../data/static/Links";
import { LogOut, Menu } from "lucide-react";
import { useEffect, useState } from "react";
import "../../styles/header/header2.css";
import ASideLinks from "../../pages/dash-bord/components/ASideLinks";
const Header3 = () => {
  const {
    Data: {
      LanguageData: {
        AppName,
        Header,
        DashBord: {
          Form: { Title },
        },
      },
      Language,
      Roles,
    },
    SetRoles,
    SetMode,
    SetLanguage,
    SetUserLogin,
    SetLogin,
  }: any = useData();
  const [links, Setlinks]: any = useState([]);
  const [ShowMenu, SetShowMenu] = useState(false);
  const [, SetTime] = useState("");
  useEffect(() => {
    if (Roles.includes("Admin")) {
      Setlinks([...Header.AdminLinks]);
      SetTime(`${new Date()}`);
    } else if (Roles.includes("Leader")) {
      Setlinks(Header.LeaderLinks);
      SetTime(`${new Date()}`);
    } else {
      Setlinks(Header.Links);
      SetTime(`${new Date()}`);
    }
    if (Roles.includes("Admin") && Roles.includes("Leader")) {
      Setlinks([...Header.AdminLinks, ...Header.IfAdminIsLeader.Links]);
    }
  }, [Language, Roles]);
  const Navigate = useNavigate();
  const LogOutFun = () => {
    SetRoles([]);
    SetMode("Light");
    SetLanguage("En");
    SetUserLogin({});
    SetLogin(false);
    Navigate("/");
  };
  const _Icon = (Name: string) => {
    return IconsForNav.filter((Icon: any) => Icon.Name == Name)[0].Icon;
  };
  useEffect(() => {
    if (window.location.hash.split("#").length > 1) {
      SetShowMenu(
        window.location.hash.split("#")[1].split("/").includes("DashBord")
      );
    }
  }, [window.location.hash]);
  return (
    <div>
      <aside
        id="sidebar"
        className={`sidebar ${Language == "Ar" ? "Right Lalezar" : "Rubik"}`}
      >
        <div
          className="Logo d-flex align-items-center justify-content-start p-1"
          dir={`${Language == "Ar" ? "rtl" : "ltr"}`}
        >
          <img
            src="/Images/Logo.png"
            alt="Logo Image"
            className={`${Language == "Ar" ? "ms-2" : "me-2"}`}
            style={{ width: "50px", height: "50px" }}
          />
          <h2 style={{ fontSize: "20px" }}>{AppName}</h2>
        </div>
        <ul className="sidebar-nav" id="sidebar-nav">
          <li className="nav-heading">{Header.Pages}</li>
          {links.map((_Link: any, i: number) => {
            return (
              <li className="nav-item" key={i}>
                <NavLink to={_Link.path} className="nav-link collapsed">
                  {_Icon(_Link.Name)}
                  <span className="mx-2">{_Link.Title}</span>
                </NavLink>
              </li>
            );
          })}
          {Roles.includes("Student") && (
            <li className="nav-item">
              <NavLink to={"/Forms"} className="nav-link collapsed">
                {_Icon("Forms")}
                <span className="mx-2">{Title}</span>
              </NavLink>
            </li>
          )}
          <li className="nav-item">
            <button className="w-100 nav-link collapsed" onClick={LogOutFun}>
              <LogOut />
              <span className="mx-2">{Header.LogOutBtn}</span>
            </button>
          </li>
          {Roles.includes("Admin") && (
            <>
              <hr />
              <ASideLinks />
            </>
          )}
        </ul>
      </aside>
    </div>
  );
};

export default Header3;
// function getDatesByInterval(
//   startDate: string,
//   endDate: string,
//   intervalDays: number
// ): Date[] {
//   // Ensure startDate and endDate are Date objects
//   const start = new Date(startDate);
//   const end = new Date(endDate);

//   const dates: Date[] = [];
//   let currentDate = start;

//   // Add dates at the specified interval until the end date is reached
//   while (currentDate <= end) {
//     dates.push(new Date(currentDate)); // Add a copy of the current date
//     currentDate.setDate(currentDate.getDate() + intervalDays); // Move to the next date by the interval
//   }

//   return dates;
// }

// // Example usage:
// const startDate = "2024-07-29";
// const endDate = "2024-09-29";
// const intervalDays = 14; // 14-day interval

// let intervalDates: any[] = getDatesByInterval(startDate, endDate, intervalDays);
// intervalDates = intervalDates.slice(1, intervalDates.length);

// intervalDates.forEach((date) => {
//   console.log(date.toDateString());
// });
