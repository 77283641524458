import { ListVideo, Plus } from "lucide-react";
import React, { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import ModalComp from "../../../../../../components/Design/Modal";
import { Encrypt_Back } from "../../../../../../services/jwt with backend/JWT.With.Backend";
import { toast } from "react-toastify";
import { useData } from "../../../../../../data/management/Data.Management";
import UploadFile from "./Upload.File";
import { CreatePlayListFun } from "../../../../../../services/api/PlayList/PlayList";
import { GetAllLevelsOfSchool } from "../../../../../../services/api/Level/Level";
import { _Date } from "../../../../../../services/Format Date/Format";
const CreatePlayList = ({
  SchoolId,
  UserId,
  OnCreated,
}: {
  SchoolId: string;
  UserId: string;
  OnCreated: Function;
}) => {
  let {
    Data: {
      LanguageData: {
        DashBord: {
          PlayList: { Create },
          PlayList,
        },
      },
    },
  }: any = useData();
  const [TypeOfPlayListSelectOption]: any = useState([
    {
      value: true,
      label: "Visible",
    },
    {
      value: false,
      label: "Hidden",
    },
  ]);
  const [disabledCloseBtn, SetdisabledCloseBtn] = useState(false);
  const [disabledConfirmBtn, SetdisabledConfirmBtn] = useState(true);
  const [DisabledSelectBox, SetDisabledSelectBox] = useState(false);
  const [Loading, SetLoading] = useState(false);
  const [Show, SetShow] = useState(false);
  const [ShowCreateBtn, SetShowCreateBtn] = useState(false);
  const [SrcOfPlayList, SetSrcOfPlayList] = useState("");
  const [Title, SetTitle] = useState("");
  const [Type, SetType] = useState(null);
  const [SRC, SetSRC]: any = useState(null);
  const [Level, SetLevel]: any = useState(null);
  const [Levels, SetLevels]: any = useState(null);

  useEffect(() => {
    GetAllLevelsOfSchool({
      UserId,
      SchoolId,
    }).then((result) => {
      if (result.Levels.length > 0) {
        let _Levels = result.Levels;
        let Selection_Levels = [];
        for (let i = 0; i < _Levels.length; i++) {
          const element = _Levels[i];
          Selection_Levels.push({
            label: element.LevelTitle,
            value: element.LevelId,
          });
        }
        SetLevels(Selection_Levels);
        SetShowCreateBtn(true);
      }
    });
  }, []);
  const CreatePlayListMedthod = () => {
    SetLoading(true);
    CreatePlayListFun({
      Token: Encrypt_Back({
        Data: {
          UserId,
          Title,
          IsVisible: Type,
          ImageUrl: SRC,
          LevelId: Level,
          SchoolId,
          DateOfPlayList: _Date(),
        },
        expiresIn: { minutes: 5 },
      }),
    }).then((result) => {
      if (result.Data.Created) {
        SetShow(!result.Data.Created);
        toast.success(result.Data.Message, {
          closeOnClick: true,
          theme: "dark",
        });
        OnCreated(true);
        SetShow(false);
        SetTitle("");
        SetSrcOfPlayList("");
        SetSRC(null);
        SetType(null);
        SetDisabledSelectBox(false);
      } else {
        toast.error(result.Data.Message, {
          closeOnClick: true,
          theme: "dark",
        });
      }
      SetLoading(false);
    });
  };
  useEffect(() => {
    if (Type != null && Level != null && Title.length != 0 && SRC != null) {
      SetdisabledConfirmBtn(false);
      SetdisabledCloseBtn(true);
    } else {
      SetdisabledConfirmBtn(true);
      SetdisabledCloseBtn(false);
    }
  }, [Title, Type, Level, SRC]);
  return (
    <div className="w-100 d-flex justify-content-center align-items-center mb-3">
      {ShowCreateBtn && (
        <ModalComp
          ButtonTitle={
            <>
              <span className="mx-2">{Create.Create}</span>
              <Plus />
            </>
          }
          ModalTitle={Create.CreateNewPlayList}
          ConfirmBtn={
            <>
              <span className="me-2">{Create.CreateBtn}</span>
              <Plus />
            </>
          }
          variantBtn="info"
          variantModal="light"
          disabledCloseBtn={disabledCloseBtn}
          disabledConfirmBtn={disabledConfirmBtn}
          onClickConFirmBtn={CreatePlayListMedthod}
          Show={Show}
          handleClose={() => {
            SetShow(false);
            SetTitle("");
            SetSrcOfPlayList("");
            SetType(null);
            SetSRC(null);
            SetDisabledSelectBox(false);
          }}
          handleShow={() => {
            SetShow(true);
            SetTitle("");
            SetSrcOfPlayList("");
            SetDisabledSelectBox(false);
            SetSRC(null);
            SetType(null);
          }}
          Loading={Loading}
        >
          <div>
            <UploadFile
              Change=""
              OnCompleteUpload={({ FileURL }: { FileURL: string }) => {
                SetSRC(`${FileURL}`);
                SetDisabledSelectBox(true);
              }}
              SchoolId={SchoolId}
            />
            <CreatableSelect
              className="text-dark mb-2"
              isClearable
              options={Levels}
              onChange={(e: any) => {
                if (e != null) {
                  SetLevel(e.value);
                } else {
                  SetLevel(null);
                }
              }}
              placeholder={Create.Message2}
            />
            <CreatableSelect
              className="text-dark"
              isClearable
              options={TypeOfPlayListSelectOption}
              onChange={(e: any) => {
                if (e != null) {
                  SetType(e.value);
                } else {
                  SetType(null);
                }
              }}
              placeholder={Create.Message}
            />
            <input
              type="text"
              placeholder={Create.CreateNewPlayList}
              className="w-100 form-control my-3"
              onChange={(e) => {
                SetTitle(e.target.value);
              }}
              value={Title}
            />
          </div>
        </ModalComp>
      )}
    </div>
  );
};

export default CreatePlayList;
/*
         UserId,
          Title,
          IsVisible,
          ImageUrl,
          LevelId,
          SchoolId,
          DateOfPlayList,*/
