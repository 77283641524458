import React, { useEffect, useState } from "react";
import { GetUserDataFun } from "../../../services/api/User/User";
import {
  Decrypt_Back,
  Encrypt_Back,
} from "../../../services/jwt with backend/JWT.With.Backend";
import { useData } from "../../../data/management/Data.Management";

const UserScore = ({ Id }: { Id: string }) => {
  const {
    Data: { UserId },
  }: any = useData();
  let [TotalScore, SetTotalScore] = useState(0);
  let [_TotalScore, Set_TotalScore] = useState(0);
  let [TotalScoreByPercent, SetTotalScoreByPercent] = useState(0);
  let [Loading, SetLoading] = useState(true);
  let FirstTime = true;
  useEffect(() => {
    if (FirstTime) {
      GetUserDataFun({
        Token: Encrypt_Back({
          Data: {
            UserId,
            Id: Id?.replace("@", ""),
          },
          expiresIn: { minutes: 5 },
        }),
      }).then((result) => {
        try {
          if (result.Data.UserData != undefined) {
            if (Decrypt_Back({ Data: result.Data.UserData })) {
              let Data = Decrypt_Back({ Data: result.Data.UserData }).Data;
              let Forms = Data.Forms;
              let Forms1 = Data.Forms;

              Forms = Forms.filter((Form: any) => Form.Type == "Quiz");
              Forms1 = Forms.filter((Form: any) => Form.Type == "Quiz");
              let _Score = 0;
              for (let i = 0; i < Forms.length; i++) {
                const element = Forms[i];
                _Score += Number(element.Score);
                SetLoading(false);
              }
              Set_TotalScore(_Score);

              Forms1.forEach((Form: any) => {
                if (Form.Answers != undefined) {
                  TotalScore += Number(
                    Form.Answers.Answers[Form.Answers.Header.indexOf("Score")]
                      .replace(/ /g, "")
                      .split("/")[0]
                  );
                  _Score += Number(Form.Score);
                  SetTotalScore(TotalScore);
                }
                SetLoading(false);
              });
            }
          }
        } catch (error) {}
      });
      FirstTime = false;
    }
  }, []);
  useEffect(() => {
    SetTotalScoreByPercent((TotalScore / _TotalScore) * 100);
  }, [_TotalScore, TotalScore]);
  return (
    <div className="Score">
      {Loading ? (
        <div className="w-100 d-flex align-items-center justify-content-center">
          <div
            className="spinner-border text-primary"
            style={{ width: "50px", height: "50px" }}
            role="status"
          ></div>
        </div>
      ) : (
        <>
          <span>
            Your Score Is: {TotalScore}/{_TotalScore}
          </span>
          <div className="progress mt-3">
            <div
              className="progress-bar bg-primary progress-bar-striped progress-bar-animated"
              role="progressbar"
              aria-label="Animated striped example"
              aria-valuenow={TotalScoreByPercent}
              aria-valuemin={0}
              aria-valuemax={100}
              style={{ width: `${TotalScoreByPercent}%` }}
            >
              {Math.round(TotalScoreByPercent) + "%"}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default UserScore;
