import React, { useEffect, useState } from "react";
import { useData } from "../../../data/management/Data.Management";
import { GetUserData } from "../../../services/api/Profile/profile";

const UserInfo = () => {
  const {
    Data: {
      IsLoggin,
      Roles,
      UserData: { UserName },
      LanguageData: { Profile },
      UserId,
      Theme,
    },
    SetTheme,
    SetPermissions,
    SetRoles,
    SetMode,
    SetLanguage,
    SetUserLogin,
  }: any = useData();
  let [AllDataWeNeed, SetAllDataWeNeed]: any = useState(null);
  useEffect(() => {
    GetUserData({ UserId })
      .then((result) => {
        SetAllDataWeNeed(result);
      })
      .catch((err) => {});
  }, []);
  return (
    <div>
      {AllDataWeNeed != null && (
        <p>
          {Profile.UserInfo[0]} {UserName}
          <div className="GoodStyle">
            {Profile.UserInfo[1]} “{AllDataWeNeed.Student.Group.GroupName}”
            {Profile.UserInfo[2]} “{AllDataWeNeed.Student.School.SchoolName}”{" "}
            {Profile.UserInfo[3]}, <br />
            {AllDataWeNeed.Student.Group.Leaders.length > 1 ? (
              <span>
                {Profile.UserInfo[4]}{" "}
                {AllDataWeNeed.Student.Group.Leaders.map(
                  (Leader: any, i: number) => {
                    if (AllDataWeNeed.Student.Group.Leaders.length - 1 == i) {
                      return (
                        <span key={i}>{Leader.UserName.split(" ")[0]} </span>
                      );
                    } else {
                      return (
                        <span key={i}>{Leader.UserName.split(" ")[0]}, </span>
                      );
                    }
                  }
                )}
              </span>
            ) : (
              <span>
                {Profile.UserInfo[5]}{" "}
                {AllDataWeNeed.Student.Group.Leaders.map(
                  (Leader: any, i: number) => {
                    return <span key={i}>{Leader.UserName.split(" ")[0]}</span>;
                  }
                )}
              </span>
            )}
          </div>
        </p>
      )}
      {AllDataWeNeed == null && (
        <div
          className="spinner-border text-primary mx-auto d-block mt-4"
          role="status"
        >
          <span className="sr-only"></span>
        </div>
      )}
    </div>
  );
};

export default UserInfo;
