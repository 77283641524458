import axios from "axios";
import { BackendURL } from "../URLs";
import {
  Decrypt_Back,
  Decrypt_BackQraphQl,
} from "../../jwt with backend/JWT.With.Backend";
import { GraphQlURL } from "../../../data/main/URLS";
export const CreateSchoolFun = ({ Token }: { Token: string }) => {
  return axios
    .post(`${BackendURL}School/Create`, { Token, date: `${new Date()}` })
    .then((result) => {
      return result.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
export const GetSchoolsFun = ({ Token }: { Token: string }) => {
  return axios
    .post(`${BackendURL}School/Get`, { Token, date: `${new Date()}` })
    .then((result) => {
      return result.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
export const DeleteSchoolsFun = ({ Token }: { Token: string }) => {
  return axios
    .post(`${BackendURL}School/Delete`, { Token, date: `${new Date()}` })
    .then((result) => {
      return result.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
export const EditSchoolsFun = ({ Token }: { Token: string }) => {
  return axios
    .post(`${BackendURL}School/Edit`, { Token, date: `${new Date()}` })
    .then((result) => {
      return result.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
export const GetSchoolAllInfoFun = ({
  UserId,
  SchoolId,
}: {
  UserId: string;
  SchoolId: string;
}) => {
  return axios
    .post(`${GraphQlURL}`, {
      query: `
        query Query($UserId:String,$SchoolId:String) {
          School(UserId:$UserId,SchoolId:$SchoolId) {
            SchoolName
            SchoolId
            SchoolSrcImage
            CreateAt
            Groups {
              GroupId
              GroupName
              CreateAt
              Leaders {
                UserName
                Email
                UserImage
              }
              Students {
                UserName
                Email
                UserImage
              }                
            }
          }
        }
      `,

      variables: {
        UserId,
        SchoolId,
      },
    })
    .then((result) => {
      return Decrypt_BackQraphQl({ Data: result.data.data.data }).Data;
    });
};
export const GetGroupsOfSchoolFun = ({
  UserId,
  SchoolId,
}: {
  UserId: string;
  SchoolId: string;
}) => {
  return axios
    .post(`${GraphQlURL}`, {
      query: `
  query Query($UserId:String,$SchoolId:String) {
    School(UserId:$UserId,SchoolId:$SchoolId) {
      SchoolName
      SchoolId
      SchoolSrcImage
      CreateAt
      Groups {
        GroupId
        GroupName
        CreateAt
        SchoolId
      }
    }
  }
`,
      variables: {
        UserId,
        SchoolId,
      },
    })
    .then((result) => {
      return Decrypt_BackQraphQl({ Data: result.data.data.data }).Data;
    });
};
export const GetLeadersOfSchoolFun = ({
  UserId,
  SchoolId,
}: {
  UserId: string;
  SchoolId: string;
}) => {
  // Null
  return axios
    .post(`${GraphQlURL}`, {
      query: `
  query Query($UserId:String,$SchoolId:String) {
    School(UserId:$UserId,SchoolId:$SchoolId) {
      SchoolName
      SchoolId
      SchoolSrcImage
      CreateAt
      Groups {
        GroupId
        GroupName
        CreateAt
        Leaders {
          UserName
          Email
          UserImage
          UserId
          Group {
            GroupId
            GroupName
          }
        }        
      }
    }
  }
`,
      variables: {
        UserId,
        SchoolId,
      },
    })
    .then((result) => {
      return Decrypt_BackQraphQl({ Data: result.data.data.data }).Data;
    });
};
export const GetStudentsOfSchoolFun = ({
  UserId,
  SchoolId,
}: {
  UserId: string;
  SchoolId: string;
}) => {
  return axios
    .post(`${GraphQlURL}`, {
      query: `
  query Query($UserId:String,$SchoolId:String) {
    School(UserId:$UserId,SchoolId:$SchoolId) {
      SchoolName
      SchoolId
      SchoolSrcImage
      CreateAt
      Groups {
        GroupId
        GroupName
        CreateAt        
        Students {
          UserName
          Email
          UserImage
          UserId
          Id
          Group {
            GroupId
            GroupName
          }          
        }     
      }
    }
  }
`,
      variables: {
        UserId,
        SchoolId,
      },
    })
    .then((result) => {
      return Decrypt_BackQraphQl({ Data: result.data.data.data }).Data;
    });
};
export const GetSchoolDataFun = ({
  UserId,
  SchoolId,
}: {
  UserId: string;
  SchoolId: string;
}) => {
  return axios
    .post(`${GraphQlURL}`, {
      query: `
    query Query($UserId:String,$SchoolId:String) {
      School(UserId:$UserId,SchoolId:$SchoolId) {
        SchoolName
        SchoolId
        SchoolSrcImage
        CreateAt
      }
    }
`,
      variables: {
        UserId,
        SchoolId,
      },
    })
    .then((result) => {
      return Decrypt_BackQraphQl({ Data: result.data.data.data }).Data;
    });
};
export const GetSchoolAllInfoByUserIdFun = ({
  UserId,
  SchoolId,
}: {
  UserId: string;
  SchoolId: string;
}) => {
  return axios
    .post(`${GraphQlURL}`, {
      query: `
        query Query($UserId:String,$SchoolId:String) {
          School(UserId:$UserId,SchoolId:$SchoolId) {
            SchoolName
            SchoolId
            SchoolSrcImage
            CreateAt
            Groups {
              GroupId
              GroupName
              CreateAt
              Leaders {
                UserName
                Email
                UserImage
                UserId
                Id
              }
              Students {
                UserName
                Email
                UserImage
                GroupId
                UserId
                Id
              }
            }
          }
        }
      `,
      variables: {
        UserId,
        SchoolId,
      },
    })
    .then((result) => {
      return Decrypt_BackQraphQl({ Data: result.data.data.data }).Data;
    });
};
