import React, { useEffect, useState } from "react";
import { useData } from "../../../../../data/management/Data.Management";
import DeleteLibrary from "./components/Delete.Library";
import { calculateTimePeriod } from "../../../../../services/Format Date/Format";
import EditLibrary from "./components/Edit.Library";
import PDFViewer from "../../../../../components/Design/PDFViewer";
import { Download } from "lucide-react";

const LibraryComponent = ({
  SchoolId,
  UserId,
  LibraryData,
  OnDataChanged,
}: {
  SchoolId: string;
  UserId: string;
  LibraryData: any;
  OnDataChanged: Function;
}) => {
  const {
    Data: {
      Roles,
      LanguageData: { ShowMore, ShowLess },
    },
  }: any = useData();
  const [_LibraryData, SetLibraryData]: any = useState(null);
  let LengthOfCut = 20;
  let [Description, SetDescription] = useState(
    LibraryData.Description.length > LengthOfCut
      ? LibraryData.Description.slice(0, LengthOfCut) + "..."
      : LibraryData.Description
  );
  const [BtnText, SetBtnText] = useState(ShowMore);
  const [IsShow, SetIsShow] = useState(false);
  const ToggleView = () => {
    if (IsShow) {
      SetIsShow(false);
      SetBtnText(ShowMore);
      SetDescription(
        _LibraryData.Description.length > LengthOfCut
          ? _LibraryData.Description.slice(0, LengthOfCut) + "..."
          : _LibraryData.Description
      );
    } else {
      SetIsShow(true);
      SetBtnText(ShowLess);
      SetDescription(_LibraryData.Description);
    }
  };
  let [Height, SetHeight] = useState({ Height: 0, ClassName: "" });
  useEffect(() => {
    switch (LibraryData.Type) {
      case "SoundCloud":
        SetHeight({
          ClassName: "SoundCloud",
          Height: 160,
        });
        break;
      case "PDF":
        SetHeight({
          ClassName: "PDF",
          Height: 500,
        });
        break;
      default:
        SetHeight({
          ClassName: "Media",
          Height: 270,
        });
    }
  }, [LibraryData.Type]);
  useEffect(() => {
    if (_LibraryData != null) {
      SetDescription(
        _LibraryData.Description.length > LengthOfCut
          ? _LibraryData.Description.slice(0, LengthOfCut) + "..."
          : _LibraryData.Description
      );
    }
    SetBtnText(ShowMore);
    SetIsShow(false);
  }, [_LibraryData]);
  useEffect(() => {
    SetLibraryData(null);
    SetLibraryData(LibraryData);
    console.log(LibraryData);
  }, [LibraryData]);
  return (
    <div className="Library">
      {_LibraryData != null && (
        <>
          {_LibraryData.Type == "PDF" ? (
            <div className="Image">
              <img src={LibraryData.PDFURL} alt="" />
              <a
                href={LibraryData.Link}
                target="_blank"
                className="btn btn-primary mx-auto mt-3 d-block"
                style={{ width: "fit-content" }}
              >
                <Download />
              </a>
            </div>
          ) : (
            <iframe
              className={`w-100 ${Height.ClassName}`}
              src={LibraryData.Link}
              allow="fullscreen"
              height={Height.Height}
            ></iframe>
          )}

          <div className="body">
            {Roles.includes("Admin") && (
              <div className="Actions">
                <DeleteLibrary
                  LibraryData={_LibraryData}
                  OnDeleted={() => {
                    OnDataChanged(true);
                  }}
                  SchoolId={SchoolId}
                  UserId={UserId}
                />
                <EditLibrary
                  LibraryData={_LibraryData}
                  OnEdit={(_LibraryData_: any) => {
                    SetLibraryData(_LibraryData_);
                    OnDataChanged(true);
                  }}
                  SchoolId={SchoolId}
                  UserId={UserId}
                />
              </div>
            )}
            <span
              className="Date fst-italic Rubik"
              dir="ltr"
              style={{ fontSize: "14px", opacity: ".8" }}
            >
              {calculateTimePeriod(_LibraryData.CreateAt)}
            </span>
            <p
              dir={
                /[\u0600-\u06FF]/.test(_LibraryData.Description) ? "rtl" : "ltr"
              }
              className={`${
                /[\u0600-\u06FF]/.test(_LibraryData.Description) ? "" : "Rubik"
              }`}
            >
              {Description}
              {_LibraryData.Description.length > LengthOfCut && (
                <button onClick={ToggleView} className="ToggleView">
                  {"  "}
                  {`" ${BtnText} "`}
                </button>
              )}
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default LibraryComponent;
