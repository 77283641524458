import React, { useState } from "react";
import ModalComp from "../../../../../../components/Design/Modal";
import { Trash } from "lucide-react";
import { Encrypt_Back } from "../../../../../../services/jwt with backend/JWT.With.Backend";
import { toast } from "react-toastify";
import { useData } from "../../../../../../data/management/Data.Management";
import { DeleteFileByUrl, Storage } from "../../../../../../firebase/firebase";
import { DeletePlayListFun } from "../../../../../../services/api/PlayList/PlayList";

const DeletePlayList = ({
  PlayListData,
  UserId,
  SchoolId,
  OnDeleted,
}: {
  PlayListData: any;
  UserId: string;
  SchoolId: string;
  OnDeleted: Function;
}) => {
  const {
    Data: {
      LanguageData: {
        DashBord: {
          PlayList: { Delete },
        },
      },
    },
  }: any = useData();
  const [disabledCloseBtn, SetdisabledCloseBtn] = useState(false);
  const [disabledConfirmBtn, SetdisabledConfirmBtn] = useState(false);
  const [Loading, SetLoading] = useState(false);
  const [Show, SetShow] = useState(false);

  const DeletePlayList = async () => {
    SetLoading(true);
    if (PlayListData.PlayListImage.length > 0) {
      await DeleteFileByUrl(PlayListData.PlayListImage);
    }
    DeletePlayListFun({
      Token: Encrypt_Back({
        Data: { UserId, SchoolId, PlayListId: PlayListData.PlayListId },
        expiresIn: { minutes: 5 },
      }),
    }).then((result) => {
      SetShow(!result.Data.Deleted);
      toast.success(result.Data.Message, {
        closeOnClick: true,
        theme: "dark",
      });
      OnDeleted(true);
      SetLoading(false);
    });
  };

  return (
    <ModalComp
      ButtonTitle={
        <>
          <Trash />
        </>
      }
      ButtonTitlevariant="danger"
      ModalTitle={Delete.DeletePlayList}
      ConfirmBtn={
        <>
          <span className="me-2">{Delete.DeleteBtn}</span>
          <Trash />
        </>
      }
      variantBtn="danger"
      variantModal="light"
      disabledCloseBtn={disabledCloseBtn}
      disabledConfirmBtn={disabledConfirmBtn}
      onClickConFirmBtn={DeletePlayList}
      Show={Show}
      handleClose={() => {
        SetShow(false);
      }}
      handleShow={() => {
        SetShow(true);
      }}
      Loading={Loading}
    >
      <div>
        <div className="body">
          <p
            dir={/[\u0600-\u06FF]/.test(PlayListData.Title) ? "rtl" : "ltr"}
            className="my-2 bg-danger p-2 rounded-3"
          >
            {PlayListData.Title.length > 30
              ? PlayListData.Title.slice(0, 30) + "..."
              : PlayListData.Title}
          </p>
        </div>
      </div>
    </ModalComp>
  );
};

export default DeletePlayList;
