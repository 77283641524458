import axios from "axios";
import { BackendURL } from "../URLs";
import {
  Decrypt_Back,
  Decrypt_BackQraphQl,
} from "../../jwt with backend/JWT.With.Backend";
import { GraphQlURL } from "../../../data/main/URLS";

export const GetUserData = ({ UserId }: { UserId: string }) => {
  return axios
    .post(`${GraphQlURL}`, {
      query: `
        query Query($StudentId:String) {
          Student(StudentId:$StudentId) {
          UserName
            Group {
              GroupName
              Leaders {
                UserName
              }
            }
            School {
              SchoolName
            }
          }
        }
      `,
      variables: {
        StudentId: UserId,
      },
    })
    .then((result) => {
      return Decrypt_BackQraphQl({ Data: result.data.data.data }).Data;
    });
};
