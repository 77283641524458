import React, { useEffect, useState } from "react";
import { GetAllLevelsOfSchool } from "../../../../../../services/api/Level/Level";
import LevelView from "./Group.View";
import { useData } from "../../../../../../data/management/Data.Management";

const GetAllLevels = ({
  GetNewLevels,
  SchoolId,
  UserId,
}: {
  GetNewLevels: String;
  SchoolId: string;
  UserId: string;
}) => {
  const {
    Data: {
      LanguageData: {
        DashBord: {
          Level: {
            Messages: { NoLevelsCreated },
          },
        },
      },
    },
  }: any = useData();
  let [Levels, SetLevels]: any = useState(null);
  let [Loading, SetLoading]: any = useState(true);
  let [Show, SetShow]: any = useState(false);
  let GetAllLevels = () => {
    SetLoading(true);
    GetAllLevelsOfSchool({
      UserId,
      SchoolId,
    })
      .then((result) => {
        SetLoading(false);
        if (result.Levels.length > 0) {
          SetShow(true);
          SetLevels(result.Levels);
        } else {
          SetShow(false);
        }
      })
      .catch((err) => {});
  };
  useEffect(() => {
    SetLoading(true);
    GetAllLevelsOfSchool({
      UserId,
      SchoolId,
    })
      .then((result) => {
        SetLoading(false);
        if (result.Levels.length > 0) {
          SetShow(true);
          SetLevels(result.Levels);
        } else {
          SetShow(false);
        }
      })
      .catch((err) => {});
  }, [GetNewLevels]);
  return (
    <div className="Levels">
      {Loading ? (
        <div className="d-flex w-100 align-items-center justify-content-center mt-2">
          <div
            className="spinner-border text-primary"
            style={{ width: "50px", height: "50px" }}
            role="status"
          ></div>
        </div>
      ) : (
        <>
          {Show ? (
            <>
              {Levels.map((Level: any, i: number) => {
                return (
                  <LevelView
                    Index={i}
                    LevelData={Level}
                    OnDeleted={GetAllLevels}
                    OnEdit={GetAllLevels}
                    Title={Level.LevelTitle}
                    UserId={UserId}
                    key={i}
                  />
                );
              })}
            </>
          ) : (
            <div className="d-flex align-items-center w-100 justify-content-center">
              <div className="alert alert-info text-center">
                {NoLevelsCreated}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default GetAllLevels;
