import { CirclePlus, Library, Plus } from "lucide-react";
import React, { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { GetGroupsOfSchoolFun } from "../../../../../../services/api/school/School";
import ModalComp from "../../../../../../components/Design/Modal";
import { CreateLeaderFun } from "../../../../../../services/api/Group/User";
import { Encrypt_Back } from "../../../../../../services/jwt with backend/JWT.With.Backend";
import { toast } from "react-toastify";
import { useData } from "../../../../../../data/management/Data.Management";
import UploadFile from "./Upload.File";
import { CreateLibraryFun } from "../../../../../../services/api/Library/Library";
import { GetAllLevelsOfSchool } from "../../../../../../services/api/Level/Level";
import { _Date } from "../../../../../../services/Format Date/Format";
import { useParams } from "react-router-dom";
const CreateLibrary = ({
  SchoolId,
  UserId,
  OnCreated,
}: {
  SchoolId: string;
  UserId: string;
  OnCreated: Function;
}) => {
  let {
    Data: {
      LanguageData: {
        DashBord: {
          Library: { Create },
          Group,
        },
      },
    },
  }: any = useData();
  let { PlayListId } = useParams();
  const [TypeOfLibrarySelectOption]: any = useState([
    {
      value: "FaceBook",
      label: "FaceBook",
    },
    {
      value: "YouTube",
      label: "YouTube",
    },
    {
      value: "SoundCloud",
      label: "SoundCloud",
    },
    {
      value: "PDF",
      label: "PDF",
    },
  ]);
  const [disabledCloseBtn, SetdisabledCloseBtn] = useState(false);
  const [disabledConfirmBtn, SetdisabledConfirmBtn] = useState(true);
  const [DisabledSelectBox, SetDisabledSelectBox] = useState(false);
  const [Loading, SetLoading] = useState(false);
  const [Show, SetShow] = useState(false);
  const [ShowCreateBtn, SetShowCreateBtn] = useState(false);
  const [SrcOfLibrary, SetSrcOfLibrary] = useState("");
  const [Description, SetDescription] = useState("");
  const [Type, SetType] = useState(null);
  const [SRC, SetSRC]: any = useState(null);
  const [Level, SetLevel]: any = useState(null);
  const [Levels, SetLevels]: any = useState(null);
  let [ChangeDataInUpload, SetChangeDataInUpload] = useState("");
  let [PDFURL, SetPDFURL] = useState("");

  useEffect(() => {
    if (SrcOfLibrary != "") {
      let parser = new DOMParser();
      let doc = parser.parseFromString(SrcOfLibrary, "text/html");
      let iframe: any = doc.querySelector("iframe");
      try {
        let src = iframe.getAttribute("src");
        SetSRC(src);
      } catch {
        SetSRC(null);
      }
    }
  }, [SrcOfLibrary]);
  useEffect(() => {
    GetAllLevelsOfSchool({
      UserId,
      SchoolId,
    }).then((result) => {
      if (result.Levels.length > 0) {
        let _Levels = result.Levels;
        let Selection_Levels = [];
        for (let i = 0; i < _Levels.length; i++) {
          const element = _Levels[i];
          Selection_Levels.push({
            label: element.LevelTitle,
            value: element.LevelId,
          });
        }
        SetLevels(Selection_Levels);
        SetShowCreateBtn(true);
      }
    });
  }, []);
  const CreateLeaderMedthod = () => {
    SetLoading(true);
    CreateLibraryFun({
      Token: Encrypt_Back({
        Data: {
          UserId,
          Link: SRC,
          SchoolId,
          Description,
          Type,
          CreateAt: _Date(),
          // LevelId: Level,
          PDFURL,
          PlayListId: `${PlayListId?.replaceAll("_-_", "#")}`,
        },
        expiresIn: { minutes: 5 },
      }),
    }).then((result) => {
      if (result.Data.Created) {
        SetShow(!result.Data.Created);
        toast.success(result.Data.Message, {
          closeOnClick: true,
          theme: "dark",
        });
        OnCreated(true);
        SetShow(false);
        SetDescription("");
        SetSrcOfLibrary("");
        SetSRC(null);
        SetType(null);
        SetDisabledSelectBox(false);
      } else {
        toast.error(result.Data.Message, {
          closeOnClick: true,
          theme: "dark",
        });
      }
      SetLoading(false);
    });
  };
  useEffect(() => {
    if (SRC != null || (Type == "PDF" && PDFURL != "")) {
      SetdisabledConfirmBtn(false);
      SetdisabledCloseBtn(true);
    } else {
      SetdisabledConfirmBtn(true);
      SetdisabledCloseBtn(false);
    }
  }, [Description, SRC, Level]);
  return (
    <div className="w-100 d-flex justify-content-center align-items-center mb-3">
      {ShowCreateBtn && (
        <ModalComp
          ButtonTitle={
            <>
              <span className="mx-2">{Create.Create}</span>
              <Plus />
            </>
          }
          ModalTitle={Create.CreateNewLeader}
          ConfirmBtn={
            <>
              <span className="me-2">{Create.CreateBtn}</span>
              <Plus />
            </>
          }
          variantBtn="info"
          variantModal="light"
          disabledCloseBtn={disabledCloseBtn}
          disabledConfirmBtn={disabledConfirmBtn}
          onClickConFirmBtn={CreateLeaderMedthod}
          Show={Show}
          handleClose={() => {
            SetShow(false);
            SetDescription("");
            SetSrcOfLibrary("");
            SetType(null);
            SetSRC(null);
            SetDisabledSelectBox(false);
          }}
          handleShow={() => {
            SetShow(true);
            SetDescription("");
            SetSrcOfLibrary("");
            SetDisabledSelectBox(false);
            SetSRC(null);
            SetType(null);
          }}
          Loading={Loading}
        >
          {/* <CreatableSelect
            className="text-dark mb-2"
            isClearable
            options={Levels}
            onChange={(e: any) => {
              if (e != null) {
                SetLevel(e.value);
              } else {
                SetLevel(null);
              }
            }}
            placeholder={Create.Message2}
            isDisabled={DisabledSelectBox}
          /> */}
          <CreatableSelect
            className="text-dark"
            isClearable
            options={TypeOfLibrarySelectOption}
            onChange={(e: any) => {
              if (e != null) {
                SetType(e.value);
                if (e.value == "PDF") {
                  SetSRC(null);
                  SetSrcOfLibrary("");
                }
              } else {
                SetType(null);
              }
            }}
            placeholder={Create.Message}
            isDisabled={DisabledSelectBox}
          />
          {Type != null && (
            <div>
              {Type == "PDF" ? (
                <div>
                  <UploadFile
                    Title="رفع الملف"
                    fileTypes={["pdf"]}
                    OnCompleteUpload={({ FileURL }: { FileURL: string }) => {
                      SetSRC(`${FileURL}`);
                      SetDisabledSelectBox(true);
                    }}
                    Change={ChangeDataInUpload}
                    SchoolId={SchoolId}
                  />
                  <UploadFile
                    Title="رفع صورة الملف"
                    fileTypes={["png", "jpg"]}
                    OnCompleteUpload={({ FileURL }: { FileURL: string }) => {
                      SetPDFURL(`${FileURL}`);
                      SetDisabledSelectBox(true);
                    }}
                    Change={ChangeDataInUpload}
                    SchoolId={SchoolId}
                  />
                </div>
              ) : (
                <input
                  type="text"
                  className="form-control mt-2"
                  placeholder={`Enter Src Of Your ${Type}`}
                  value={SrcOfLibrary}
                  onChange={(e) => {
                    SetSrcOfLibrary(e.target.value);
                  }}
                />
              )}
            </div>
          )}
          {SRC != null && (
            <iframe
              src={SRC}
              width="100%"
              height={Type == "SoundCloud" ? 160 : 260}
              className="mt-3"
              allow="fullscreen"
            ></iframe>
          )}
          <textarea
            name="Description"
            id="Description"
            style={{ height: "150px", maxHeight: "300px", minHeight: "100px" }}
            placeholder={Create.Description}
            className="form-control mt-2"
            value={Description}
            onChange={(e) => {
              SetDescription(e.target.value);
            }}
          ></textarea>
        </ModalComp>
      )}
    </div>
  );
};

export default CreateLibrary;
