import axios from "axios";
import { BackendURL } from "../URLs";
import {
  Decrypt_Back,
  Decrypt_BackQraphQl,
} from "../../jwt with backend/JWT.With.Backend";
import { GraphQlURL } from "../../../data/main/URLS";
export const CreateLibraryFun = ({ Token }: { Token: string }) => {
  return axios
    .post(`${BackendURL}Library/Create`, { Token, date: `${new Date()}` })
    .then((result) => {
      return result.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
export const DeleteLibraryFun = ({ Token }: { Token: string }) => {
  console.log(Token);

  return axios
    .post(`${BackendURL}Library/Delete`, { Token, date: `${new Date()}` })
    .then((result) => {
      return result.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
export const EditLibraryFun = ({ Token }: { Token: string }) => {
  return axios
    .post(`${BackendURL}Library/Edit`, { Token, date: `${new Date()}` })
    .then((result) => {
      return result.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
export const GetAllLibrariesForSchool = ({
  UserId,
  SchoolId,
  PlayListId,
}: {
  UserId: string;
  SchoolId: string;
  PlayListId: string;
}) => {
  return axios
    .post(`${GraphQlURL}`, {
      query: `
        query Query($UserId:String,$SchoolId:String,$PlayListId:String) {
          Libraries(UserId:$UserId,SchoolId:$SchoolId,PlayListId:$PlayListId) {
            CreateAt
            Description
            Link
            Type
            LibraryId
            UserId
            PDFURL
          }
        }
      `,
      variables: {
        UserId,
        SchoolId,
        PlayListId,
      },
    })
    .then((result) => {
      return Decrypt_BackQraphQl({ Data: result.data.data.data }).Data;
    });
};
