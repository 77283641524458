import axios from "axios";
import { BackendURL } from "../URLs";
export const CreateLeaderFun = ({ Token }: { Token: string }) => {
  return axios
    .post(`${BackendURL}Leader/Create`, { Token, date: `${new Date()}` })
    .then((result) => {
      return result.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
export const CreateStudentFun = ({ Token }: { Token: string }) => {
  return axios
    .post(`${BackendURL}Student/Create`, { Token, date: `${new Date()}` })
    .then((result) => {
      return result.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
export const DeleteUserFun = ({ Token }: { Token: string }) => {
  return axios
    .post(`${BackendURL}User/Delete`, { Token, date: `${new Date()}` })
    .then((result) => {
      return result.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
export const EditUserFun = ({ Token }: { Token: string }) => {
  return axios
    .post(`${BackendURL}User/Edit`, { Token, date: `${new Date()}` })
    .then((result) => {
      return result.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
