import React from "react";
import { calculateTimePeriod } from "../../../../../../services/Format Date/Format";
import EditPlayList from "./Edit.PlayList";
import { useData } from "../../../../../../data/management/Data.Management";
import { useNavigate, useParams } from "react-router-dom";
import DeletePlayList from "./Delete.PlayList";
import { Info } from "lucide-react";

const ViewPlayList = ({
  PlayListData,
  OnChanges,
}: {
  PlayListData: any;
  OnChanges: Function;
}) => {
  const {
    Data: { UserId, Roles },
  }: any = useData();
  let { SchoolId } = useParams();
  let Navigate = useNavigate();
  const GoToPlayList = () => {
    if (!Roles.includes("Admin")) {
      Navigate(`${PlayListData.PlayListId.replaceAll("#", "_-_")}`);
    }
  };
  return (
    <div className="PlayList" onClick={GoToPlayList}>
      {PlayListData.PlayListImage?.length > 0 ? (
        <img src={PlayListData.PlayListImage} alt="" />
      ) : (
        <img src="/Images/PlayListNoImageUploaded.png" alt="" />
      )}

      <div>
        <h2>{PlayListData.Title}</h2>
        <p>
          <span>{calculateTimePeriod(PlayListData.CreateAt)}</span>
          <span>{PlayListData.Libraries.length}</span>
        </p>
        <div className="Actions">
          {Roles.includes("Admin") && (
            <>
              <EditPlayList
                OnEdit={OnChanges}
                PlayListData={PlayListData}
                SchoolId={`${SchoolId?.replaceAll("_-_", "#")}`}
                UserId={UserId}
              />
              <DeletePlayList
                OnDeleted={OnChanges}
                PlayListData={PlayListData}
                SchoolId={`${SchoolId?.replaceAll("_-_", "#")}`}
                UserId={UserId}
              />
              <button
                className="m-1 d-inline-flex align-items-center justify-content-center text-white btn btn-outline-primary"
                onClick={() => {
                  console.log();
                  Navigate(
                    `/DashBord/School/${PlayListData.SchoolId.replaceAll(
                      "#",
                      "_-_"
                    )}/PlayLists/${PlayListData.PlayListId.replaceAll(
                      "#",
                      "_-_"
                    )}/libraries`
                  );
                }}
              >
                <Info />
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewPlayList;
