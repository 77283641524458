import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
const Redirect = ({ To }: { To: string }) => {
  const Navigate = useNavigate();
  useEffect(() => {
    Navigate(To);
  }, []);
  return <div></div>;
};

export default Redirect;
