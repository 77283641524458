import React from "react";
import ChangeTheme from "./components/Change.Theme";
import ChangeLanguage from "./components/Change.Language";
import { useData } from "../../data/management/Data.Management";
import UserInfo from "./components/UserInfo";
import ChangeUserName from "./components/Change.UserName";
import UserData from "./components/UserData";
import FormsType from "./components/FormsType";
import Score from "./components/Score";

const ProfilePage = () => {
  const {
    Data: {
      Roles,
      Language,
      UserData: { Email },
      LanguageData: { Profile },
    },
  }: any = useData();
  return (
    <div
      id="ProfilePage"
      className={`d-flex align-items-center justify-content-center flex-column ${
        Roles.length == 1 && Roles.includes("Student") ? "" : "Admin"
      } ${Language == "Ar" ? "Left" : "Right"}`}
    >
      {!Roles.includes("Student") ? (
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-8 col-xs-12">
              <ChangeTheme />
              <ChangeLanguage />
              {Roles.includes("Student") && <UserInfo />}
            </div>
            <div className="col-xl-4 col-lg-6 col-md-8 col-xs-12">
              <div className="Box-Input">
                <label htmlFor="Email" className="Email mb-1">
                  {Profile.Inputs.Email}
                </label>
                <input
                  type="email"
                  name="Email"
                  id="Email"
                  disabled={true}
                  placeholder={Profile.Inputs.Email}
                  className="form-control"
                  value={Email}
                />
              </div>
              <ChangeUserName />
            </div>
          </div>
        </div>
      ) : (
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-6 col-md-12 col-xs-12">
              <ChangeTheme />
              <ChangeLanguage />
              <UserInfo />
              <Score />
            </div>
            <div className="col-xl-4 col-lg-6 col-md-8 col-xs-12">
              <div className="Box-Input">
                <label htmlFor="Email" className="Email mb-1">
                  {Profile.Inputs.Email}
                </label>
                <input
                  type="email"
                  name="Email"
                  id="Email"
                  disabled={true}
                  placeholder={Profile.Inputs.Email}
                  className="form-control"
                  value={Email}
                />
              </div>
              <ChangeUserName />
            </div>
            <div className="col-xl-4 col-lg-6 col-md-8 col-xs-12">
              <FormsType />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfilePage;
