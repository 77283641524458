import React, { useState } from "react";
import { useData } from "../../../../../../data/management/Data.Management";
import ModalComp from "../../../../../../components/Design/Modal";
import { Trash } from "lucide-react";
import { DeleteSchoolsFun } from "../../../../../../services/api/school/School";
import { Encrypt_Back } from "../../../../../../services/jwt with backend/JWT.With.Backend";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { DeleteFileByUrl } from "../../../../../../firebase/firebase";

const DeleteSchool = ({
  SchoolData,
  UserId,
  OnDeleted,
}: {
  SchoolData: any;
  UserId: string;
  OnDeleted: Function;
}) => {
  const {
    Data: {
      LanguageData: {
        DashBord: {
          School: { Delete },
        },
      },
    },
  }: any = useData();
  const [disabledCloseBtn, SetdisabledCloseBtn] = useState(false);
  const [disabledConfirmBtn, SetdisabledConfirmBtn] = useState(false);
  const [Loading, SetLoading] = useState(false);
  const [Show, SetShow] = useState(false);
  let Navigate = useNavigate();
  const DeleteSchool = async () => {
    SetLoading(true);
    if (await DeleteFileByUrl(`${SchoolData.SchoolSrcImage}`)) {
      DeleteSchoolsFun({
        Token: Encrypt_Back({
          Data: {
            SchoolId: SchoolData.SchoolId,
            UserId,
          },
          expiresIn: {
            minutes: 5,
          },
        }),
      }).then((result) => {
        SetShow(!result.Data.Deleted);
        toast.success(result.Data.Message, {
          closeOnClick: true,
          theme: "dark",
        });
        OnDeleted(true);
        SetLoading(false);
        Navigate("/DashBord/");
      });
    }
  };
  return (
    <ModalComp
      ButtonTitle={
        <>
          <Trash />
        </>
      }
      ButtonTitlevariant="danger"
      ModalTitle={Delete.DeleteSchool}
      ConfirmBtn={
        <>
          <span className="me-2">{Delete.DeleteBtn}</span>
          <Trash />
        </>
      }
      variantBtn="danger"
      variantModal="light"
      disabledCloseBtn={disabledCloseBtn}
      disabledConfirmBtn={disabledConfirmBtn}
      onClickConFirmBtn={DeleteSchool}
      Show={Show}
      handleClose={() => {
        SetShow(false);
      }}
      handleShow={() => {
        SetShow(true);
      }}
      Loading={Loading}
    >
      <input
        type="text"
        disabled={true}
        value={SchoolData.SchoolName}
        className="form-control"
      />
    </ModalComp>
  );
};

export default DeleteSchool;
