import { useEffect, useState } from "react";
import { useData } from "../../data/management/Data.Management";
import { useNavigate } from "react-router-dom";
import { GetNotificationsForSchoolFun } from "../../services/api/Notifications/Notifications";
import { Connect } from "../dash-bord/components/Page Of DashBord/Notifications/Connect.To.Socket.io";
import { GetSchoolDataFun } from "../../services/api/school/School";
import Message from "../dash-bord/components/Page Of DashBord/Notifications/components/Notification";

const NotificationsForAllSchool = () => {
  const {
    Data: {
      Roles,
      UserId,
      UserData: { SchoolId },
      LanguageData: {
        DashBord: {
          Notifications: { MessageForNoNotifications },
        },
      },
    },
  }: any = useData();
  let Navigate = useNavigate();
  const [Notifications, SetNotifications]: any = useState(null);
  const [, SetDate] = useState("");
  useEffect(() => {
    if (Roles.includes("Admin")) {
      Navigate("/");
    } else {
      let Socket = Connect();
      GetSchoolDataFun({
        SchoolId: `${SchoolId?.replaceAll("_-_", "#")}`,
        UserId,
      }).then((result: any) => {
        Socket.emit("joinRoom", result.School);
        Socket.on("message", (message) => {
          if (message.Error == false) {
            GetNotificationsForSchoolFun({ UserId })
              .then((result) => {
                SetNotifications([]);
                setTimeout(() => {
                  SetNotifications(() => result.Notifications.reverse());
                }, 10);
              })
              .catch((err) => {});
          }
        });
      });
      GetNotificationsForSchoolFun({ UserId })
        .then((result) => {
          SetNotifications(result.Notifications.reverse());
        })
        .catch((err) => {});
    }
  }, [Roles]);

  return (
    <div
      className={`ViewMessages Show ${Roles.includes("Leader") && "Leader"}`}
    >
      <div className="w-100 d-flex align-items-center justify-content-center">
        {Notifications == null && (
          <div className="spinner-border text-primary" role="status"></div>
        )}
      </div>

      {Notifications != null &&
        Notifications.length > 0 &&
        Notifications.map((Notification: any, i: number) => {
          return <Message Index={i} Notification={Notification} key={i} />;
        })}
      {Notifications != null && Notifications.length == 0 && (
        <div
          className="alert alert-info m-auto"
          style={{ width: "fit-content" }}
        >
          {MessageForNoNotifications}
        </div>
      )}
    </div>
  );
};

export default NotificationsForAllSchool;
