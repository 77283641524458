import { Plus } from "lucide-react";
import React, { useEffect, useState } from "react";
import ModalComp from "../../../../../../components/Design/Modal";
import { CreateGroupFun } from "../../../../../../services/api/Group/Group";
import { Encrypt_Back } from "../../../../../../services/jwt with backend/JWT.With.Backend";
import { toast } from "react-toastify";
import { useData } from "../../../../../../data/management/Data.Management";
import { _Date } from "../../../../../../services/Format Date/Format";

const CreateGroup = ({
  SchoolId,
  UserId,
  OnCreated,
}: {
  SchoolId: string;
  UserId: string;
  OnCreated: Function;
}) => {
  const {
    Data: {
      LanguageData: {
        DashBord: {
          Group: { Create },
        },
      },
    },
  }: any = useData();

  const [disabledCloseBtn, SetdisabledCloseBtn] = useState(false);
  const [disabledConfirmBtn, SetdisabledConfirmBtn] = useState(true);
  const [Loading, SetLoading] = useState(false);
  const [Show, SetShow] = useState(false);
  const [GroupName, SetGroupName] = useState("");
  useEffect(() => {
    if (GroupName.replace(/ /g, "").length > 0) {
      SetdisabledConfirmBtn(false);
    } else {
      SetdisabledConfirmBtn(true);
    }
  }, [GroupName]);
  const CreateGroup = () => {
    SetLoading(true);
    CreateGroupFun({
      Token: Encrypt_Back({
        Data: { GroupName, SchoolId, UserId, CreateAt: _Date() },
        expiresIn: {
          minutes: 5,
        },
      }),
    }).then((result) => {
      SetShow(!result.Data.Created);
      toast.success(result.Data.Message, {
        closeOnClick: true,
        theme: "dark",
      });
      OnCreated(true);
      SetGroupName("");
      SetLoading(false);
    });
  };
  return (
    <div className="w-100 d-flex justify-content-center align-items-center mb-2">
      <ModalComp
        ButtonTitle={
          <>
            <span className="mx-2">{Create.Create}</span>
            <Plus />
          </>
        }
        ModalTitle={Create.CreateNewGroup}
        ConfirmBtn={
          <>
            <span className="me-2">{Create.CreateBtn}</span>
            <Plus />
          </>
        }
        variantBtn="info"
        variantModal="light"
        disabledCloseBtn={disabledCloseBtn}
        disabledConfirmBtn={disabledConfirmBtn}
        onClickConFirmBtn={CreateGroup}
        Show={Show}
        handleClose={() => {
          SetShow(false);
          SetGroupName("");
        }}
        handleShow={() => {
          SetShow(true);
          SetGroupName("");
        }}
        Loading={Loading}
      >
        <input
          type="text"
          disabled={Loading}
          value={GroupName}
          onChange={(e: any) => {
            SetGroupName(e.target.value);
          }}
          placeholder={Create.GroupName}
          className="form-control"
        />
      </ModalComp>
    </div>
  );
};

export default CreateGroup;
