import React, { useEffect, useState } from "react";
import { GetSchoolsFun } from "../../../../../services/api/school/School";
import {
  Decrypt_Back,
  Encrypt_Back,
} from "../../../../../services/jwt with backend/JWT.With.Backend";
import { useData } from "../../../../../data/management/Data.Management";
import School from "./components/School";

const GetSchoolsPage = () => {
  const {
    Data: {
      UserData: { UserId },
      LanguageData: {
        DashBord: {
          School: {
            Messages: { NoSchoolCreated },
          },
        },
      },
    },
  }: any = useData();
  const [Schools, SetSchools]: any = useState(null);

  useEffect(() => {
    GetSchoolsFun({
      Token: Encrypt_Back({
        Data: {
          UserId,
        },
        expiresIn: {
          minutes: 5,
        },
      }),
    }).then((result) => {
      SetSchools(Decrypt_Back({ Data: result.Data.Schools }).Data);
    });
  }, []);
  return (
    <div>
      <div className="Schools">
        <section className="w-100 d-flex align-items-center justify-content-center">
          {Schools == null && (
            <div
              className="spinner-border text-primary"
              style={{ width: "50px", height: "50px" }}
              role="status"
            ></div>
          )}
        </section>
        {Schools != null && (
          <div>
            {Schools.length > 0 ? (
              Schools.map((_School: any, i: number) => {
                return <School SchoolData={_School} key={i} />;
              })
            ) : (
              <div className="alert alert-primary">{NoSchoolCreated}</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default GetSchoolsPage;
