import React, { useEffect, useState } from "react";
import { useData } from "../../../data/management/Data.Management";
import { ChangeUserNameFun } from "../../../services/api/User/User";
import {
  Decrypt_Back,
  Encrypt_Back,
} from "../../../services/jwt with backend/JWT.With.Backend";
import { toast } from "react-toastify";

const ChangeUserName = () => {
  const {
    Data: {
      Roles,
      Language,
      UserData: { UserName, UserId },
      LanguageData: { Profile },
    },
    SetTheme,
    SetPermissions,
    SetRoles,
    SetMode,
    SetLanguage,
    SetUserLogin,
  }: any = useData();
  const [NewUserName, SetNewUserName] = useState(`${UserName}`);

  const [ShowBtn, SetShowBtn] = useState(false);
  const [DisabledBtn, SetDisabledBtn] = useState(false);
  const [IsLoading, SetIsLoading] = useState(false);
  useEffect(() => {
    if (NewUserName.replace(/ /g, "").length > 0 && NewUserName != UserName) {
      SetShowBtn(true);
    } else {
      SetShowBtn(false);
    }
  }, [NewUserName]);
  const ChangeName = () => {
    SetDisabledBtn(true);
    SetIsLoading(true);
    ChangeUserNameFun({
      Token: Encrypt_Back({
        Data: {
          UserName: NewUserName,
          UserId: UserId,
        },
        expiresIn: { minutes: 5 },
      }),
    }).then((result) => {
      toast.success(result.Data.Message, { theme: "dark", closeOnClick: true });
      let UserData = Decrypt_Back({
        Data: result.Data.UserData,
      }).Data;
      SetPermissions(UserData.Permissions);
      SetRoles(UserData.Roles);
      SetTheme(UserData.Theme);
      SetLanguage(UserData.Language);
      SetUserLogin(UserData);
      SetDisabledBtn(false);
      SetIsLoading(false);
      SetShowBtn(false);
    });
  };
  return (
    <div className="Box-Input my-2">
      <label htmlFor="UserName" className="UserName mb-1">
        {Profile.Inputs.UserName}
      </label>
      <input
        type="UserName"
        name="UserName"
        id="UserName"
        placeholder={Profile.Inputs.UserName}
        className="form-control"
        value={NewUserName}
        onChange={(e) => {
          SetNewUserName(e.target.value);
        }}
        disabled={DisabledBtn}
      />
      {ShowBtn && (
        <button
          className="btn btn-primary mt-2 d-flex align-items-center justify-content-center"
          disabled={DisabledBtn}
          onClick={ChangeName}
        >
          <span className="mx-2">{Profile.Inputs.ChangeBtn}</span>
          {IsLoading && (
            <div
              className="spinner-border text-light"
              style={{ width: "25px", height: "25px" }}
              role="status"
            ></div>
          )}
        </button>
      )}
    </div>
  );
};

export default ChangeUserName;
