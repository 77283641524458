import React, { useEffect, useState } from "react";
import ModalComp from "../../../../../../components/Design/Modal";
import { Trash } from "lucide-react";
import { Encrypt_Back } from "../../../../../../services/jwt with backend/JWT.With.Backend";
import { toast } from "react-toastify";
import { useData } from "../../../../../../data/management/Data.Management";
import { DeleteFileByUrl, Storage } from "../../../../../../firebase/firebase";
import { DeleteLibraryFun } from "../../../../../../services/api/Library/Library";

const DeleteLibrary = ({
  LibraryData,
  UserId,
  SchoolId,
  OnDeleted,
}: {
  LibraryData: any;
  UserId: string;
  SchoolId: string;
  OnDeleted: Function;
}) => {
  const {
    Data: {
      LanguageData: {
        DashBord: {
          Library: { Delete },
        },
      },
    },
  }: any = useData();
  const [disabledCloseBtn, SetdisabledCloseBtn] = useState(false);
  const [disabledConfirmBtn, SetdisabledConfirmBtn] = useState(false);
  const [Loading, SetLoading] = useState(false);
  const [Show, SetShow] = useState(false);

  const DeleteLibrary = () => {
    SetLoading(true);
    if (LibraryData.Type == "PDF") {
      DeleteFileByUrl(LibraryData.Link).then((result) => {
        DeleteFileByUrl(LibraryData.PDFURL).then((result1) => {
          console.log(result1);

          DeleteLibraryFun({
            Token: Encrypt_Back({
              Data: { UserId, SchoolId, LibraryId: LibraryData.LibraryId },
              expiresIn: { minutes: 5 },
            }),
          }).then((result) => {
            SetShow(!result.Data.Deleted);
            toast.success(result.Data.Message, {
              closeOnClick: true,
              theme: "dark",
            });
            OnDeleted(true);
            SetLoading(false);
          });
        });
      });
    } else {
      DeleteLibraryFun({
        Token: Encrypt_Back({
          Data: { UserId, SchoolId, LibraryId: LibraryData.LibraryId },
          expiresIn: { minutes: 5 },
        }),
      }).then((result) => {
        SetShow(!result.Data.Deleted);
        toast.success(result.Data.Message, {
          closeOnClick: true,
          theme: "dark",
        });
        OnDeleted(true);
        SetLoading(false);
      });
    }
  };
  let [Height, SetHeight] = useState({ Height: 0, ClassName: "" });
  useEffect(() => {
    switch (LibraryData.Type) {
      case "SoundCloud":
        SetHeight({
          ClassName: "SoundCloud",
          Height: 160,
        });
        break;
      case "PDF":
        SetHeight({
          ClassName: "PDF",
          Height: 500,
        });
        break;
      default:
        SetHeight({
          ClassName: "Media",
          Height: 270,
        });
    }
  }, [LibraryData.Type]);
  return (
    <ModalComp
      ButtonTitle={
        <>
          <Trash />
        </>
      }
      ButtonTitlevariant="danger"
      ModalTitle={Delete.DeleteLibrary}
      ConfirmBtn={
        <>
          <span className="me-2">{Delete.DeleteBtn}</span>
          <Trash />
        </>
      }
      variantBtn="danger"
      variantModal="light"
      disabledCloseBtn={disabledCloseBtn}
      disabledConfirmBtn={disabledConfirmBtn}
      onClickConFirmBtn={DeleteLibrary}
      Show={Show}
      handleClose={() => {
        SetShow(false);
      }}
      handleShow={() => {
        SetShow(true);
      }}
      Loading={Loading}
    >
      <div>
        {LibraryData.Type == "PDF" ? (
          <div className="Image w-100">
            <img src={LibraryData.PDFURL} className=" w-100" alt="" />
          </div>
        ) : (
          <iframe
            className={`w-100 ${Height.ClassName}`}
            src={LibraryData.Link}
            allow="fullscreen"
            height={Height.Height}
          ></iframe>
        )}
        <div className="body">
          <p
            dir={
              /[\u0600-\u06FF]/.test(LibraryData.Description) ? "rtl" : "ltr"
            }
          >
            {LibraryData.Description.length > 30
              ? LibraryData.Description.slice(0, 30) + "..."
              : LibraryData.Description}
          </p>
        </div>
      </div>
    </ModalComp>
  );
};

export default DeleteLibrary;
