import React, { useEffect, useState } from "react";
import FormViewDataOfUser from "../../Your-Group/components/Form.View.Data.Of.User";
import { GetUserDataFun } from "../../../services/api/User/User";
import {
  Decrypt_Back,
  Encrypt_Back,
} from "../../../services/jwt with backend/JWT.With.Backend";
import { useData } from "../../../data/management/Data.Management";

const QuizPage = () => {
  const {
    Data: {
      UserId,
      Roles,
      Language,
      UserData: { Id },
      LanguageData: {
        DashBord: {
          Form: {
            Messages: { NoFormsCreated },
          },
        },
      },
    },
  }: any = useData();
  //   let { Id } = useParams();
  let [UserData, SetUserData]: any = useState(null);
  let [Forms, SetForms]: any = useState(null);
  const [Loading, SetLoading] = useState(true);

  useEffect(() => {
    GetUserDataFun({
      Token: Encrypt_Back({
        Data: {
          UserId,
          Id: Id?.replace("@", ""),
        },
        expiresIn: { minutes: 5 },
      }),
    }).then((result) => {
      try {
        if (result.Data.UserData != undefined) {
          if (Decrypt_Back({ Data: result.Data.UserData })) {
            let Data = Decrypt_Back({ Data: result.Data.UserData }).Data;

            let Forms = Data.Forms;
            let ResultOfFilter = Forms.filter((Form: any) => {
              return Form.Type == "Quiz";
            });
            console.log(ResultOfFilter);

            SetUserData(Data.UserData);
            SetForms(ResultOfFilter);
          }
        }
        setTimeout(() => {
          SetLoading(false);
        }, 1000);
      } catch (error) {}
    });
  }, []);
  return (
    <div
      id="YourGroupPage"
      className={`d-flex User align-items-center justify-content-center flex-column ${
        Roles.length == 1 && Roles.includes("Student") ? "" : "Leader"
      } ${Language == "Ar" ? "Left" : "Right"}`}
    >
      <div className="w-100">
        {Loading ? (
          <div className="w-100 d-flex align-items-center justify-content-center">
            <div
              className="spinner-border text-primary"
              style={{ width: "50px", height: "50px" }}
              role="status"
            ></div>
          </div>
        ) : (
          <div className="w-100 d-flex flex-column">
            {Forms != null && Forms.length > 0 && (
              <>
                <div className="Forms">
                  {Forms.map((Form: any, i: number) => {
                    return <FormViewDataOfUser Data={Form} key={i} />;
                  })}
                </div>
              </>
            )}
            {Forms != null && Forms.length == 0 && (
              <div
                className="alert alert-primary d-block mx-auto"
                style={{ width: "fit-content" }}
              >
                {NoFormsCreated}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default QuizPage;
