import React, { useEffect, useState } from "react";

import { GetGroupsOfSchoolFun } from "../../../../../../services/api/school/School";
import { useData } from "../../../../../../data/management/Data.Management";
import GroupView from "./Group.View";
import { motion } from "framer-motion";
const GetAllGroups = ({
  SchoolId,
  GetGroups,
}: {
  SchoolId: string;
  GetGroups: string;
}) => {
  const {
    Data: {
      UserId,
      LanguageData: {
        DashBord: {
          Group: {
            Messages: { NoSGroupsCreated },
          },
        },
      },
    },
  }: any = useData();
  const [Groups, SetGroups]: any = useState(null);
  const GetGroupsFun = () => {
    GetGroupsOfSchoolFun({
      SchoolId,
      UserId,
    })
      .then((result) => {
        SetGroups(result.School.Groups);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    GetGroupsFun();
  }, [GetGroups]);
  return (
    <div className="Groups">
      <section className="w-100 d-flex align-items-center justify-content-center">
        {Groups == null && (
          <div
            className="spinner-border text-primary"
            style={{ width: "50px", height: "50px" }}
            role="status"
          ></div>
        )}
      </section>
      {Groups != null &&
        (Groups.length > 0 ? (
          Groups.map((Group: any, i: number) => {
            return (
              <motion.div
                key={i}
                initial={{ opacity: 0, x: 200 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{
                  ease: "easeInOut",
                  duration: 0.5,
                  delay: (i + 1) * 2 * 0.05,
                }}
              >
                <GroupView
                  Index={i}
                  GroupData={Group}
                  Title={Group.GroupName}
                  UserId={UserId}
                  OnDeleted={() => {
                    GetGroupsFun();
                  }}
                  OnEdit={() => {
                    GetGroupsFun();
                  }}
                />
              </motion.div>
            );
          })
        ) : (
          <div
            className="alert alert-primary m-auto mt-3"
            style={{ width: "fit-content" }}
          >
            {NoSGroupsCreated}
          </div>
        ))}
    </div>
  );
};

export default GetAllGroups;
