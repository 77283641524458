import React, { useEffect, useState } from "react";
import { Connect } from "./Connect.To.Socket.io";
import { useParams } from "react-router-dom";
import { GetSchoolAllInfoFun } from "../../../../../services/api/school/School";
import { useData } from "../../../../../data/management/Data.Management";
import { GetNotificationsForSchoolFunAdmin } from "../../../../../services/api/Notifications/Notifications";
import SendSection from "./components/SendSection";
import Notification from "./components/Notification";

const Notifications = () => {
  let { SchoolId } = useParams();
  const {
    Data: {
      UserId,

      LanguageData: {
        Errors: { SchoolNotFound },
      },
    },
  }: any = useData();
  let Socket = Connect();
  const [Notifications, SetNotifications]: any = useState([]);
  const UpDate = () => {
    GetNotificationsForSchoolFunAdmin({ UserId, SchoolId: `${SchoolId}` })
      .then((result: any) => {
        SetNotifications([]);
        setTimeout(() => {
          SetNotifications(result.NotificationsAdmin.reverse());
        }, 100);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    UpDate();
    const handleNotificationUpdate = (prevNotifications: any[]) => {
      SetNotifications(() => [...prevNotifications]);
    };
    Socket.on("message", (message) => {
      if (message.Error == false) {
        GetNotificationsForSchoolFunAdmin({ UserId, SchoolId: `${SchoolId}` })
          .then((result: any) => {
            handleNotificationUpdate(result.NotificationsAdmin.reverse());
          })
          .catch((err) => {});
      }
    });
  }, [UserId, SchoolId]);
  const [Show, SetShow] = useState(false);
  const [Loading, SetLoading] = useState(true);
  useEffect(() => {
    GetSchoolAllInfoFun({
      SchoolId: `${SchoolId?.replaceAll("_-_", "#")}`,
      UserId: UserId,
    }).then((result) => {
      SetLoading(false);
      if (result != null) {
        SetShow(true);
      }
    });
  }, []);
  return (
    <div id="Notifications">
      {Loading ? (
        <div className="d-flex w-100 align-items-center justify-content-center mt-2">
          <div
            className="spinner-border text-primary"
            role="status"
            style={{ width: "50px", height: "50px" }}
          ></div>
        </div>
      ) : (
        <>
          {Show ? (
            <>
              <div className="ViewMessages" style={{ overflowX: "hidden" }}>
                {Notifications.map((_Notification: any, i: number) => {
                  return (
                    <Notification
                      OnDeleted={UpDate}
                      Notification={_Notification}
                      key={i}
                      Index={i}
                    />
                  );
                })}
              </div>
              <div className="SendSection">
                <SendSection
                  OnSended={UpDate}
                  SchoolId={`${SchoolId}`}
                  UserId={UserId}
                />
              </div>
            </>
          ) : (
            <div className="d-flex align-items-center w-100 justify-content-center">
              <div className="alert alert-info text-center">
                {SchoolNotFound}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Notifications;
