import moment from "moment";
export function convertDate(dateStr: any) {
  try {
    // Create a Date object from the input date string
    let date = new Date(dateStr);

    // Extract components
    let day = date.getDate();
    let month = date.getMonth() + 1; // Months are zero-based in JavaScript
    let year = date.getFullYear();
    let hours = date.getHours();
    let minutes: any = date.getMinutes();

    // Convert to 12-hour format and determine AM/PM
    let period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'

    // Pad single digit minutes with a leading zero
    minutes = minutes < 10 ? "0" + minutes : minutes;

    // Format the date string
    let formattedDate = `${day}/${month}/${year} ${hours}:${minutes} ${period}`;
    return formattedDate;
  } catch (error) {
    return null;
  }
}
export function calculateTimePeriod(dateStr: any) {
  try {
    // Create a Date object from the input date string
    let startDate: any = new Date(dateStr);
    let now: any = new Date(
      moment.utc(new Date()).local().format("YYYY-MM-DD HH:mm:ss")
    );

    // Calculate the difference in milliseconds
    let diff = now - startDate;

    // Convert the difference to various units
    let seconds = Math.floor(diff / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    let days = Math.floor(hours / 24);
    let months = Math.floor(days / 30); // Approximation
    let years = Math.floor(months / 12);

    // Calculate remainder values for each unit
    months = months % 12;
    days = days % 30;
    hours = hours % 24;
    minutes = minutes % 60;
    seconds = seconds % 60;

    // Create a formatted string
    if (years != 0) {
      return years + " y";
    } else {
      if (months != 0) {
        return months + " mu";
      } else {
        if (days != 0) {
          return days + " d";
        } else {
          if (hours != 0) {
            return hours + " h";
          } else {
            if (minutes != 0) {
              return minutes + " mi";
            } else {
              return "Just Now";
            }
          }
        }
      }
    }

    // const _year = startDate.getFullYear();
    // const _month = startDate.getMonth() + 1; // Months are zero-indexed
    // const _day = startDate.getDate();
    // let _hours = startDate.getHours();
    // const _minutes = startDate.getMinutes().toString().padStart(2, "0");
    // const _ampm = hours >= 12 ? "AM" : "PM";
    // _hours = _hours % 12;
    // _hours = _hours ? _hours : 12; // The hour '0' should be '12'
    // const formattedHours = _hours.toString().padStart(2, "0");

    // return `${_year}/${_month}/${_day} ${formattedHours}:${_minutes} ${_ampm}`;
    // // return `${startDate.getFullYear()}/${
    // //   startDate.getMonth() + 1
    // // }/${startDate.getDate()}  ${startDate.getHours()}:${startDate.getMinutes()}`;
  } catch (error) {
    return null;
  }
}
export const _Date = () => {
  return moment.utc(new Date()).local().format("YYYY-MM-DD HH:mm:ss");
};
