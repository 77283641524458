import React, { useEffect, useState } from "react";
import { CreateSchoolFun } from "../../../../../../services/api/school/School";
import {
  Decrypt_Back,
  Encrypt_Back,
} from "../../../../../../services/jwt with backend/JWT.With.Backend";
import UploadImage from "./Upload.Image";
import { toast } from "react-toastify";
import { useData } from "../../../../../../data/management/Data.Management";
import { useNavigate } from "react-router-dom";
import { _Date } from "../../../../../../services/Format Date/Format";

const CreateSchoolPage = () => {
  const {
    Data: {
      LanguageData: {
        DashBord: {
          School: { Create },
        },
      },
    },
  }: any = useData();
  const [SchoolName, SetSchoolName] = useState("");
  const [SchoolImage, SetSchoolImage] = useState("");
  let [Loading, SetLoading] = useState(false);
  let [DisabledCreateBtn, SetDisabledCreateBtn] = useState(true);
  let [ChangeDataInUpload, SetChangeDataInUpload] = useState("");
  let Navigate = useNavigate();
  const CreateSchool = () => {
    SetLoading(true);
    SetDisabledCreateBtn(true);
    CreateSchoolFun({
      Token: Encrypt_Back({
        Data: {
          CreateAt: _Date(),
          SchoolName: SchoolName,
          SchoolImage: SchoolImage,
        },
        expiresIn: {
          minutes: 5,
        },
      }),
    }).then((result) => {
      SetLoading(false);
      SetDisabledCreateBtn(true);
      Navigate("/DashBord/");
      toast.success(result.Data.Message, {
        theme: "dark",
        closeOnClick: true,
      });
      SetSchoolName("");
      SetChangeDataInUpload(`${new Date()}`);
    });
  };
  useEffect(() => {
    if (SchoolName.length > 0 && SchoolImage.length > 0) {
      SetDisabledCreateBtn(false);
    } else {
      SetDisabledCreateBtn(true);
    }
  }, [SchoolName, SchoolImage]);
  return (
    <div className="CreateSchool">
      <div className="Box-Input w-100">
        <label htmlFor="SchoolName" className="form-check-label ms-1 mb-1">
          {Create.SchoolName}
        </label>
        <input
          type="text"
          id="SchoolName"
          name="SchoolName"
          className="form-control"
          placeholder={Create.SchoolName}
          onChange={(e) => {
            SetSchoolName(e.target.value);
          }}
        />
      </div>
      <div className="Upload mt-4 w-100">
        <UploadImage
          OnCompleteUpload={({ ImgSrc }: { ImgSrc: string }) => {
            SetSchoolImage(ImgSrc);
          }}
          Change={ChangeDataInUpload}
        />
      </div>
      <div className="w-100">
        <button
          className="btn btn-primary rounded-5 mt-3 w-100 py-2 d-flex align-items-center justify-content-center"
          disabled={DisabledCreateBtn}
          onClick={CreateSchool}
        >
          <span> {Create.CreateBtn}</span>
          {Loading && (
            <div
              className="spinner-border text-light ms-2"
              role="status"
              style={{ width: "25px", height: "25px" }}
            ></div>
          )}
        </button>
      </div>
    </div>
  );
};

export default CreateSchoolPage;
/*
rules_version = '2';

// Craft rules based on data in your Firestore database
// allow write: if firestore.get(
//    /databases/(default)/documents/users/$(request.auth.uid)).data.isAdmin;
service firebase.storage {
  match /b/{bucket}/o {
    match /{allPaths=**} {
      allow read, write: if false;
    }
  }
}*/
