import React, { useEffect, useState } from "react";
import { useData } from "../../../data/management/Data.Management";
import { IconsForNav } from "../../../data/static/Links";
import { NavLink, useNavigate } from "react-router-dom";

const ASideLinks = () => {
  const {
    Data: {
      LanguageData: {
        AppName,
        Header: { LogOutBtn },
        DashBord,
      },
      Language,
      Role,
    },
    SetRole,
    SetMode,
    SetLanguage,
    SetUserLogin,
    SetLogin,
  }: any = useData();
  const [links, Setlinks]: any = useState([]);
  const [, SetTime] = useState("");
  const Navigate = useNavigate();
  const [IsSchoolPart, SetIsSchoolPart] = useState(false);
  const [SchoolId, SetSchoolId] = useState("");
  useEffect(() => {
    if (window.location.hash.split("/").includes("School")) {
      SetSchoolId(window.location.hash.split("/")[3]);

      SetIsSchoolPart(true);
    } else {
      SetIsSchoolPart(false);
    }
    Setlinks(DashBord.Links);
    SetTime(`${new Date()}`);
  }, [Navigate]);
  useEffect(() => {
    Setlinks(DashBord.Links);
    SetTime(`${new Date()}`);
  }, [Language, Role]);
  const _Icon = (Name: string) => {
    return IconsForNav.filter((Icon) => Icon.Name == Name)[0].Icon;
  };
  const [ClassASide, SetClassASide] = useState("");
  window.onclick = (e: any) => {
    if (ClassASide.length != 0) {
      if (`${e.target.className}` != "[object SVGAnimatedString]") {
        if (e.target.className.split(" ").includes("ASideLinks")) {
          if (!window.location.href.split("#").includes("ShowPopup")) {
            Navigate(
              window.location.hash.slice(1, window.location.hash.length) +
                "#ShowPopup"
            );
          } else {
            Navigate(
              window.location.hash
                .split("#")
                .slice(0, window.location.hash.split("#").length - 1)
                .join("#")
                .slice(
                  1,
                  window.location.hash
                    .split("#")
                    .slice(0, window.location.hash.split("#").length - 1)
                    .join("#").length
                )
            );
          }
        }
      }
    }
  };
  useEffect(() => {
    SetClassASide(
      window.location.hash.split("#")[
        window.location.hash.split("#").length - 1
      ] == "ShowPopup"
        ? "Show"
        : ""
    );
  }, [window.location.hash]);
  return (
    <ul
      className={`${ClassASide} p-0 m-0 ${Language == "Ar" ? "LargeFont" : ""}`}
    >
      {links.map((_Link: any, i: number) => {
        return (
          <li
            key={i}
            //
            className="nav-item"
            // data-aos-delay={(i + 1) * 100}
          >
            <NavLink to={_Link.path} className="w-100 nav-link collapsed">
              {_Icon(_Link.Name)}
              <span className="mx-2">{_Link.Title}</span>
            </NavLink>
          </li>
        );
      })}
      {IsSchoolPart && SchoolId.length != 0 && (
        <>
          {DashBord.LinksForSchool.map((_Link: any, i: number) => {
            return (
              <li className="nav-item" key={i}>
                <NavLink
                  to={_Link.path.replace(":SchoolId", SchoolId)}
                  className="w-100 nav-link collapsed"
                >
                  {_Icon(_Link.Name)}
                  <span className="mx-2">{_Link.Title} </span>
                </NavLink>
              </li>
            );
          })}
        </>
      )}
    </ul>
  );
};

export default ASideLinks;
