import React from "react";
import { Edit, Trash } from "lucide-react";
import DeleteBtnLeader from "./Delete.Btn.Leader";
import DeleteBtnUser from "./Delete.Btn.Leader";
import EditBtnUser from "./Edit.Btn.Leader";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
const LeaderView = ({
  Title,
  LeaderData,
  GroupsData,
  UserId,
  OnDeleted,
  SchoolId,
}: {
  Title: string;
  LeaderData: any;
  GroupsData: any[];
  UserId: string;
  SchoolId: string;
  OnDeleted: Function;
}) => {
  return (
    <div className="Leader">
      <h3 className="mb-0">
        {/* {Title.length > 25 ? (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="button-tooltip">{Title}</Tooltip>}
          >
            <span>{Title.slice(0, 25) + ".."}</span>
          </OverlayTrigger>
        ) : (
          Title
        )} */}
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="button-tooltip">{Title}</Tooltip>}
        >
          <span>{Title}</span>
        </OverlayTrigger>
      </h3>
      <div>
        <EditBtnUser
          UserData={LeaderData}
          OnEdit={OnDeleted}
          UserId={UserId}
          type="Leader"
          SchoolId={SchoolId}
          GroupsData={GroupsData}
        />
        <DeleteBtnUser
          type="Leader"
          UserData={LeaderData}
          OnDeleted={OnDeleted}
          UserId={UserId}
        />
      </div>
    </div>
  );
};

export default LeaderView;
