import React from "react";
import { calculateTimePeriod } from "../../../services/Format Date/Format";

const FormView = ({ FormData }: { FormData: any }) => {
  return (
    <div className="Form">
      <div className="Header">
        <h3>{FormData.FormTitle}</h3>
      </div>
      <div className="body">
        {FormData.FormDescription.length > 0 && (
          <p>{FormData.FormDescription}</p>
        )}
        <button className="btn btn-primary">
          <a href={FormData.FormUrl} target="_blank">
            Go To Form
          </a>
        </button>
      </div>
      <span className="Time">{calculateTimePeriod(FormData.CreateAt)}</span>
    </div>
  );
};

export default FormView;
