import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GetSchoolAllInfoFun } from "../../../../../../services/api/school/School";
import { useData } from "../../../../../../data/management/Data.Management";
import Img from "../../../../../../components/Design/Img";
import "../../../../../../styles/DashBord/DashBord.Show.School.style.css";
import Pagination from "@mui/material/Pagination";
import { motion } from "framer-motion";
import DeleteSchool from "./Delete.School";
import EditSchool from "./Edit.School";

const ActionForSchool = () => {
  let { SchoolId } = useParams();
  const {
    Data: {
      UserId,
      LanguageData: {
        DashBord: { SchoolView },
        Errors: { SchoolNotFound },
      },
      Language,
    },
  }: any = useData();

  let [SchoolData, SetSchoolData]: any = useState(null);
  let [, SetDate]: any = useState("");
  let [Pages, SetPages]: any = useState([]);
  let [IndexPage, SetIndexPage]: any = useState(0);
  const [Show, SetShow] = useState(false);
  const [Loading, SetLoading] = useState(true);
  useEffect(() => {
    if (SchoolId != undefined) {
      GetSchoolAllInfoFun({
        SchoolId: SchoolId?.replaceAll("_-_", "#"),
        UserId: UserId,
      })
        .then((result) => {
          console.log(result);

          SetLoading(false);
          if (result != null) {
            console.log(result);
            SetPages(chunk(result.School.Groups, 2));
            SetSchoolData(result.School);
            SetDate(`${new Date()}`);
            SetShow(true);
          } else {
            SetSchoolData(null);
            SetShow(false);
          }
        })
        .catch((err) => {});
    }
  }, []);
  const chunk = (arr: any[], size: number) =>
    Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
      arr.slice(i * size, i * size + size)
    );

  return (
    <div className="DashBordShowSchool">
      {Loading ? (
        <section className="w-100 d-flex align-items-center justify-content-center">
          <div
            className="spinner-border text-primary"
            style={{ width: "50px", height: "50px" }}
            role="status"
          ></div>
        </section>
      ) : (
        <>
          {Show ? (
            <>
              <div className="w-100">
                <div className="header d-flex justify-content-between">
                  <div className="d-flex align-items-center justify-content-start">
                    <Img
                      Default=""
                      Height={60}
                      URL={SchoolData.SchoolSrcImage}
                      Width={60}
                    />
                    <h2 className="mx-2">{SchoolData.SchoolName}</h2>
                  </div>
                  <div className="Actions">
                    <DeleteSchool
                      OnDeleted={() => {}}
                      SchoolData={SchoolData}
                      UserId={UserId}
                    />
                    <EditSchool
                      OnEditd={() => {}}
                      SchoolData={SchoolData}
                      UserId={UserId}
                    />
                  </div>
                </div>
                <hr />
                {SchoolData.Groups.length != 0 && (
                  <div className="Groups">
                    {Pages[IndexPage].map((Group: any, i: number) => {
                      return (
                        <motion.div
                          key={i}
                          initial={{ opacity: 0, x: i == 0 ? 100 : -100 }}
                          animate={{ opacity: 1, x: 0 }}
                          transition={{
                            ease: "easeOut",
                            duration: (i + 1) * 2 * 0.05,
                          }}
                        >
                          <div className="Group">
                            <h3>
                              {SchoolView.GroupName}: {Group.GroupName}
                            </h3>
                            <div className="Leaders">
                              <h4>{SchoolView.Leaders}</h4>
                              {Group.Leaders.length != 0 &&
                                Group.Leaders.map((Leader: any, i: number) => {
                                  return (
                                    <div className="Leader" key={i}>
                                      <p className="mb-0">
                                        {SchoolView.UserName}:{Leader.UserName}
                                      </p>
                                      {/* <p className="mb-0">
                                        {SchoolView.Email}:{Leader.Email}
                                      </p> */}
                                    </div>
                                  );
                                })}
                            </div>
                            <div className="Students">
                              <h4>{SchoolView.Students}</h4>
                              {Group.Students.length != 0 &&
                                Group.Students.map(
                                  (Student: any, i: number) => {
                                    return (
                                      <div className="Student" key={i}>
                                        <p className="mb-0">
                                          {SchoolView.UserName}:{" "}
                                          {Student.UserName}
                                        </p>
                                        {/* <p className="mb-0">
                                          {SchoolView.Email}: {Student.Email}
                                        </p> */}
                                      </div>
                                    );
                                  }
                                )}
                            </div>
                          </div>
                        </motion.div>
                      );
                    })}
                  </div>
                )}
                {Pages.length != 0 && (
                  <div
                    className={`w-100 d-flex align-items-center justify-content-center mt-5 Pagination ${Language}`}
                  >
                    <Pagination
                      count={Pages.length}
                      color="primary"
                      variant="outlined"
                      style={{ color: "#fff !important" }}
                      boundaryCount={1}
                      siblingCount={0}
                      onChange={(e: any, v) => {
                        SetIndexPage(v - 1);
                      }}
                    />
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className="alert alert-info">{SchoolNotFound}</div>
          )}
        </>
      )}
    </div>
  );
};

export default ActionForSchool;
