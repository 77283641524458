import React, { useState } from "react";
import ModalComp from "../../../../../../components/Design/Modal";
import { Trash } from "lucide-react";
import { DeleteUserFun } from "../../../../../../services/api/Group/User";
import { Encrypt_Back } from "../../../../../../services/jwt with backend/JWT.With.Backend";
import { toast } from "react-toastify";
import { useData } from "../../../../../../data/management/Data.Management";

const DeleteBtnUser = ({
  UserData,
  UserId,
  OnDeleted,
  type,
}: {
  UserData: any;
  UserId: string;
  OnDeleted: Function;
  type: "Leader" | "Student";
}) => {
  const {
    Data: {
      LanguageData: {
        DashBord: { Leader, Student },
      },
    },
  }: any = useData();
  const [disabledCloseBtn, SetdisabledCloseBtn] = useState(false);
  const [disabledConfirmBtn, SetdisabledConfirmBtn] = useState(false);
  const [Loading, SetLoading] = useState(false);
  const [Show, SetShow] = useState(false);

  const DeleteUser = () => {
    SetLoading(true);
    DeleteUserFun({
      Token: Encrypt_Back({
        Data: {
          UserId: UserId,
          _UserId: UserData.UserId,
          type,
        },
        expiresIn: { minutes: 5 },
      }),
    }).then((result) => {
      if (result.Data.Deleted != undefined) {
        SetShow(!result.Data.Deleted);
        toast.success(result.Data.Message, {
          closeOnClick: true,
          theme: "dark",
        });
        OnDeleted(true);
      }
      SetLoading(false);
    });
  };

  return (
    <ModalComp
      ButtonTitle={
        <>
          <Trash />
        </>
      }
      ButtonTitlevariant="danger"
      ModalTitle={
        type == "Leader"
          ? Leader.Delete.DeleteLeader
          : Student.Delete.DeleteStudent
      }
      ConfirmBtn={
        <>
          <span className="me-2">
            {type == "Leader"
              ? Leader.Delete.DeleteBtn
              : Student.Delete.DeleteBtn}
          </span>
          <Trash />
        </>
      }
      variantBtn="danger"
      variantModal="light"
      disabledCloseBtn={disabledCloseBtn}
      disabledConfirmBtn={disabledConfirmBtn}
      onClickConFirmBtn={DeleteUser}
      Show={Show}
      handleClose={() => {
        SetShow(false);
      }}
      handleShow={() => {
        SetShow(true);
      }}
      Loading={Loading}
    >
      <input
        type="text"
        disabled={true}
        value={UserData.UserName}
        className="form-control mb-2"
      />
      <input
        type="text"
        disabled={true}
        value={UserData.Email}
        className="form-control"
      />
    </ModalComp>
  );
};

export default DeleteBtnUser;
