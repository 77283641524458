import React, { useEffect, useState } from "react";
import Password from "./Password";
import { GoogleLogin } from "@react-oauth/google";
import {
  Decrypt_Back,
  Encrypt_Back,
} from "../../../services/jwt with backend/JWT.With.Backend";
import { SignInAdminFun } from "../../../services/api/SignIn/SignIn";
import { useData } from "../../../data/management/Data.Management";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const LoginAsAdmin = () => {
  const {
    SetMode,
    SetLanguage,
    SetUserLogin,
    SetRoles,
    SetLogin,
    SetPermissions,
  }: any = useData();
  const Navigate = useNavigate();
  const SignIn = () => {
    SignInAdminFun({
      Token: Encrypt_Back({
        Data: {
          EmailToken: Token,
          Password: AdminPassword,
        },
        expiresIn: {
          minutes: 5,
        },
      }),
    }).then((result: any) => {
      if (result.Data.Loggin) {
        let AdminData = Decrypt_Back({
          Data: result.Data.UserData,
        }).Data;
        SetPermissions(AdminData.Permissions);
        SetRoles(AdminData.Roles);
        SetMode(AdminData.Theme);
        SetLanguage(AdminData.Language);
        SetUserLogin(AdminData);
        SetLogin(true);
        Navigate("/");
      } else {
        //  Message To User Because Not Found
      }
    });
  };
  const [AdminPassword, SetAdminPassword] = useState("");
  const [Token, SetToken] = useState("");
  const [DisabledLoginBtn, SetDisabledLoginBtn] = useState(true);
  useEffect(() => {
    try {
      const decoded = jwtDecode(Token);
      if (AdminPassword.length > 0) {
        SetDisabledLoginBtn(false);
      } else {
        SetDisabledLoginBtn(true);
      }
    } catch {
      SetDisabledLoginBtn(true);
    }
  }, [AdminPassword, Token]);

  return (
    <div id="SignInPage">
      <div className="Form">
        <h3>Login To </h3>
        <h2 className="Rubik">Siham Beid Jabaar</h2>
        <h3 className="mb-5">As Admin</h3>
        <div className="w-100 d-flex align-items-center justify-content-center flex-column">
          <GoogleLogin
            onSuccess={(credentialResponse: any) => {
              SetToken(credentialResponse.credential);
            }}
            onError={() => {
              // console.log("Login Failed");
            }}
            useOneTap
          />
          <Password
            OnChange={(Password: string) => {
              SetAdminPassword(Password);
            }}
          />
          <button
            className="Btn"
            disabled={DisabledLoginBtn}
            onClick={SignIn}
            style={{ width: "200px" }}
          >
            Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginAsAdmin;
