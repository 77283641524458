import React from "react";
import UserView from "./User.View";

const GetAllUsers = ({ Users }: { Users: any[] }) => {
  return (
    <div className="Users">
      {Users.map((User: any, i: number) => {
        return <UserView key={i} UserData={User} />;
      })}
    </div>
  );
};

export default GetAllUsers;
