import React from "react";
import "../../styles/not-found/not-found.page.style.css";
import { Link } from "react-router-dom";
import { useData } from "../../data/management/Data.Management";
const NotFoundPage = () => {
  const {
    Data: { Roles, Language },
  }: any = useData();
  return (
    <div
      id="NotFoundPage"
      className={`Rubik ${Roles.includes("Admin") ? "Admin" : ""} ${
        Language == "Ar" ? "RTL" : "LTR"
      }`}
    >
      <h2>404</h2>
      <h3>Page Not Found</h3>
      <Link to="/" className="text-decoration-none">
        <button className="Btn Btn-Light">Home Page</button>
      </Link>
    </div>
  );
};

export default NotFoundPage;
