import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { toast } from "react-toastify";
import { Storage } from "../../../../../../firebase/firebase";
import { v4 } from "uuid";
import { useData } from "../../../../../../data/management/Data.Management";

const UploadImage = ({
  OnCompleteUpload,
  Change,
}: {
  OnCompleteUpload: Function;
  Change: string;
}) => {
  const {
    Data: {
      LanguageData: {
        DashBord: {
          School: { Create },
        },
      },
    },
  }: any = useData();
  const fileTypes = ["JPG", "PNG", "GIF"];
  const [File, SetFile] = useState(null);
  const [FileSrc, SetFileSrc]: any = useState("");
  let [ProgressValue, SetProgressValue] = useState(0);
  let [DisabledUploadBtn, SetDisabledUploadBtn] = useState(true);
  let [Deleted, SetDeleted] = useState(false);
  let [DeletedUploadSection, SetDeletedUploadSection] = useState(false);
  let [Loading, SetLoading] = useState(false);

  const _UploadImage = () => {
    SetDisabledUploadBtn(true);
    SetLoading(true);
    SetDeletedUploadSection(true);
    if (File != null) {
      const Ref = ref(Storage, `SchoolsImages/${v4()}.Image`);
      const uploadTask = uploadBytesResumable(Ref, File);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          SetProgressValue(Math.round(progress));
        },
        () => {},
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            OnCompleteUpload({ ImgSrc: downloadURL });
          });
          SetDeleted(true);
          SetProgressValue(0);
          toast.success("Uploaded Successfully !!", {
            theme: "dark",
            closeOnClick: true,
          });
        }
      );
    }
  };
  useEffect(() => {
    SetFile(null);
    SetFileSrc("");
    SetProgressValue(0);
    SetDisabledUploadBtn(true);
    SetDeleted(false);
    SetDeletedUploadSection(false);
    SetLoading(false);
  }, [Change]);
  return (
    <div className="w-100">
      {!DeletedUploadSection && (
        <FileUploader
          handleChange={(file: any) => {
            SetFile(file);
            const fileReader = new FileReader();
            fileReader.onload = (e) => {
              SetFileSrc(e.target?.result);
            };
            fileReader.readAsDataURL(file);
            SetDisabledUploadBtn(false);
          }}
          name="file"
          types={fileTypes}
        />
      )}
      {FileSrc.length > 0 && (
        <img src={FileSrc} alt="" className="w-100 mt-3" />
      )}
      {ProgressValue != 0 && (
        <div className="progress w-100 mt-4">
          <div
            className="progress-bar progress-bar-striped"
            role="progressbar"
            style={{ width: `${ProgressValue}%` }}
            aria-valuenow={ProgressValue}
            aria-valuemin={0}
            aria-valuemax={100}
          >
            {ProgressValue + "%"}
          </div>
        </div>
      )}
      {!Deleted && (
        <button
          className="btn btn-primary rounded-5 mt-3 w-100 py-2 d-flex align-items-center justify-content-center"
          disabled={DisabledUploadBtn}
          onClick={_UploadImage}
        >
          <span>{Create.Upload}</span>
          {Loading && (
            <div
              className="spinner-border text-light ms-2"
              role="status"
              style={{ width: "25px", height: "25px" }}
            ></div>
          )}
        </button>
      )}
    </div>
  );
};

export default UploadImage;
