import React, { useEffect, useRef, useState } from "react";
import { GetSchoolDataFun } from "../../../../../../services/api/school/School";
import { Connect } from "../Connect.To.Socket.io";
import { Send } from "lucide-react";
import { useData } from "../../../../../../data/management/Data.Management";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { _Date } from "../../../../../../services/Format Date/Format";
const SendSection = ({
  SchoolId,
  UserId,
  OnSended,
}: {
  SchoolId: string;
  UserId: string;
  OnSended: Function;
}) => {
  const {
    Data: {
      Theme,
      LanguageData: {
        DashBord: {
          Notifications: { EnterYourMessage, SendNotification },
        },
      },
      Language,
    },
  }: any = useData();
  let Socket = Connect();
  let [Message, SetMessage] = useState("");
  let [Loading, SetLoading] = useState(false);
  let [BtnDisabled, SetBtnDisabled] = useState(true);
  useEffect(() => {
    if (Message.replaceAll(/ /g, "").replaceAll(/\n/g, "").length > 0) {
      SetBtnDisabled(false);
    } else {
      SetBtnDisabled(true);
    }
  }, [Message]);
  const SendMessage = () => {
    SetLoading(true);
    SetBtnDisabled(true);
    Socket.emit("joinRoom", { SchoolId: SchoolId?.replaceAll("_-_", "#") });
    Socket.emit("message", {
      room: { SchoolId: SchoolId?.replaceAll("_-_", "#") },
      message: Message,
      Medthod: "Send",
      AllData: {
        UserId,
        SchoolId: SchoolId?.replaceAll("_-_", "#"),
        CreateAt: _Date(),
      },
    });
    OnSended(true);
    SetLoading(false);
    SetBtnDisabled(false);
    SetMessage("");
    textarea.current.style.height = `${30}px`;
  };
  function newHeight(value: any) {
    let linebreaks = (value.match(/\n/g) || []).length - 1;
    // min-height + (lines * line-height) + padding + border
    let height = 36 + linebreaks * 20;
    return height;
  }

  let textarea: any = useRef();

  return (
    <>
      <textarea
        ref={textarea}
        onInput={() => {
          let height = newHeight(textarea.current.value);
          // If less that 36 set 36
          if (height > 200) {
            textarea.current.style.height = "200px";
          } else {
            textarea.current.style.height = `${height}px`;
          }
        }}
        value={Message}
        onChange={(e) => {
          SetMessage(e.target.value);
        }}
        dir={/[\u0600-\u06FF]/.test(Message) ? "rtl" : "ltr"}
        disabled={Loading}
        className="form-control"
        placeholder={EnterYourMessage}
        onKeyUp={(e) => {
          if (e.which == 13) {
            if (!BtnDisabled) {
              SendMessage();
            }
          }
        }}
      ></textarea>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="button-tooltip">{SendNotification}</Tooltip>}
      >
        <button
          disabled={BtnDisabled}
          className="btn btn-outline-primary"
          onClick={SendMessage}
        >
          {Loading ? (
            <div
              className="spinner-grow text-light"
              style={{ width: "20px", height: "20px" }}
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            <Send />
          )}
        </button>
      </OverlayTrigger>
    </>
  );
};

export default SendSection;
