let SOCKET_SERVER_URL = "https://admin.sbjministry.com/Notifications";
let SOCKET_SERVER_URL2 = "https://admin.sbjministry.com/GoogleSheet";
let MainURLBackEnd = "https://admin.sbjministry.com/api/";
let GraphQlURL = "https://admin.sbjministry.com/graphql";
export { SOCKET_SERVER_URL, MainURLBackEnd, GraphQlURL, SOCKET_SERVER_URL2 };

// let SOCKET_SERVER_URL = "http://localhost:8000/Notifications";
// let SOCKET_SERVER_URL2 = "http://localhost:8000/GoogleSheet";
// let MainURLBackEnd = "http://localhost:8000/api/";
// let GraphQlURL = "http://localhost:8000/graphql";
// export { SOCKET_SERVER_URL, MainURLBackEnd, GraphQlURL, SOCKET_SERVER_URL2 };

// let SOCKET_SERVER_URL2 =
//   "https://old-waly-siham-bied-jabaar-698e916b.koyeb.app/GoogleSheet";
// let SOCKET_SERVER_URL =
//   "https://old-waly-siham-bied-jabaar-698e916b.koyeb.app/Notifications";
// let MainURLBackEnd =
//   "https://old-waly-siham-bied-jabaar-698e916b.koyeb.app/api/";
// let GraphQlURL =
//   "https://old-waly-siham-bied-jabaar-698e916b.koyeb.app/graphql";
// export { SOCKET_SERVER_URL, MainURLBackEnd, GraphQlURL, SOCKET_SERVER_URL2 };
