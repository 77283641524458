import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "../../styles/AppBody/Modal.style.css";
import { useData } from "../../data/management/Data.Management";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Ban } from "lucide-react";
import { GetData } from "../../data/management/SaveData/SaveData&Get";
const ModalComp = ({
  children,
  ButtonTitle,
  ModalTitle,
  ConfirmBtn,
  variantBtn,
  disabledCloseBtn,
  disabledConfirmBtn,
  variantModal,
  onClickConFirmBtn,
  Show,
  handleClose,
  handleShow,
  Loading,
  ButtonTitlevariant,
}: {
  ButtonTitle: any;
  ModalTitle: string;
  ConfirmBtn: any;
  variantBtn: string;
  ButtonTitlevariant?: string;
  disabledCloseBtn: boolean;
  disabledConfirmBtn: boolean;
  children?: any;
  onClickConFirmBtn: any;
  variantModal?: "dark" | "light";
  Show: boolean;
  Loading: boolean;
  handleClose: any;
  handleShow: any;
}) => {
  const {
    Data: {
      Theme,
      LanguageData: { CloseBtn },
      Language,
      Permissions,
    },
  }: any = useData();
  const [_Show, Set_Show] = useState(false);
  useEffect(() => {
    if (Permissions.length == 0) {
      Set_Show(false);
    } else {
      Set_Show(true);
    }
  }, [Permissions]);

  return (
    <>
      {_Show && (
        <>
          {" "}
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="button-tooltip">{ModalTitle}</Tooltip>}
          >
            <Button
              variant={
                ButtonTitlevariant != undefined
                  ? `outline-${ButtonTitlevariant}`
                  : "outline-primary"
              }
              onClick={handleShow}
              className="m-1 d-inline-flex align-items-center justify-content-center text-white"
            >
              {ButtonTitle}
            </Button>
          </OverlayTrigger>
          <Modal
            dir={Language == "Ar" ? "rtl" : "ltr"}
            show={Show}
            onHide={handleClose}
            aria-labelledby="contained-modal-title-vcenter"
            className={`Modal ${Language == "Ar" ? "RTL" : "LTR"} ${
              Theme == "Dark" ? "dark" : "light"
            } ${Language == "Ar" ? "Lalezar" : "Rubik"}`}
            centered
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
                {ModalTitle}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>{children}</Modal.Body>
            <Modal.Footer className="justify-content-between">
              <Button
                onClick={onClickConFirmBtn}
                disabled={disabledConfirmBtn || Loading}
                variant={`outline-${variantBtn}`}
                className="d-flex align-items-center p-2 px-3 justify-content-center"
              >
                {Loading && (
                  <div
                    className="spinner-border text-light IfDark"
                    style={{ width: "25px", height: "25px" }}
                    role="status"
                  ></div>
                )}
                <span className="mx-2"> {ConfirmBtn}</span>
              </Button>
              <Button
                disabled={disabledCloseBtn || Loading}
                className="p-2 px-3 text-capitalize"
                variant="outline-primary"
                onClick={handleClose}
              >
                <span>
                  <span className="mx-2">{CloseBtn}</span>
                  <Ban />
                </span>
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
};

export default ModalComp;
