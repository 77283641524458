import {
  AudioWaveform,
  BarChartBig,
  BellRing,
  CirclePlus,
  Home,
  Library,
  ListVideo,
  QrCode,
  School,
  Sheet,
  User,
  UsersRound,
} from "lucide-react";

// ASide Links
export const AdminArabicASideLinks: {
  Path: string;
  Title: string;
  Icon: any;
}[] = [
  { Path: "/DashBord", Title: "الرئيسية", Icon: <Home /> },
  {
    Path: "/DashBord/Create-School",
    Title: "انشاء مدرسة جديدة",
    Icon: <CirclePlus />,
  },
];
export const AdminEnglishASideLinks: {
  Path: string;
  Title: string;
  Icon: any;
}[] = [
  { Path: "/DashBord", Title: "Home", Icon: <Home /> },
  {
    Path: "/DashBord/Create-School",
    Title: "Create New School",
    Icon: <CirclePlus />,
  },
];
export const AdminASideLinks = [
  { Language: "En", LanguageData: AdminEnglishASideLinks },
  { Language: "Ar", LanguageData: AdminArabicASideLinks },
];

export const IconsForNav = [
  { Name: "Groups", Icon: <UsersRound /> },
  { Name: "Students", Icon: <User /> },
  { Name: "Home", Icon: <Home /> },
  { Name: "CreateNewSchool", Icon: <CirclePlus /> },
  { Name: "DashBord", Icon: <BarChartBig /> },
  { Name: "Home", Icon: <Home /> },
  { Name: "Profile", Icon: <User /> },
  { Name: "Leaders", Icon: <User /> },
  { Name: "Notifications", Icon: <BellRing /> },
  { Name: "School", Icon: <School /> },
  { Name: "Library", Icon: <Library /> },
  { Name: "Forms", Icon: <Sheet /> },
  { Name: "Levels", Icon: <AudioWaveform /> },
  { Name: "Your-Group", Icon: <UsersRound /> },
  { Name: "PlayLists", Icon: <ListVideo /> },
  { Name: "QrCode", Icon: <QrCode /> },
];
/*
Timestamp	Email Address	User Name	
7/24/2024 4:22:44	artenanaeem@gmail.com	Arttenanaeem	
7/26/2024 8:49:47	eezzat453@gmail.com	Isaac Ezzat	
7/26/2024 8:49:57	isaacnabil112018@gmail.com	Isaac Nabil	
7/26/2024 8:50:34	joslinmaher@gmail.com	جوسلن ماهر 	
7/26/2024 8:50:45	smaanhenain661@gmail.com	سمعان حنين	
7/26/2024 8:55:01	nadymarina111@gmail.com	مارينا نادي شفيق	
7/26/2024 8:56:42	joyajoseph2017@gmail.com	Joya	
7/26/2024 8:56:53	raymonraouf04@gmail.com	Raymon	
7/26/2024 9:07:37	adwardsara8@gmail.com	سارة ادوارد صبحي 	
7/26/2024 9:16:26	christynessem507@gmail.com	Christy nessem	
7/26/2024 9:22:03	sandykameil01227041125@gmail.com	Sandy kameil	
7/26/2024 9:22:53	jomanageorge5555@gmail.com	Jomana George 	
7/26/2024 9:25:40	sandopastor20@gmail.com	ساندي بهاء وديع 	
7/26/2024 9:43:30	merojan18102005@gmail.com	Armia Jan Bernaba 	
7/26/2024 9:56:15	marthafadiel@gmail.com	Martha fadiel	
7/26/2024 10:01:22	amgadashak22@gmail.com	Amgad Eshak	
7/26/2024 10:05:14	hanyamira072@gmail.com	Amira hany	
7/26/2024 11:10:01	smwyylsmyr326@gmail.com	صمويل سمير	
7/26/2024 11:37:05	lydiaead505@gmail.com	ليديا عيد	
7/26/2024 11:43:23	raranaem1@gmail.com	رجائي نعيم 	
7/26/2024 11:50:59	asklfjlasdkfj@gmail.com	ميرا وحيد ميخائيل 	
7/26/2024 12:01:58	johnazarief99@gmail.com	جون أمجد ظريف عبيد 	
7/26/2024 12:31:18	marlinemelad92@gmail.com	marline melad 	
7/26/2024 13:11:48	eavhossam@gmail.com	Eva Hossam 	
7/26/2024 13:24:37	tefa53589@gmail.com	Steven	
7/26/2024 13:52:48	mdhtbwls985@gmail.com	مدحت بولس	
7/26/2024 13:54:11	youssefhossem837@gmail.com	Youssef Hossem	
7/26/2024 14:13:02	ygergis500@gmail.com	Youssef Girgis 	
7/26/2024 14:23:51	lolofarag24.12@gmail.com	Lara Farag 	
7/26/2024 14:30:05	fadyboles4974@gmail.com	فادي بولس 	
7/26/2024 14:32:10	totazakarai@gmail.com	ترفينا زكريا ميخائيل 	
7/26/2024 16:10:44	mariamhannanasri@gmail.com	مريم حنا 	
7/26/2024 18:41:01	wafaa.masu@gmail.com	Wafa Masu	
7/27/2024 9:16:14	karenmagdy10@gmail.com	كارين مجدي اسحق	
7/27/2024 15:52:08	millermagdy2009@gmail.com	Miller Magdy	
7/27/2024 16:18:46	mirna0fady@gmail.com	Mirna Mounir	*/

/*

انجي رافت
ايمي رافت
باسم نبيل
مينا شكري
جون اسحق
صاصا
*/
