import React, { useState } from "react";
import { calculateTimePeriod } from "../../../services/Format Date/Format";
import { Check, X } from "lucide-react";

const FormViewDataOfUser = ({ Data }: { Data: any }) => {
  const [IsShow, SetIsShow] = useState(false);
  return Data != null ? (
    <div id="Form">
      <div className="Form mb-2">
        <div>
          <span>{Data.FormTitle}</span>
          {Data.IsAnswered && (
            <span className="d-felx">
              {calculateTimePeriod(
                new Date(Data.Time)
                  .toLocaleString("en-US", {
                    timeZone: "Africa/Cairo",
                  })
                  .toString()
              )}
            </span>
          )}
        </div>
        <span
          className={`status ${Data.IsAnswered ? "Answered" : "NotAnswered"}`}
        >
          {Data.IsAnswered ? <Check /> : <X />}
        </span>
      </div>
      <div className="w-100">
        {Data.IsAnswered && (
          <button
            className="btn btn-primary mx-auto d-block my-2"
            style={{ width: "fit-content" }}
            onClick={() => {
              SetIsShow(!IsShow);
            }}
          >
            {!IsShow ? "Show" : "Hide"}
          </button>
        )}
        {Data != null && Data.IsAnswered && (
          <div
            className="w-100"
            style={{ display: `${IsShow ? "block" : "none"}` }}
          >
            {Data.Answers.Header.map((HeaderOfQuestion: any, i: number) => {
              return (
                <div key={i}>
                  <span>{HeaderOfQuestion}</span>
                  <p>{Data.Answers.Answers[i]}</p>
                  <hr />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  ) : (
    <p>"Loading ..."</p>
  );
};

export default FormViewDataOfUser;
