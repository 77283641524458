import "../../styles/home/home.pgae.style.css";
import { useData } from "../../data/management/Data.Management";
import PDFReader from "./PDF.Reader";

const HomePage = () => {
  const {
    SetLanguage,
    Data: {
      IsLoggin,
      Roles,
      UserData: { UserName },
      LanguageData: { HomePage },
      UserId,
      Language,
    },
  }: any = useData();

  return (
    <div
      className={`HomePage ${
        !(Roles.length == 1 && Roles.includes("Student")) && "Admin"
      }`}
      style={
        !(Roles.length == 1 && Roles.includes("Student"))
          ? {
              marginLeft: Language == "Ar" ? "0" : "auto",
              marginRight: Language == "Ar" ? "auto" : "0",
            }
          : {}
      }
    >
      <h1 className="DeskTop">{HomePage.Welcome + ", " + UserName}</h1>
      <h1 className="Mobile">{HomePage.Welcome}</h1>
      {/* <PDFReader /> */}
    </div>
  );
};

export default HomePage;
