import { Info } from "lucide-react";
import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useData } from "../../../data/management/Data.Management";
import { useNavigate } from "react-router-dom";

const UserView = ({ UserData }: { UserData: any }) => {
  const {
    Data: {
      Roles,
      Language,
      UserData: { Email },
      LanguageData: {
        Profile,
        DashBord: {
          Group: {
            Messages: { DataAboutUser },
          },
        },
      },
      UserId,
    },
  }: any = useData();
  let Navigate = useNavigate();
  return (
    <div className="User">
      <h4>{UserData.UserName}</h4>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="button-tooltip" className="text-capitalize">
            {DataAboutUser + " " + UserData.UserName}
          </Tooltip>
        }
      >
        <button
          className="btn btn-outline-primary"
          onClick={() => {
            Navigate(`User/@${UserData.Id}`);
          }}
        >
          <Info />
        </button>
      </OverlayTrigger>
    </div>
  );
};

export default UserView;
